import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

type BadgeProps = {
  offset: number;
};

export const BadgeStyle = styled.div<BadgeProps>`
  color: ${Colors.WHITE_COLOR};
  background: ${Colors.BUTTON_COLOR};
  position: absolute;
  top: 0;
  right: ${({ offset }) => `${offset}px`};
  border-radius: 30px;
  font-size: 11px;
  margin-top: -10px;
  min-width: 20px;
  padding: 2px;
  text-align: center;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Content = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.BLACK};
`;

export const DeleteStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & span {
    white-space: nowrap;
    font-size: 12px;
    color: ${Colors.WHITE_COLOR};
  }
`;

export const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
