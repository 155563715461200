import { FC } from "react";

export const FileColored: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5.33333V14.6667C5 15.311 5.52233 15.8333 6.16667 15.8333H13.1667C13.811 15.8333 14.3333 15.311 14.3333 14.6667V7.86594C14.3333 7.55198 14.2068 7.25127 13.9823 7.03177L11.3921 4.49916C11.1741 4.28601 10.8814 4.16666 10.5765 4.16666H6.16667C5.52233 4.16666 5 4.689 5 5.33333Z"
      stroke="url(#paint0_linear_839_8815)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9165 10.5834H11.4165"
      stroke="url(#paint1_linear_839_8815)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9165 12.9167H9.6665"
      stroke="url(#paint2_linear_839_8815)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8335 4.16666V6.5C10.8335 7.14433 11.3558 7.66666 12.0002 7.66666H14.3335"
      stroke="url(#paint3_linear_839_8815)"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_839_8815"
        x1="5"
        y1="5.30093"
        x2="14.6476"
        y2="5.43127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_839_8815"
        x1="7.9165"
        y1="10.6807"
        x2="11.5224"
        y2="10.8938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_839_8815"
        x1="7.9165"
        y1="13.0139"
        x2="9.72418"
        y2="13.0673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_839_8815"
        x1="10.8335"
        y1="4.50694"
        x2="14.451"
        y2="4.56803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
    </defs>
  </svg>
);
