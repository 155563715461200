import { Content } from "antd/lib/layout/layout";
import React, { FC, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { listApi } from "../../../api/ManageListApi/ManageList";
import { ButtonPlus } from "../../../assets";
import { useUser } from "../../../context/auth-context";
import { QueryNames } from "../../../utils/constants/QueryNames";
import ButtonStyled from "../../Button";
import { TextStyled } from "../../Button/Button.styled";
import SearchInput from "../../Input/SearchInput";
import PanelHeader from "../../PanelHeader";

import { AddAccountWrapper, TableWrapper } from "./ManageList.styled";
import ManageListTable from "./ManageListTable/ManageListTable";
import UploadModal from "./UploadModal/UploadModal";

export type FilterType = {
  limit: number;
  offset: number;
  sort: string;
  dir: string;
  search: string;
};

const ManageListScreen: FC = () => {
  const LIMIT = 10;

  const { user } = useUser();

  const [filterValues, setFilterValues] = useState<FilterType>({
    limit: LIMIT,
    offset: 0,
    sort: "org_list_dt_create",
    dir: "",
    search: "",
  });

  const makeQuery = ({
    limit,
    search,
    offset,
    sort,
    dir,
  }: FilterType): string => {
    return `?limit=${limit}&offset=${offset}&sort=${sort}${
      dir ? `&dir=${dir}` : ""
    }${search ? `&query=${search}` : ""}`.trim();
  };

  const {
    data: { lists, total },
  } = useQuery({
    queryKey: [QueryNames.GET_LISTS, filterValues],
    queryFn: () => {
      return listApi.get(makeQuery(filterValues));
    },
    initialData: {
      lists: [],
      total: 0,
      offset: 0,
    },
  });
  const downloadFile = async (id: string) => {
    await listApi
      .get(`/${id}?export=true`, {
        responseType: "blob",
      })
      .then((res) => {
        const headers = res.headers;
        const filename = headers["x-suggested-filename"];
        const blob = new Blob([res.data]);

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(() => {
        NotificationManager.error("Something went wrong");
      });
  };

  const queryClient = useQueryClient();
  const addList = useMutation(listApi.create);
  const updateList = useMutation(listApi.update);
  const deleteListById = useMutation(listApi.deleteById);

  const addNewList = (newList: any) => {
    addList.mutate(
      {
        name: newList.name,
        file: newList.file,
      },
      {
        onSuccess: () => {
          NotificationManager.success("New List created");
          queryClient.invalidateQueries([QueryNames.GET_LISTS]);
        },
        onError: () => {
          NotificationManager.error("Something went wrong");
        },
      }
    );
  };

  const updateNewList = (name: string, id: string) => {
    updateList.mutate(
      { name, id },
      {
        onSuccess: () => {
          NotificationManager.success("List successfully updated");
          queryClient.invalidateQueries([QueryNames.GET_LISTS]);
        },
        onError: () => {
          NotificationManager.error("Something went wrong");
        },
      }
    );
  };

  const deleteList = (id: any) => {
    deleteListById.mutate(id, {
      onSuccess: () => {
        NotificationManager.success(`List ${id} successfully deleted`);
        queryClient.invalidateQueries([QueryNames.GET_LISTS]);
      },
      onError: (error: any) => {
        NotificationManager.error(`${JSON.stringify(error?.request.response)}`);
      },
    });
  };

  const onCreate = async (createClient: any) => {
    try {
      await addNewList(createClient);
    } catch (error) {
      NotificationManager.error(`Error message:  ${error}`);
    }
  };

  return (
    <>
      <PanelHeader
        tabTitle="Management"
        title="Manage lists"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <AddAccountWrapper>
        <SearchInput
          onSearch={(value: string) =>
            setFilterValues((prev) => ({
              ...prev,
              ["search"]: value,
              export: null,
            }))
          }
        />

        <UploadModal
          width={500}
          handleSave={(data) => {
            onCreate({
              name: data.name,
              file: data.file,
            });
          }}
          title="Add List"
        >
          <ButtonStyled>
            <>
              <ButtonPlus />
              <TextStyled>Add List</TextStyled>
            </>
          </ButtonStyled>
        </UploadModal>
      </AddAccountWrapper>
      <Content>
        <TableWrapper>
          <ManageListTable
            data={lists}
            limit={LIMIT}
            total={total}
            onDelete={deleteList}
            filter={setFilterValues}
            onUpdate={updateNewList}
            onDownload={downloadFile}
            filterValues={filterValues}
            offset={filterValues.offset / LIMIT + 1}
            setOffset={(value: any) => {
              setFilterValues((prev) => ({
                ...prev,
                ["offset"]: (value - 1) * LIMIT,
                export: null,
              }));
            }}
          />
        </TableWrapper>
      </Content>
    </>
  );
};

export default ManageListScreen;
