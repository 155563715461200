import React, { FC, useState } from "react";
import { NotificationManager } from "react-notifications";

import FileUpload from "../../../FileUpload";
import InputStyled from "../../../Input/Input";
import ModalStyled from "../../../Modal";

import { ModalContent } from "./UploadModal.styled";

export type UploadFile = {
  file: HTMLInputElement | null;
  name: string;
  error: boolean;
  fileSize: string;
};

type TableStyledProps = {
  title: string;
  children: JSX.Element;
  handleSave: (data: any) => void;
  initialData?: UploadFile;
  width?: number;
};
const UploadModal: FC<TableStyledProps> = ({
  children,
  title,
  handleSave,
  width,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalData, setData] = useState<UploadFile>({
    file: null,
    name: "",
    error: false,
    fileSize: "",
  });

  const handleClose = () => {
    setIsModalOpen(false);
    setData({
      file: null,
      name: "",
      error: false,
      fileSize: "",
    });
  };

  const submit = () => {
    if (
      modalData.name.length > 0 &&
      !modalData.error &&
      modalData.file !== null
    ) {
      handleSave(modalData);
      setData({
        file: null,
        name: "",
        error: false,
        fileSize: "",
      });
      setIsModalOpen(false);
      return;
    }

    const fileArr = modalData?.fileSize?.split(" ");

    modalData?.name?.length === 0 &&
      NotificationManager.error("Enter List name");
    !modalData.file && NotificationManager.error("Choose file for upload");
    +fileArr[0] > 20 &&
      fileArr[1] === "MB" &&
      NotificationManager.error("Exceeding the threshold size");
  };
  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        width={width}
        title={title}
        isModalOpen={isModalOpen}
        handleSave={() => submit()}
        handleClose={handleClose}
        modalOpen={() => console.log()}
      >
        <ModalContent>
          <InputStyled
            title="List name :"
            value={modalData?.name}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["name"]: value }));
            }}
          />
          <FileUpload onChange={setData} modalData={modalData}></FileUpload>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default UploadModal;
