import React, { FC } from "react";

import { Arrow } from "../../../assets";
import PanelHeader from "../../PanelHeader";
import LicenseContent from "../Licensing/PrivacyModal/LicenseContent";

import { ContentWrapper, StyledA } from "./Privacy.styled";

const PrivacyScreen: FC = () => {
  return (
    <>
      <PanelHeader
        title="Privacy policy"
        subTitle={
          <StyledA onClick={() => history.back()} style={{ cursor: "pointer" }}>
            <Arrow />
            <div style={{ width: "5px" }}></div>
            Back
          </StyledA>
        }
      />
      <ContentWrapper>
        <LicenseContent />
      </ContentWrapper>
    </>
  );
};

export default PrivacyScreen;
