import { FC } from "react";

type IconProps = {
  stroke?: string;
};

export const SearchIcon: FC<IconProps> = ({ stroke = "#858691" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="6" stroke={stroke} />
    <path d="M20 20L17 17" stroke="#616F81" strokeLinecap="round" />
  </svg>
);
