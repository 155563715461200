import { FC } from "react";

export const UserLight: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4397 17.0394C16.0598 15.9762 15.2229 15.0368 14.0586 14.3667C12.8943 13.6967 11.4677 13.3335 10.0002 13.3335C8.5326 13.3335 7.10605 13.6967 5.94175 14.3667C4.77746 15.0368 3.94049 15.9762 3.56066 17.0394"
      stroke="url(#paint0_linear_231_7512)"
      strokeLinecap="round"
    />
    <ellipse
      cx="9.99984"
      cy="6.66683"
      rx="3.33333"
      ry="3.33333"
      stroke="url(#paint1_linear_231_7512)"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_231_7512"
        x1="3.3335"
        y1="14.3057"
        x2="17.1113"
        y2="14.6159"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_231_7512"
        x1="6.6665"
        y1="3.98165"
        x2="13.5569"
        y2="4.09801"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#592C7D" />
        <stop offset="0.427083" stopColor="#3FBDD4" />
        <stop offset="1" stopColor="#6BC495" />
      </linearGradient>
    </defs>
  </svg>
);
