import React, { FC } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "../../../../../assets/Calendar";
import { InputPlaceholder, StyledInput } from "../AccountModal.styled";

type DateInputProps = {
  onClick?: (value: any) => void;
  id: string;
  value: Date | string;
  title: string;
  onChange?: (dates: any) => void;
};
const DateInput: FC<DateInputProps> = ({ onChange, value, id, onClick }) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "13px", left: "12px" }}>
        <Calendar></Calendar>
      </div>
      <div style={{ position: "absolute", top: "6px", left: "33px" }}>
        <InputPlaceholder>From</InputPlaceholder>
      </div>
      <StyledInput
        onChange={onChange}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        value={value}
        id={id}
        onClick={onClick}
      />
    </div>
  );
};

export default DateInput;
