import { Footer } from "antd/es/layout/layout";
import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";

import { Colors } from "../../../utils/colors";

import CustomPopover from "./Popover/Popover";

export const Subscription = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;

  color: ${Colors.BLACK};
`;

export const ContentStyled = styled(Content)`
  padding: 24px;
`;

export const PurchasedLicense = styled.div`
  max-width: 468px;
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  border-radius: 6px;
  background-color: ${Colors.WHITE_COLOR};
`;

export const ButtonText = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  color: ${Colors.WHITE_COLOR};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 26px;
`;

export const Price = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: ${Colors.TITLE_INNER_COLOR};
`;

export const Cansel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${Colors.TITLE_INNER_COLOR};
`;

export const Call = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.WHITE_COLOR};
`;

export const Corsound = styled.a`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.MAIN_BLUE};
  text-decoration: underline;

  &:hover {
    color: ${Colors.MAIN_BLUE};
  }
`;

export const StyledPopover = styled(CustomPopover)`
  .ant-popover-inner {
    padding: 16px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  }
  .ant-popover-arrow {
    top: 1px;
    --antd-arrow-background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const DateStyled = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: ${Colors.TITLE_INNER_COLOR};
`;

export const End = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #6bc495;
`;

export const Text = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  color: ${Colors.BLACK_COLOR};
`;

export const Privacy = styled.div`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${Colors.SILVER_BLUR_COLOR};
  white-space: nowrap;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const HeadTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const LicenseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

export const FooterStyled = styled(Footer)`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 9px 23px;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const PlanWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
