import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const ButtonStyledWrapper = styled.div<{
  background?: string;
  padding?: string;
  border?: string;
}>`
  & button {
    display: flex;
    background: ${({ background }) =>
      background ? `${background}` : `${Colors.BUTTON_COLOR}`};
    padding: ${({ padding }) => (padding ? `${padding}` : "10px 16px")};
    height: 100%;
    border: ${({ border }) => (border ? `${border}` : "initial")};

    &::after {
      all: unset;
    }
    span {
      display: flex;
      align-items: center;
    }
  }
`;

export const TextStyled = styled.div`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.WHITE_COLOR};
`;
