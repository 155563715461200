export enum QueryNames {
  GET_USERS = "GET USERS",
  GET_USER = "GET USER",
  GET_USER_PROFILE = "GET USER PROFILE",
  GET_CLIENTS = "GET CLIENTS",
  GET_CLIENT = "GET CLIENT",
  GET_LISTS = "GET LISTS",
  GET_PIE_AGE = "GET PIE AGE",
  GET_PIE_GANDER = "GET PIE GANDER",
  GET_PIE_VERIFY = "GET PIE VERIFY",
  GET_PIE_ETHNICITY = "GET PIE ETHNICITY",

  GET_BAR_AGE = "GET BAR AGE",
  GET_BAR_GANDER = "GET BAR GANDER",
  GET_BAR_VERIFY = "GET BAR VERIFY",
  GET_BAR_ETHNICITY = "GET BAR ETHNICITY",

  GET_CALLS = "GET CALLS",
  GET_CALL_BY_ID = "GET CALL BY ID",

  GET_LICENSE = "GET LICENSE",
  GET_LICENSE_LAST = "GET LICENSE LAST",

  GET_CONFIG = "GET CONFIG",
}
