import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { FC } from "react";
import "./index.css";

import { Colors } from "../../utils/colors";

import { CollapseStyledWrapper } from "./Callapse.styled";

type CollapseStyledProps = {
  defaultActiveKey: string;
  onChange: (value: string) => void;
  children?: JSX.Element;
};
const CollapseStyled: FC<CollapseStyledProps> = ({
  children,
  onChange,
  defaultActiveKey,
}) => {
  return (
    <CollapseStyledWrapper>
      <Collapse
        onChange={(key) => onChange(key as string)}
        accordion
        defaultActiveKey={[defaultActiveKey]}
        expandIconPosition="end"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            color={Colors.SILVER_COLOR}
            rotate={isActive ? 90 : 270}
          />
        )}
        style={{ background: Colors.WHITE_COLOR }}
      >
        {children}
      </Collapse>
    </CollapseStyledWrapper>
  );
};

export default CollapseStyled;
