import { Input } from "antd";
import React, { FC, useState } from "react";

import { SearchIcon } from "../../../assets";

import { SearchStyled, StyledSearchIcon } from "./SearchInput.styled";

type MainLayoutProps = {
  width?: string;
  icon?: JSX.Element;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
};
const SearchInput: FC<MainLayoutProps> = ({
  onSearch,
  icon,
  width,
  onChange,
}) => {
  const [value, setValue] = useState("");

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onSearch?.(value);
    }
  };

  return (
    <SearchStyled width={width}>
      <Input
        onKeyDown={handleKeyDown}
        suffix={
          icon || (
            <StyledSearchIcon onClick={() => onSearch?.(value)}>
              <SearchIcon></SearchIcon>
            </StyledSearchIcon>
          )
        }
        onChange={(e) => {
          onChange?.(e.target.value);
          setValue(e.target.value);
        }}
        placeholder="Search"
      />
    </SearchStyled>
  );
};

export default SearchInput;
