import { Empty } from "antd";
import React, { FC } from "react";
import { PieChart, Pie, Bar, Cell, Legend } from "recharts";
// eslint-disable-next-line import/no-unresolved
import { Payload } from "recharts/types/component/DefaultLegendContent";

import { PieData, PieType } from "../../../../api/PieApi/Pie";
import { ChartLabel, PieChartWrapper } from "../GraphicalReports.styled";

type PieChartDonutProps = {
  pie: PieType;
  colors: string[];
  label: string;
  secondColors: string[];
};

const PieChartDonut: FC<PieChartDonutProps> = ({
  pie,
  colors,
  label,
  secondColors,
}) => {
  const legendPayload = (pie: PieType): Payload[] => {
    return pie?.categories.map((category: string, i: number) => ({
      value: category,
      type: "square",
      color: colors[i],
    }));
  };

  if (!pie?.categories.length || !pie?.data.length) {
    return (
      <Empty
        style={{ width: "312px" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No data found"
      />
    );
  }

  return (
    <PieChartWrapper>
      <ChartLabel>{label}</ChartLabel>
      <PieChart width={300} height={300}>
        <Pie
          data={pie?.data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={70}
          fill="#8884d8"
        >
          {pie?.data.map((entry: PieData, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Pie
          data={pie?.data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          fill="#82ca9d"
          label
        >
          {pie?.data.map((entry: PieData, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={secondColors[index % secondColors.length]}
            />
          ))}
        </Pie>
        <Legend payload={legendPayload(pie)} />
        {pie?.categories.map((el: string, index: number) => (
          <Bar key={`bar-${index}`} dataKey={el} fill={colors[index]} />
        ))}
      </PieChart>
    </PieChartWrapper>
  );
};

export default PieChartDonut;
