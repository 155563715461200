import React, { FC } from "react";

import "./table.module.css";
import {
  BlueTitle,
  Content,
  StyledP,
  StyledTable,
  StyledTD,
} from "./LicenseModal.styled";

const LicenseContent: FC = () => {
  return (
    <Content>
      <BlueTitle>Introduction</BlueTitle>
      <p>
        <StyledP>
          Welcome to Corsound AI, a Web-service / web-app and Smartphone
          application that is a state-of-the-art technology designed to handle
          any challenge related to voice biometrics through various solutions,
          such as Voice-to-Face, Voice-Face-Matching, Fraud Detection,
          Diarization, Voice Biometrics, Anti-Spoofing and Biomarkers Detection,
          as further explained at:
          <a href="https://www.corsound.ai/">https://www.corsound.ai/</a>
          (the “<strong>Service</strong>”).
        </StyledP>
        <StyledP>
          This Privacy Policy (“Policy”) explains how information about you is
          collected and used by the Service, which is developed and operated by
          Corsound AI Ltd. (“<strong>Company</strong>” <strong>or</strong> “
          <strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”).
        </StyledP>
        <StyledP>
          We are committed to complying with applicable data protection laws,
          including the EU and the UK General Data Protection Regulation (GDPR),
          and the California Privacy Rights Act (CPRA).
        </StyledP>
        <StyledP>
          The Service is not directed to users under the age of 18. We do not
          knowingly collect information or data from children under the age of
          18 or knowingly allow minors under the age of 18 to use the Service.
        </StyledP>
        <StyledP>
          This Policy may be amended from time to time. We will post any change
          to this Policy on our Service at a<br />
          reasonable time in advance of the effective date of the change, and we
          will also make efforts to proactively notify you by email of the
          changes if we have your email address.
        </StyledP>
        <BlueTitle>Contact us </BlueTitle>
        <StyledP>
          If you have any questions, comments or concerns regarding this Policy
          or our processing of your personal information, please contact us at
          <a href="https://www.corsound.ai/"> https://www.corsound.ai/</a>.
        </StyledP>
        <BlueTitle>What we collect and why</BlueTitle>
        <StyledTable>
          <tr>
            <th style={{ width: "33%", textAlign: "center" }}>Scenario</th>
            <th style={{ width: "33%", textAlign: "center" }}>Purposes</th>
            <th style={{ width: "33%", textAlign: "center" }}>
              Categories of information processed
            </th>
          </tr>
          <tr>
            <StyledTD>When you register to our Service</StyledTD>
            <td>
              providing you with the functionality of the Service, contacting
              you regarding administrative issues related to the Services, this
              Policy, our agreement with you, support and maintenance
            </td>
            <td>
              At your discretion – one of the following: Email address;Account
              username;Mobile phone number; Registering to the Service is
              mandatory, but you do not have a legal obligation to do so. If you
              choose not to provide us with your information, you will not be
              able to use the Service.
            </td>
          </tr>
          <tr>
            <td>When you provide us with access to your device components</td>
            <td>Providing the Service and users authentication</td>
            <td>
              Data from components such as WiFi, Internet/3G access, Unique
              device ID (UDID) or UUID;
            </td>
          </tr>
          <tr>
            <td>Subscribing to our newsletters</td>
            <td>
              providing you with the newsletter you requested, such as
              newsletters and marketing materials regarding our current
              solutions and solutions which are in development
            </td>
            <td>
              Email address; You do not have a legal obligation to provide us
              with the abovementioned information; however, if you choose to not
              share this information with us, we may not be able to send you our
              newsletter with information about our business and offers.
            </td>
          </tr>
          <tr>
            <td>Uploading or sharing user content through the Service</td>
            <td>
              providing you with the functionality of the Service you requested
            </td>
            <td>
              Personal Data that the Customer feeds into the Service regarding
              its own data subjects, such as pictures, audio and voiceprints. We
              refer to this as ''User Content''. Providing User Content is not
              mandatory. You may choose to use our Service without providing or
              sharing any User Content. In that case, you will not be required
              to provide the above information. Please use discretion when
              providing User Content that includes the personal information of
              you or others. YOU ARE SOLELY LIABLE FOR PROTECTING THIRD PARTIES’
              AND YOUR OWN PRIVACY, AND FOR OBTAINING THE PRIOR CONSENT OF
              INDIVIDUALS’ WHOSE PERSONAL INFORMATION IS INCLUDED IN THE USER
              CONTENT. WE WILL NOT BEAR LIABILITY FOR ANY DAMAGES THAT MIGHT
              INCUR TO YOU OR TO THIRD PARTIES AS A RESULT OF THE PUBLICATION OF
              PERSONAL INFORMATION.
            </td>
          </tr>
          <tr>
            <td>
              Contacting us with an inquiry through our Online contact form or
              Email;
            </td>
            <td>Responding to your inquiry, our business development</td>
            <td>
              Your Email address; the subject of your inquiry and the text of
              your message. At your discretion, you may also provide us with
              your Full Name, Country, Phone number and your position at the
              company. We refer to this as “Inquiry Information”. You do not
              have a legal obligation to provide your Inquiry Information;
              however, if you choose to not share this information with us, we
              may not be able to respond to your inquiry.
            </td>
          </tr>
          <tr>
            <td>When you provide us with your feedback and reviews</td>
            <td>
              Responding to your feedback and reviews, our business development
            </td>
            <td>
              Email address, and the feedback or review. We refer to this as
              “Feedback”.
            </td>
          </tr>
          <tr>
            <td>Use of cookies on the Service</td>
            <td>
              Facilitate a Service feature that the user specifically requested,
              Analyze the Service usage to evaluate and improve its performance,
              improve user experience on the Service, inform and serve
              personalized ads more relevant to user interests
            </td>
            <td>
              IP address from which you access the Service, time and date of
              access, type of device and browser used, language used, links
              clicked via a mouse or a touch screen, and actions taken while
              using the Service.
            </td>
          </tr>
        </StyledTable>
        <BlueTitle style={{ marginTop: "16px" }}>
          Methods and sources for collecting your personal information
        </BlueTitle>
        <StyledP>
          We collect the personal information from several sources:
        </StyledP>
        <ul>
          <li>
            Directly from you when you register to our Service or when provided
            to us through our Online contact form and Email;
          </li>
          <li>
            From our service providers helping us to operate the Service.{" "}
          </li>
          <li>
            Through the device you use to access our Service, including through
            third party cookies and analytics tools, such as JWT Tokens;{" "}
          </li>
          <li>
            You are not legally obligated to provide us with your personal
            information, but if you do not, we will not be able to handle or
            respond to your inquiry, or fulfill your request to register or to
            use our Service functionalities.
          </li>
        </ul>
        <BlueTitle>Sharing your personal information</BlueTitle>
        <StyledP>
          We will not share your information with third parties, except in the
          events listed below or when you provide us your explicit and informed
          consent.
        </StyledP>
        <StyledTable>
          <tr>
            <th style={{ width: "40%", textAlign: "center" }}>Scenario</th>
            <th style={{ width: "40%", textAlign: "center" }}>Purposes</th>
            <th style={{ width: "20%", textAlign: "center" }}>
              Third parties involved
            </th>
          </tr>
          <tr>
            <td>
              We will share your information with our service providers who
              assist us with the internal operations of the Service. These
              companies are authorized to use your personal information in this
              context only as necessary to provide these services to us and not
              for their own promotional purposes
            </td>
            <td>Operating the Service and our business</td>
            <td>
              AWS Subject to the following additional Policies:
              <a href="https://aws.amazon.com/privacy/">
                {` https://aws.amazon.com/privacy/`}
              </a>
            </td>
          </tr>
          <tr>
            <td>
              If you abused your rights to use the Service or violated any
              applicable law while doing business with us.
            </td>
            <td>
              Responding to, handling, and mitigating suspected violations of
              law in connection with our business.
            </td>
            <td>Competent authorities, legal counsels, and advisors</td>
          </tr>
          <tr>
            <td>
              If a judicial, governmental, or regulatory authority requires us
              to disclose your information.
            </td>
            <td>
              Complying with a binding request from a competent authority.
            </td>
            <td>Competent authorities.</td>
          </tr>
          <tr>
            <td>
              If the operation of the Service or our business is organized
              within a different framework, or through another legal structure
              or entity.
            </td>
            <td>
              Enabling a structural change in the operation of the Service and
              our business.
            </td>
            <td>
              The target entity of the merger or acquisition, legal counsels,
              and advisors.
            </td>
          </tr>
        </StyledTable>
        <BlueTitle style={{ marginTop: "10px" }}>
          Data retention and security
        </BlueTitle>
        <StyledP style={{ fontWeight: "bold" }}>
          We retain your information for as long as needed to operate the
          Service, and thereafter as needed for recordkeeping matters.
        </StyledP>
        <StyledP>
          We will retain your information for as long as needed to operate the
          Service. Thereafter, we will still retain your personal information as
          necessary to comply with our legal obligations, resolve disputes,
          establish, and defend legal claims and enforce our agreements. The
          overall period of retention is approximately 7 years.
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>
          We implement measures to secure your information
        </StyledP>
        <StyledP>
          We implement measures to reduce the risks of damage, loss of
          information and unauthorized access or use of information, such as
          TLS, HTTPS, vectoring, single tenant and Multi Tenant, access to
          servers is protected by keys and secrets, seperation ect. However,
          these measures do not provide absolute information security.
          Therefore, although efforts are made to secure your personal
          information, there is no guarantee that it will be immune from
          information security risks.
        </StyledP>
        <BlueTitle>
          Additional information for individuals in the EU or UK
        </BlueTitle>
        <StyledP style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Controller, GDPR and UK representatives
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>
          Corsound AI Ltd. is the data controller of the personal information
          collected via the Service
        </StyledP>
        <StyledTable>
          <tr>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "16%" }}>Address</th>
            <th style={{ width: "32%" }}>EU GDPR Representative</th>
            <th style={{ width: "32%" }}>UK GDPR Representative</th>
          </tr>
          <tr>
            <td>Corsound AI Ltd.</td>
            <td style={{ textAlign: "center" }}>
              Montefiore St 39, Tel AvivYafo, Israe
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr></tr>
        </StyledTable>
        <StyledP
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            marginTop: "10px",
          }}
        >
          International data transfers
        </StyledP>
        <StyledP>
          To facilitate processing your information through the Service and by
          our service providers, we will transfer your information to countries
          such as EU;. We do so under the terms of a data transfer agreement
          which contain standard data protection contract clauses with adequate
          safeguards determined by the EU Commission and UK Information
          Commissioner’s Office.
        </StyledP>
        <StyledP style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Legal basis for processing your personal data
        </StyledP>
        <StyledTable>
          <tr>
            <th>Purpose or Scenario</th>
            <th>Legal Basis</th>
          </tr>
          <tr>
            <td>Registering to our Service.</td>
            <td>
              Our legitimate interests in providing you with the Service you
              requested, contacting you regarding administrative issues related
              to the Services, this Policy, our agreement with you, support and
              maintenance{" "}
            </td>
          </tr>
          <tr>
            <td>Acssesing to your device components</td>
            <td>
              Our legitimate interest in maintaining, developing, and enhancing
              the Service, Providing the Service and users authentication
            </td>
          </tr>
          <tr>
            <td>Subscribing to our newsletters</td>
            <td>
              Your consent for us to providing you with the newsletter you
              requested.
            </td>
          </tr>
          <tr>
            <td>Processing User Content you provided through the Service</td>
            <td>
              Our legitimate intrest in providing you with the functionality of
              the service, and the performance of our contract.
            </td>
          </tr>
          <tr>
            <td>Responding to your inquiry</td>
            <td>
              Our legitimate intrest in responding to your inquiry, and our
              business development
            </td>
          </tr>
          <tr>
            <td>When you provide us with your Feedback and reviews</td>
            <td>
              Our legitimate interest in developing and enhancing our business
              and the Service, responding to your Feedback or reviews
            </td>
          </tr>
          <tr>
            <td>Use of cookies on the Service</td>
            <td>
              Our legitimate interests in providing you with the Service you
              requested, tailoring the Service to your preferences
            </td>
          </tr>
          <tr>
            <td>Third party cookies</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>
              Responding to, handling, and mitigating suspected violations of
              law in connection with our business
            </td>
            <td>
              Legitimate interests in defending and enforcing against violations
              and breaches that are harmful to our business
            </td>
          </tr>
          <tr>
            <td>Complying with a binding request from a competent authority</td>
            <td>
              Legitimate interests in complying with mandatory legal
              requirements imposed on us
            </td>
          </tr>
          <tr>
            <td>
              Enabling a structural change in the operation of the Service and
              our business{" "}
            </td>
            <td>Legitimate interests in our business continuity</td>
          </tr>
        </StyledTable>
        <StyledP
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            marginTop: "10px",
          }}
        >
          Data subject rights
        </StyledP>
        <StyledP>
          If you are in the EU or the UK, you have the following rights under
          the GDPR:
        </StyledP>
        <StyledP>
          <strong>Right to Access</strong> and receive a copy of your personal
          information that we process.
        </StyledP>
        <StyledP>
          <strong>Right to Rectify</strong> inaccurate personal information we
          have concerning you and to have incomplete personal information
          completed.
        </StyledP>
        <StyledP>
          <strong>Right to easily and at any time withdraw your consent</strong>{" "}
          to us processing your personal data to email you our newsletters. The
          withdrawal of consent will not affect the lawfulness of processing
          based on consent before its withdrawal
        </StyledP>
        <StyledP>
          <strong>Right to easily and at any time withdraw your consent</strong>{" "}
          to the use of non-essential cookies on our Service. The withdrawal of
          consent will not affect the lawfulness of processing based on consent
          before its withdrawal.
        </StyledP>
        <StyledP>
          <strong>Right to Data Portability</strong>, that is, to receive the
          personal information that you provided to us, in a structured,
          commonly used, and machine-readable format. You have the right to
          transmit this data to another person or entity. Where technically
          feasible, you have the right to have your personal information
          transmitted directly from us to the person or entity you designate.
        </StyledP>
        <StyledP>
          <strong>Right to Object</strong> to our processing of your personal
          information based on our legitimate interest. However, we may override
          the objection if we demonstrate compelling legitimate grounds, or if
          we need to process such personal information for the establishment,
          exercise, or defense of legal claims.
        </StyledP>
        <StyledP>
          <strong>Right to Restrict</strong> us from processing your personal
          information (except for storing it): (a) if you contest the accuracy
          of the personal information (in which case the restriction applies
          only for a period enabling us to determine the accuracy of the
          personal information); (b) if the processing is unlawful and you
          prefer to restrict the processing of the personal information rather
          than requiring the deletion of such data by us; (c) if we no longer
          need the personal information for the purposes outlined in this
          Policy, but you require the personal information to establish,
          exercise or defend legal claims; or (d) if you object to our
          processing based on our legitimate interest (in which case the
          restriction applies only for the period enabling us to determine
          whether our legitimate grounds for processing override yours).
        </StyledP>
        <StyledP>
          <strong> Right to be Forgotten</strong>. Under certain circumstances,
          such as when you object to our processing of your personal information
          based on our legitimate interest and there are no overriding
          legitimate grounds for the processing, you have the right to ask us to
          erase your personal information. However, notwithstanding such
          request, we may still process your personal information if it is
          necessary to comply with our legal obligations, or for the
          establishment, exercise, or defense of legal claims. If you wish to
          exercise any of these rights, please contact us through the channels
          listed in this Policy.
        </StyledP>
        <StyledP>
          When you contact us, we reserve the right to ask for reasonable
          evidence to verify your identity before we provide you with
          information. Where we are not able to provide you with information
          that you have asked for, we will explain the reason.
        </StyledP>
        <StyledP>
          Subject to applicable law, you have the right to lodge a complaint
          with your local data protection authority. If you are in the EU, then
          according to Article 77 of the GDPR, you can lodge a complaint to the
          supervisory authority, in the Member State of your residence, place of
          work or place of alleged infringement of the GDPR. For a list of
          supervisory authorities in the EU, click{" "}
          <a
            href="http://ec.europa.eu/newsroom/article29/document.cfm?action=display&doc_id=50061"
            type="download"
          >
            here
          </a>
          .
        </StyledP>
        <br />
        <StyledP>
          If you are in the UK, you can lodge a complaint to the Information
          Commissioner’s Office (ICO) pursuant to the instructions provided{" "}
          <a href="https://ico.org.uk/make-a-complaint/">here</a>
        </StyledP>
        <BlueTitle>
          Additional information for individuals in California
        </BlueTitle>
        <StyledP>
          If you are an individual residing in California, we provide you with
          the following information pursuant to the California Privacy Rights
          Act (CPRA). This is also the information we have collected in the past
          12 months.
        </StyledP>
        <StyledP style={{ marginBottom: "10px" }}>
          We do not sell your personal information and have not done so in the
          past 12 months. We do not share you personal information for
          cross-context behavioral advertising
        </StyledP>
        <StyledTable>
          <tr>
            <th style={{ width: "15%" }}>
              Caxtegories of personal information (under the CCPA)
            </th>
            <th style={{ width: "50%" }}>
              Specific types of personal information collected
            </th>
            <th style={{ width: "35%" }}>
              Business Purposes under the CCPA for which the information is used
            </th>
          </tr>
          <tr>
            <td>Identifiers</td>
            <td>
              Real name, IP address, unique personal identifier, email address
            </td>
            <td
              style={{
                borderBottom: "none",
              }}
            >
              <ul>
                <li>
                  helping to ensure security and integrity to the extent the use
                  of the consumer’s personal information is reasonably necessary
                  and proportionate for these purposes
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Biometric information</td>
            <td>Voice recordings and voiceprints in User Content</td>
            <td
              style={{
                borderTop: "none",
                borderBottom: "none",
                backgroundColor: "#FDE9D9",
              }}
            >
              <ul>
                <li>
                  debugging to identify and repair errors that impair existing
                  intended functionalit
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Internet or other electronic network activity information</td>
            <td>IP Address, mouse clicks, authentication Token etc.</td>
            <td
              style={{
                paddingBottom: 0,
                borderTop: "none",
                borderBottom: "none",
                verticalAlign: "bottom",
              }}
            >
              <ul
                style={{
                  marginBottom: 0,
                }}
              >
                <li>
                  short-term, transient use, including, but not limited to,
                  nonpersonalized advertising shown as part of a consumer’s
                  current interaction with the business, provided that the
                  consumer’s personal information is not disclosed to
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td>Audio in User Content</td>
            <td
              style={{
                paddingTop: 0,
                paddingLeft: "20px",
                verticalAlign: "initial",
                borderTop: "none",
                borderBottom: "none",
                backgroundColor: "#FDE9D9",
              }}
            >
              another third party and is not used to build a profile about the
              consumer or otherwise alter the consumer’s experience outside the
              current interaction with the business
            </td>
          </tr>

          <tr>
            <td>
              Inferences drawn from any of the above information to create a
              profile about a consumer
            </td>
            <td>
              Audio to create voiceprint and biometric data from User Content
            </td>
            <td
              style={{
                borderTop: "none",
              }}
            >
              <ul>
                <li>
                  performing services on behalf of the business, including
                  maintaining or servicing accounts, providing customer service,
                  processing or fulfilling orders and transactions, verifying
                  customer information, processing payments, providing
                  financing, providing analytic services, providing storage, or
                  providing similar services on behalf of the business;
                </li>
              </ul>
            </td>
          </tr>
        </StyledTable>
        <StyledP style={{ fontWeight: "bold", marginTop: "10px" }}>
          Disclosures to third parties
        </StyledP>
        <StyledP>
          The chart below explains what is the personal information we disclosed
          for a business purpose to third parties in the preceding 12 months.
        </StyledP>
        <StyledTable>
          <tr>
            <th style={{ width: "40%" }}>
              Categories of personal information (under the CPRA)
            </th>
            <th style={{ width: "60%" }}>
              Categories of third parties to whom we disclose your information
              and the specific business or commercial purpose for the disclosure
            </th>
          </tr>
          <tr>
            <td>Identifiers</td>
            <td
              style={{
                borderBottom: "none",
              }}
            >
              <ul>
                <li>
                  cookie providers, for the same purposes indicated in the chart
                  above
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Internet or other electronic network activity information</td>
            <td
              style={{
                borderTop: "none",
                backgroundColor: "#FDE9D9",
              }}
            >
              <ul>
                <li>
                  Competent authorities, legal counsels, and advisors if you
                  abused your rights to use the Service or violated any
                  applicable law in the course of doing business with us.
                </li>
                <li>
                  Judicial, governmental, or regulatory authority if they
                  require us to disclose your information
                </li>
                <li>
                  Target entity of the merger or acquisition, legal counsels,
                  and advisors If the operation of the Service or our business
                  is organized within a differ
                </li>
              </ul>
            </td>
          </tr>
        </StyledTable>
        <StyledP
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            marginTop: "6px",
            marginBottom: 0,
          }}
        >
          Your rights under the CPRA if you are a resident of California
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>
          Knowing the personal information we collect about you
        </StyledP>
        <StyledP>You have the right to know:</StyledP>
        <ul>
          <li>
            The categories of personal information we have collected about you.
          </li>
          <li>
            The categories of sources from which the personal information is
            collected.
          </li>
          <li>
            Our business or commercial purpose is for collecting personal
            information.
          </li>
          <li>
            The categories of third parties with whom we share personal
            information, if any.
          </li>
          <li>
            The specific pieces of personal information we have collected about
            you.
          </li>
        </ul>
        <StyledP style={{ fontWeight: "bold" }}>Right to deletion</StyledP>
        <br />
        <StyledP>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </StyledP>
        <ul>
          <li>Delete your personal information from our records; and</li>

          <li>
            Direct any service providers to delete your personal information
            from their records.
          </li>
        </ul>
        <StyledP>
          Please note that we may not delete your personal information if it is
          necessary to:
        </StyledP>
        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between you and us.
          </li>
          <li>
            Help to ensure security and integrity to the extent the use of the
            consumer’s personal information is reasonably necessary and
            proportionate for those purposes.
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended
            functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law.
          </li>

          <li>
            Comply with the California Electronic Communications Privacy Act.
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research that conforms or adheres to all other
            applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            ability to complete such research, provided we have obtained your
            informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us and compatible with
            the context in which you provided the information. or
          </li>
          <li>Comply with an existing legal obligation.</li>
        </ul>
        <StyledP>
          We also will deny your request to delete if it proves impossible or
          involves disproportionate effort, or if another exception to the CPRA
          applies. We will provide you a detailed explanation that includes
          enough facts to give you a meaningful understanding as to why we
          cannot comply with the request to delete your information.
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>
          Right to correct inaccurate personal information
        </StyledP>
        <StyledP>
          If we receive a verifiable request from you to correct your
          information and we determine the accuracy of the corrected information
          you provide, we will correct inaccurate personal information that we
          maintain about you.
        </StyledP>
        <StyledP>
          In determining the accuracy of the personal information that is the
          subject of your request to correct, we will consider the totality of
          the circumstances relating to the contested personal information.
        </StyledP>
        <StyledP>
          {" "}
          We also may require that you provide documentation if we believe it is
          necessary to rebut our own documentation that the personal information
          is accurate.
        </StyledP>
        <StyledP>
          We may deny your request to correct in the following cases:
        </StyledP>
        <ul>
          <li>
            We have a good-faith, reasonable, and documented belief that your
            request to correct is fraudulent or abusive.
          </li>
          <li>
            We determine that the contested personal information is more likely
            than not accurate based on the totality of the circumstances.
          </li>
          <li>Conflict with federal or state law.</li>
          <li>Other exception to the CPRA.</li>
          <li> Inadequacy in the required documentation</li>
          <li>
            Compliance proves impossible or involves disproportionate effort.
          </li>
        </ul>
        <StyledP>
          We will provide you a detailed explanation that includes enough facts
          to give you a meaningful understanding as to why we cannot comply with
          the request to correct your information
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>
          Protection against discrimination
        </StyledP>
        <span>
          You have the right to not be discriminated against by us because you
          exercised any of your rights under the CPRA.
        </span>
        <StyledP>
          Exercising your CPRA rights by yourself or through an authorized agent
        </StyledP>
        <StyledP>
          If you would like to exercise any of your CPRA rights as described in
          this Policy, please contact us at
          <a href="https://www.corsound.ai/"> https://www.corsound.ai/</a>.
        </StyledP>
        <StyledP>
          We will ask you for additional information to confirm your identity
          and for security purposes, before disclosing the personal data
          requested to you, by using a two or three points of data verification
          process, depending on the type of information you require and the
          nature of your request.
        </StyledP>
        <StyledP>
          We may ask you for additional information to confirm your identity and
          for security purposes, before disclosing the personal data requested
          to you, by using a two or three points of data verification process,
          depending on the type of information you require.
        </StyledP>
        <StyledP>
          You may also designate an authorized agent to make a request under the
          CPRA on your behalf. To do so, you need to provide the authorized
          agent with written permission to do so and the agent will need to
          submit to us proof that they have been authorized by you. We will also
          require that you verify your own identity, as explained below.
        </StyledP>
        <StyledP style={{ fontWeight: "bold" }}>Do Not Track</StyledP>
        <StyledP>
          Our Do Not Track Notice. We do not currently respond or take any
          action with respect to web browser “do not track” signals or other
          mechanisms that provide consumers the ability to exercise choice
          regarding the collection of Personal Data about a User’s online
          activities over time and across third-party web sites or online
          services. We do allow third parties who provide us with analytics
          tools, to collect Personal Data about a User’s online activities when
          a User uses the Service.
        </StyledP>
        <StyledP>
          We use your Analytics Information to provide you with our Service and
          to customize your experience.
        </StyledP>
        <StyledP>
          We will also respond to Global Privacy Control Signals your device may
          send to our Service for opting out of such signals. Please note if you
          opt out, you will receive a less personalized experience when using
          our Service.
        </StyledP>
      </p>
    </Content>
  );
};

export default LicenseContent;
