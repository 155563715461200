import styled from "styled-components";

export const TextTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #acb9c9;
`;

export const Text = styled.div`
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #616f81;
  margin-top: 8px;
  padding: 0 0 8px 0;
`;

export const CallWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 10px;
  width: 100%;
  gap: 24px;
`;

export const BackWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 0;
  width: 100%;
  font-weight: 700;
`;
