import React, { FC } from "react";

import { Arrow } from "../../../assets";
import PanelHeader from "../../PanelHeader";
import PdaContent from "../Licensing/PdaModal/PdaContent";

import { ContentWrapper, StyledA } from "./Pda.styled";

const PdaScreen: FC = () => {
  return (
    <>
      <PanelHeader
        title="Data protection agreement"
        subTitle={
          <StyledA onClick={() => history.back()} style={{ cursor: "pointer" }}>
            <Arrow />
            <div style={{ width: "5px" }}></div>
            Back
          </StyledA>
        }
      />
      <ContentWrapper>
        <PdaContent />
      </ContentWrapper>
    </>
  );
};

export default PdaScreen;
