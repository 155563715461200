import styled from "styled-components";

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IdWrapper = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 75px;
  width: 75px;
  height: 1.2em;
  white-space: nowrap;
`;
