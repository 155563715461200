import { FC } from "react";

export const Export: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.52813 5.96232C7.38787 5.82172 7.3091 5.63123 7.3091 5.43264C7.3091 5.23404 7.38787 5.04355 7.52813 4.90295L11.4656 0.965449C11.6078 0.824613 11.7999 0.745605 12 0.745605C12.2001 0.745605 12.3922 0.824613 12.5344 0.965449L16.4719 4.90295C16.6121 5.04355 16.6909 5.23404 16.6909 5.43264C16.6909 5.63123 16.6121 5.82172 16.4719 5.96232C16.4021 6.03319 16.3189 6.08946 16.2272 6.12788C16.1354 6.16629 16.037 6.18607 15.9375 6.18607C15.838 6.18607 15.7396 6.16629 15.6478 6.12788C15.5561 6.08946 15.4729 6.03319 15.4031 5.96232L12.75 3.3092V11.9998C12.75 12.1987 12.671 12.3895 12.5303 12.5302C12.3897 12.6708 12.1989 12.7498 12 12.7498C11.8011 12.7498 11.6103 12.6708 11.4697 12.5302C11.329 12.3895 11.25 12.1987 11.25 11.9998V3.3092L8.59687 5.96232C8.52709 6.03319 8.4439 6.08946 8.35216 6.12788C8.26042 6.16629 8.16196 6.18607 8.0625 6.18607C7.96304 6.18607 7.86458 6.16629 7.77284 6.12788C7.6811 6.08946 7.59791 6.03319 7.52813 5.96232ZM18.75 8.24982H16.5C16.3011 8.24982 16.1103 8.32884 15.9697 8.46949C15.829 8.61015 15.75 8.80091 15.75 8.99982C15.75 9.19874 15.829 9.3895 15.9697 9.53016C16.1103 9.67081 16.3011 9.74982 16.5 9.74982H18.75V19.4998H5.25V9.74982H7.5C7.69891 9.74982 7.88968 9.67081 8.03033 9.53016C8.17098 9.3895 8.25 9.19874 8.25 8.99982C8.25 8.80091 8.17098 8.61015 8.03033 8.46949C7.88968 8.32884 7.69891 8.24982 7.5 8.24982H5.25C4.85218 8.24982 4.47064 8.40786 4.18934 8.68916C3.90804 8.97047 3.75 9.352 3.75 9.74982V19.4998C3.75 19.8976 3.90804 20.2792 4.18934 20.5605C4.47064 20.8418 4.85218 20.9998 5.25 20.9998H18.75C19.1478 20.9998 19.5294 20.8418 19.8107 20.5605C20.092 20.2792 20.25 19.8976 20.25 19.4998V9.74982C20.25 9.352 20.092 8.97047 19.8107 8.68916C19.5294 8.40786 19.1478 8.24982 18.75 8.24982Z"
      fill="black"
    />
  </svg>
);
