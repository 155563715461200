import { Empty, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC } from "react";

import { DataType } from "../screens/AgentsManagement/ManagementTable/ManagementTable";
import { DataClientType } from "../screens/CLientsManagement/ClientTable/ClientTable";

import { TableStyledWrapper } from "./Table.styled";

type TableStyledProps = {
  // TODO update in future
  dataSource: any;
  columns: ColumnsType<DataType | DataClientType>;
  onChange: any;
  setOffset?: (val: number) => void;
  offset?: number;
  limit?: number;
  total: number;
};
const TableStyled: FC<TableStyledProps> = ({
  dataSource,
  columns,
  onChange,
  setOffset,
  offset = 1,
  limit = 10,
  total,
}) => {
  return (
    <TableStyledWrapper>
      <Table
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data found"
            />
          ),
        }}
        scroll={{ x: 800 }}
        pagination={{
          total: total,
          current: offset,
          defaultPageSize: limit,
          onChange: (page) => setOffset?.(page),
        }}
        bordered={false}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
      />
    </TableStyledWrapper>
  );
};

export default TableStyled;
