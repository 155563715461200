import { Modal } from "antd";
import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const ModalStyledWrapper = styled(Modal)`
  & .ant-modal-content {
    box-shadow: 1px 1px 2px 2px ${Colors.SHADOW_COLOR};
    border-radius: 12px;
    padding: 32px 30px 30px;
  }

  & .ant-modal-close {
    top: 30px;
    right: 30px;
  }

  & .ant-modal-title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    letter-spacing: -0.02em;

    color: ${Colors.TITLE_BLACK_MUTED};
  }

  & .ant-modal-close-x {
    line-height: 22px;
    color: ${Colors.BLACK_COLOR};
  }

  & .ant-modal-footer {
    margin-top: 24px;
    width: 100%;
    flex-direction: revert;
    justify-content: start;

    button:first-child {
      border: none;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: ${Colors.SILVER_BLUR_COLOR};
    }

    button:last-child {
      padding: 8px 16px;
      background: ${Colors.BUTTON_COLOR};
      border-radius: 4px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: ${Colors.WHITE_COLOR};
    }
  }
`;
