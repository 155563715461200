import { FC } from "react";

export const FileExport: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9767 7.37856C8.8662 7.26055 8.80414 7.10066 8.80414 6.93397C8.80414 6.76728 8.8662 6.6074 8.9767 6.48939L12.079 3.18452C12.191 3.06631 12.3423 3 12.5 3C12.6577 3 12.809 3.06631 12.921 3.18452L16.0233 6.48939C16.1338 6.6074 16.1959 6.76728 16.1959 6.93397C16.1959 7.10066 16.1338 7.26055 16.0233 7.37856C15.9683 7.43803 15.9028 7.48527 15.8305 7.51751C15.7582 7.54975 15.6806 7.56635 15.6023 7.56635C15.5239 7.56635 15.4463 7.54975 15.3741 7.51751C15.3018 7.48527 15.2362 7.43803 15.1813 7.37856L13.0909 5.1517V12.446C13.0909 12.613 13.0287 12.7731 12.9178 12.8911C12.807 13.0092 12.6567 13.0755 12.5 13.0755C12.3433 13.0755 12.193 13.0092 12.0822 12.8911C11.9713 12.7731 11.9091 12.613 11.9091 12.446V5.1517L9.81875 7.37856C9.76377 7.43803 9.69823 7.48527 9.62595 7.51751C9.55367 7.54975 9.47609 7.56635 9.39773 7.56635C9.31937 7.56635 9.24179 7.54975 9.16951 7.51751C9.09723 7.48527 9.03169 7.43803 8.9767 7.37856ZM17.8182 8.29853H16.0455C15.8887 8.29853 15.7384 8.36485 15.6276 8.4829C15.5168 8.60096 15.4545 8.76107 15.4545 8.92802C15.4545 9.09498 15.5168 9.25509 15.6276 9.37315C15.7384 9.4912 15.8887 9.55752 16.0455 9.55752H17.8182V17.741H7.18182V9.55752H8.95455C9.11126 9.55752 9.26156 9.4912 9.37238 9.37315C9.4832 9.25509 9.54545 9.09498 9.54545 8.92802C9.54545 8.76107 9.4832 8.60096 9.37238 8.4829C9.26156 8.36485 9.11126 8.29853 8.95455 8.29853H7.18182C6.86838 8.29853 6.56778 8.43117 6.34615 8.66728C6.12451 8.90339 6 9.22362 6 9.55752V17.741C6 18.0749 6.12451 18.3951 6.34615 18.6312C6.56778 18.8674 6.86838 19 7.18182 19H17.8182C18.1316 19 18.4322 18.8674 18.6539 18.6312C18.8755 18.3951 19 18.0749 19 17.741V9.55752C19 9.22362 18.8755 8.90339 18.6539 8.66728C18.4322 8.43117 18.1316 8.29853 17.8182 8.29853Z"
      fill="#1E1E1E"
    />
  </svg>
);
