import { AxiosInstance } from "axios";

import { clientEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type CreateClient = {
  name: string;
  email: string;
  phone: string;
  claim_id: string;
};

export type ManageList = {
  org_list_id: number;
  org_list_name: string;
};

export type UpdateClient = Partial<CreateClient> & { id?: string };

export type ClientProps = {
  client_id: string;
  dt_create: string;
  dt_update: string;
  name: string;
  email: string;
  phone: string;
  claim_id: string;
  dt_signature: string;
  dt_last_call: null | string;
  lists: ManageList[];
};

export type Clients = {
  offset: number;
  limit: number;
  operators: ClientProps[];
};

class Client {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  get = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${clientEndpoints.client}${queries}`);
  };

  create = async (newClient: CreateClient): Promise<any> => {
    return this.requestInstance.post(`${clientEndpoints.client}`, {
      name: newClient.name,
      email: newClient.email,
      phone: newClient.phone,
      claim_id: newClient.claim_id,
    });
  };

  getById = async (id: string): Promise<any> => {
    return this.requestInstance.get(`${clientEndpoints.client}/${id}`);
  };

  update = async (updateClient: UpdateClient): Promise<any> => {
    const { id, ...rest } = updateClient;
    return this.requestInstance.patch(`${clientEndpoints.client}/${id}`, rest);
  };

  delete = async (id: string): Promise<any> => {
    return this.requestInstance.delete(
      `${clientEndpoints.client}/${id}${clientEndpoints.signature}`
    );
  };

  deleteClient = async (id: string): Promise<any> => {
    return this.requestInstance.delete(`${clientEndpoints.client}/${id}`);
  };
}

export const clientApi = new Client(axios);
