import moment from "moment/moment";
import { FC } from "react";
import { NotificationContainer } from "react-notifications";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";

import { callApi } from "../../../../api/CallApi/Call";
import { Arrow } from "../../../../assets";
import { Colors } from "../../../../utils/colors";

import { BackWrapper, CallWrapper, Text, TextTitle } from "./CallScreen.styled";

const CallScreen: FC = () => {
  const { id } = useParams();

  const { data } = useQuery({
    queryFn: () => callApi.getById(id as string),
  });

  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const notEditableText = (title: string, text?: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderBottom: `1px solid ${Colors.TITLE_INNER_COLOR}`,
        }}
      >
        <TextTitle>{title}</TextTitle>
        <Text>{text}</Text>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <NotificationContainer />
      {!isAuth && <Navigate to={"/login"} replace={true} />}
      {data?.call_id === id && (
        <CallWrapper>
          <BackWrapper onClick={() => history.back()}>
            <Arrow></Arrow>
            <div style={{ width: "5px" }}></div>
            Back
          </BackWrapper>
          {notEditableText("Call id:", data.call_id)}
          {notEditableText("Client name:", data?.client?.name)}
          {notEditableText("Claim id:", data?.client?.claim_id)}
          {notEditableText(
            "Call date:",
            moment(data.dt_create).format("YYYY[/]MM[/]D")
          )}
          {notEditableText(
            "Verification score:",
            data.verify?.[0]?.actual_score
          )}
          {notEditableText(
            "Verification Result:",
            data?.verify?.[0]?.["is_success"]
          )}
          {notEditableText("Age:", data?.age?.[0]?.age)}
          {notEditableText("Gender:", data?.gender?.[0]?.gender)}
          {notEditableText("Ethnicity:", data?.ethnicity?.[0]?.ethnicity)}
        </CallWrapper>
      )}
    </div>
  );
};

export default CallScreen;
