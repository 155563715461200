import { Header } from "antd/lib/layout/layout";
import React, { FC } from "react";

import { MenuArrow } from "../../assets";

import {
  HeaderStyled,
  SubTitle,
  Title,
  TitleMain,
  Breadcrumb,
} from "./PanelHeader.styled";

type MainLayoutProps = {
  title: string;
  tabTitle?: string;
  subTitle?: string | JSX.Element;
};
const PanelHeader: FC<MainLayoutProps> = ({ tabTitle, title, subTitle }) => {
  return (
    <HeaderStyled>
      <Header>
        <Breadcrumb>
          {tabTitle && (
            <>
              <TitleMain>{tabTitle}</TitleMain>
              <MenuArrow></MenuArrow>
            </>
          )}
          <Title>{title}</Title>
        </Breadcrumb>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </Header>
    </HeaderStyled>
  );
};

export default PanelHeader;
