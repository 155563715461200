import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const SearchWrapper = styled.div`
  & > div {
    padding: 0;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
`;

export const SelectStyled = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 31px;
  overflow: hidden;
  padding: 4px;
  gap: 12px;
`;

export const SelectItem = styled.div<{ active: boolean; width?: string }>`
  background-color: ${({ active }) =>
    active ? `${Colors.WHITE_COLOR}` : "initial"};
  width: ${({ width = "135px" }) => width};
  border-radius: 31px;
  padding: 3px;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  font-size: ${({ active }) => (active ? "14px" : "12px")};

  text-align: center;

  color: #2f363e;

  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
`;

export const PanelTitle = styled.div<{ active: boolean }>`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  font-size: 14px;
  color: #2f363e;
  opacity: ${({ active }) => (active ? 1 : 0.7)};
`;

export const StyledInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #1e1e1e;

  width: 183px;
  height: 39px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding-left: 33px;
  padding-top: 14px;

  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`;

export const InputPlaceholder = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: rgba(47, 54, 62, 0.5);
`;

export const AgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  max-height: 100px;
  gap: 8px;
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: column;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 185px;
`;
