import React, { FC } from "react";

import "./table.module.css";
import {
  Content,
  StyledLi,
  StyledP,
  StyledSilverTable,
  StyledTable,
  StyledTitle,
} from "./PdaModal.styled";

const PdaContent: FC = () => {
  return (
    <Content>
      <StyledTitle>Corsound AI Data Processing Addendum</StyledTitle>
      <p>
        <StyledP>
          This Data Processing Addendum (the “<strong>Addendum</strong>”) forms
          part of the underlying agreement, inclusive of any amendments to the
          underlying agreement, by which Corsound AI Ltd. (“
          <strong>Corsound AI</strong>” or “<strong>Company</strong>”) provides
          the Service to Customer (the “<strong>Customer Agreement</strong>”)
          and reflects the parties’ agreement with regard to the Processing of
          Personal Data (as defined below) in accordance with the requirements
          of the applicable Privacy Laws. All capitalized terms not defined
          herein shall have the meaning set forth in the Customer Agreement.
        </StyledP>
        <StyledP>
          WHEREAS, Corsound AI process certain personal data for Customer for
          the provision of the Service it provides pursuant to the Customer
          Agreement between the parties, and the parties wish to regulate
          Corsound AI’s processing of such personal data, through this Addendum.
        </StyledP>
        <StyledP>THEREFORE, the Parties have agreed as follows:</StyledP>
        <StyledTable>
          <tr>
            <th style={{ width: "50%", textAlign: "center" }}>Part</th>
            <th style={{ width: "50%", textAlign: "center" }}>
              Scope of applicability
            </th>
          </tr>
          <tr>
            <td>Part One – General provisions</td>
            <td>
              Applies where Company is processing Customer Data (as defined in
              the Customer Agreement), in the course of the provision of the
              Service.
            </td>
          </tr>
          <tr>
            <td>
              Part Two – EU General Data Protection Regulations (“
              <strong>GDPR</strong>”) and the United Kingdom's Data Protection
              Act 2018 and the GDPR as saved into United Kingdom law by virtue
              of Section 3 of the United Kingdom's European Union (Withdrawal)
              Act 2018 (“<strong>UK GDPR</strong>”)
            </td>
            <td>
              Applies where, in the course of the provision of the Service,
              Company is processing Customer Data (as defined in the Customer
              Agreement) that is subject to the GDPR and UK GDPR.
            </td>
          </tr>
          <tr>
            <td>
              Part Three – California Privacy Rights Act (Cal. Civ. Code
              §1798.100 et seq., Cal. Civ. Code §1798.140 or the regulations at
              11 C.C.R. §7000 et seq., collectively, the “<strong>CPRA</strong>
              ”)
            </td>
            <td>Applies where the CPRA applies to the Customer.</td>
          </tr>
          <tr>
            <td>
              Part Four – Israeli Privacy Protection Regulations (Information
              Security)
            </td>
            <td>
              Applies where, in the course of the provision of the Service,
              Company is processing Customer Data (as defined in the Customer
              Agreement) that is subject to the Israeli privacy laws.
            </td>
          </tr>
        </StyledTable>
        <StyledTitle>Part 1 (General Provisions)</StyledTitle>
        <ol>
          <StyledLi>
            <strong>Processing</strong>. Company is prohibited from retaining,
            using or disclosing the Customer Data for: (a) any purpose other
            than the purpose of properly performing, or for any commercial
            purpose other than as reasonably necessary to perform Customer’s
            processing instructions; (b) selling the Customer Data; and (c)
            retaining, using or disclosing the Customer Data outside of the
            direct business relationship between the parties. Company certifies
            that it understands the restriction specified in this subsection and
            will comply with it. For the avoidance of doubt, Company may compile
            Service Data (as the term is defined in the Customer Agreement and
            may process Customer Data for machine learning and AI development
            purposes.
          </StyledLi>
          <StyledLi>
            <strong>Data Subject Requests.</strong> Company will follow
            Customer’s instructions to accommodate data subjects’ requests to
            exercise their rights in relation to their information within the
            Customer Data, such as accessing their data, correcting it,
            restricting its processing or deleting it. Company will pass on to
            Customer requests that it receives (if any) from data subjects
            regarding their information processed by Company. Company shall
            notify Customer of the receipt of such request as soon as possible,
            and no later than five (5) business days from the receipt of such
            request, together with the relevant details.
          </StyledLi>
          <StyledLi>
            <strong> Disclosure.</strong> Unless legally prohibited, Company
            will provide Customer within reasonable time, notice of any request
            it receives from an Authority (as defined below) to produce or
            disclose Customer Data it has Processed on Customer’s behalf, so
            that Customer (or its customer) may contest or attempt to limit the
            scope of production or disclosure request.
          </StyledLi>
          <StyledLi>
            <strong>Data security.</strong> Considering the state of the art,
            the costs of implementation and the nature, scope, context and
            purposes of Company’s processing of Customer Data, Company shall
            implement and maintain reasonable security procedures and practices
            appropriate to the nature of the Customer Data, to protect the
            personal information from unauthorized access, destruction, use,
            modification, or disclosure (including data breaches).
          </StyledLi>
          <StyledLi>
            <strong>Data Breaches.</strong> Company shall without undue delay,
            and in any event within 72 hours, notify Customer of any actual
            accidental or unlawful destruction, loss, alteration, unauthorized
            disclosure of, or access to, Customer Data, that it becomes aware of
            regarding the Customer Data that it Processes. Company shall
            investigate the breach and take all available measures to mitigate
            the breach and prevent its reoccurrence. Company will reasonably
            cooperate in good-faith with Customer on issuing any statements or
            notices regarding such breaches, to authorities and data subjects.
          </StyledLi>
          <StyledLi>
            <strong>Subcontracting to suppliers</strong>. Customer authorizes
            Company to subcontract any of its Servicerelated activities which
            involve Processing the Customer Data. Company shall ensure that the
            third party is bound by substantially same obligations of the
            Company under this Part and shall supervise compliance thereof, and
            Company shall remain fully liable vis-à-vis the Customer for the
            performance of any such third party that fails to fulfil its
            obligations.
          </StyledLi>
          <StyledLi>
            <strong>Data Return and Deletion</strong>. Upon Customer’s request,
            Company will delete the Customer Data processed on Customer’s behalf
            under this Addendum from its own and its Processor’s systems, if
            applicable, or, at Customer’s choice, return such Customer Data or
            delete existing copies, within 30 business days of receiving a
            request to do so. Customer acknowledges and agrees that the Service
            shall automatically delete Customer Data within 60 days as of the
            termination of the Customer Agreement. Upon Customer’s request,
            Processor will furnish written confirmation that the Customer Data
            has been deleted or returned pursuant to this Section.
          </StyledLi>
        </ol>
        <StyledTitle>Part 2 (GDPR & UK GDPR)</StyledTitle>
        <ol>
          <StyledLi>
            DEFINITIONS
            <ol>
              <StyledLi value={1.2}>
                “<strong>Authority</strong>” means any supervisory authority
                with authority under Privacy Laws over all or any part of the
                provision or receipt of the Services or the Processing of
                Personal Data.
              </StyledLi>
              <StyledLi>
                “<strong>Customer</strong>” means the relevant entity that has
                entered into an agreement with Company to receive the Service,
                and if applicable, any of its Authorized Affiliates that have
                signed the Customer Agreement or any Order Forms related
                thereto.
              </StyledLi>
              <StyledLi>
                “<strong>Customer Data</strong>” has the same meaning as in the
                Customer Agreement.
              </StyledLi>
              <StyledLi>
                “<strong>Data Controller</strong>” means the entity that
                determines the purposes and means of the Processing of Personal
                Data.
              </StyledLi>
              <StyledLi>
                “<strong>Data Processor</strong>” means the entity that
                Processes Personal Data on behalf of the Data Controller.
              </StyledLi>
              <StyledLi>
                “<strong>Data Subject</strong>” means the individual to whom
                Personal Data relates (including Customer’s employee).
              </StyledLi>
              <StyledLi>
                “<strong>Data Subject Request</strong>” means a Data Subject’s
                request to access, correct, amend, transfer, block or delete
                that person’s Personal Data consistent with that person’s rights
                under Privacy Laws.
              </StyledLi>
              <StyledLi>
                “<strong>Instructions</strong>” means all provisions of the
                Customer Agreement, any Order Form, and any written amendments
                to either, concerning the Processing of Customer Data.
              </StyledLi>
              <br />
              <StyledLi>
                “<strong>Personal Data</strong>” has the meaning set forth in
                Privacy Laws, namely (and without limitation) any information
                relating to an identified or identifiable person, including
                sensitive data, where such data is submitted to Company as part
                of the Service.
              </StyledLi>
              <StyledLi>
                “<strong>Privacy Laws</strong>” means all applicable laws and
                regulations, including laws and regulations of the European
                Union, the European Economic Area and their member states,
                applicable to the Processing of Personal Data under the Customer
                Agreement, and including the General Data Protection Regulation
                (Regulation (EU) 2016/679) (the “GDPR”) as of its effective date
                and the United Kingdom's Data Protection Act 2018 and the GDPR
                as saved into United Kingdom law by virtue of Section 3 of the
                United Kingdom's European Union (Withdrawal) Act 2018 (“
                <strong>UK GDPR</strong>”).
              </StyledLi>
              <StyledLi>
                “<strong>Process</strong>”, “<strong>Processes</strong>” or “
                <strong>Processing</strong>” means any operation or set of
                operations which is performed upon Personal Data, whether or not
                by automatic means, including the collection, recording,
                organization, storage, updating, modification, retrieval,
                consultation, use, transfer, dissemination by means of
                transmission, distribution or otherwise making available,
                merging, linking as well as blocking, erasure or destruction.
              </StyledLi>
              <StyledLi>
                “<strong>Service(s)</strong>” has the same meaning as in the
                Customer Agreement.
              </StyledLi>
              <StyledLi>
                “<strong>Standard Contractual Clauses</strong>” means where the
                GDPR applies the Standard Contractual Clauses between
                controllers and processors under Article 28 (7) of Regulation
                (EU) 2016/679 of the European Parliament and of the Council and
                Article 29 (7) of Regulation (EU) 2018/1725 of the European
                Parliament and of the Council pursuant to Commission
                implementing decision (EU) 2021/914 of 4 June 2021 (the “
                <strong>EU SCCs</strong>”); and (ii) where the UK GDPR applies,
                the “International Data Transfer Addendum to the EU Commission
                Standard Contractual Clauses” issued by the Information
                Commissioner under s.119A(1) of the Data Protection Act 2018 (“
                <strong>UK Addendum</strong>”);
              </StyledLi>
              <StyledLi>
                “<strong>Subprocessor</strong>” means any Data Processor engaged
                by Company for Processing or having authorized access to
                Personal Data as part of the subcontractor’s role in delivering
                the Service.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              SUBJECT-MATTER, DURATION, NATURE AND PURPOSE OF THE PROCESSING,
              TYPE OF PERSONAL DATA AND CATEGORIES OF DATA SUBJECTS
            </strong>
            <ol style={{ marginTop: "10px" }}>
              <StyledLi>
                <strong>Subject-matter of the Processing</strong>. The
                Processing is carried out in an automated Processing using the
                Service provided by the Company. The Processing operations are
                further set out in Attachment A (Details of Data Processing).
              </StyledLi>

              <StyledLi>
                <strong>Duration of the Processing</strong>. The Processing
                begins and ends with performance of the Service for Customer, as
                specified in the Instructions.
              </StyledLi>

              <StyledLi>
                <strong>Nature and Purpose of the Processing</strong>. The
                purpose and object of the Processing of Personal Data by Company
                is to perform and provide the Service pursuant to the
                Instructions, as specified in the Customer Agreement and this
                Addendum, on behalf of and for the benefit of Customer, machine
                learning and for AI development purposes.
              </StyledLi>
              <StyledLi>
                <strong>
                  Type of Personal Data and Categories of Data Subjects
                </strong>
                . The type of personal data and categories of affected Data
                Subjects are set out in Attachment A (Details of Data
                Processing).
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              INSTRUCTIONS, COMMITMENT TO CONFIDENTIALITY
            </strong>
            <ol style={{ marginTop: "10px" }}>
              <StyledLi>
                <strong>Controller Processor Relationship</strong>. Other than
                for machine learning and for AI development purposes, Company
                shall only Process Personal Data on behalf of the Customer while
                providing the Services. The parties acknowledge that with regard
                to the Processing of Personal Data as between the parties,
                Customer acts as the Data Controller and Company acts as the
                Data Processor (e.g., even where Customer is a data processor on
                behalf of another data controller, as between the parties to the
                Customer Agreement, Customer will act as the Data Controller).
              </StyledLi>
              <StyledLi>
                <strong>Instructions</strong>. Company shall only Process
                Personal Data on behalf of and in accordance with the
                Instructions of Customer, as part of the Services, and shall
                protect Personal Data as Confidential Information. Customer
                shall ensure that its Instructions to Company shall comply with
                Privacy Laws. The Instructions are Customer’s complete and final
                instructions to Company for the Processing of Personal Data as
                part of the Services. Any additional or alternate instructions
                must be agreed upon separately with prior written agreement
                between Customer and Company. The foregoing applies unless
                Company is otherwise required by law to which it is subject (and
                in such a case, Company shall inform Customer of that legal
                requirement before processing, unless that law prohibits such
                information on important grounds of public interest) or where it
                processes data for machine learning and for AI development
                purposes.
              </StyledLi>
              <StyledLi>
                Where Company believes that compliance with any Customer’s
                Instructions infringes Privacy Law, Company shall immediately
                notify Customer thereof.
              </StyledLi>
              <StyledLi>
                <strong>Commitment to Confidentiality</strong>. Company shall
                ensure that its personnel engaged in the Processing of Personal
                Data are informed of the confidential nature of the Personal
                Data, have received appropriate training on their
                responsibilities and have committed themselves to
                confidentiality. Company shall ensure that access to Personal
                Data is limited to those personnel who require such access to
                perform the Customer Agreement.
              </StyledLi>
              <StyledLi>
                <strong>Compliance with Laws</strong>. Each party will comply
                with all laws, regulations and rules applicable to it in the
                performance of this Addendum, including Privacy Laws. Without
                prejudice to the foregoing, Customer shall have sole
                responsibility for the accuracy, quality, and legality of
                Personal Data, and the means by which Customer acquired Personal
                Data and shall establish the legal basis for Processing under
                Privacy Laws, including by providing all notices and obtaining
                all consents as may be required under Privacy Laws, in order for
                Company to Process Personal Data on behalf of the Customer
                pursuant to the Instructions.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>SECURITY</strong>
            <ol style={{ marginTop: "10px" }}>
              <StyledLi>
                <strong>Security Controls</strong> Company shall implement
                appropriate technical and organizational measures to protect and
                safeguard the Customer Data that is processed as part of the
                Services, against Personal Data Breaches(as defined under the
                Privacy Laws). In addition, Company and Customer shall have in
                place and shall comply with documented written policies and
                procedures, periodically reviewed, covering the administrative,
                physical and technical safeguards in place and relevant to the
                access, use, loss, alteration, disclosure, storage, destruction
                and control of information. Such policies and procedures will
                include encryption of data, virus detection and firewall
                utilization.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              COMPLIANCE DEMONSTRATION BY COMPANY
            </strong>
          </StyledLi>
          Company will make available to Customer all information in its
          disposal necessary to demonstrate compliance with the obligations
          under Privacy Laws.
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              DATA SUBJECT OR AUTHORITY REQUESTS
            </strong>
            <ol>
              <StyledLi>
                <strong>Data Subject Requests</strong>. Company will follow
                Customer’s instructions to accommodate Data Subjects’ Requests
                to exercise their rights in relation to their Personal Data
                processed as part of the Services, to the extent Customer, in
                its use of the Service, does not have the ability to do so. To
                the extent legally permitted, Company will notify Customer of
                any Data Subject Request it receives (if any) from Data Subjects
                regarding their Personal Data Processed by Company as part of
                the Services. Company shall notify Customer of the receipt of
                such request as soon as possible, and no later than five (5)
                business days from the receipt of such request, together with
                the relevant details. Company shall not respond to any such Data
                Subject Request without Customer’s prior written approval.
                Company shall provide Customer with assistance in relation to
                handling of a Data Subject Request, to the extent legally
                permitted and to the extent Customer does not have access to
                such Personal Data through its use of the Service. If legally
                permitted, Customer shall be responsible for any actual,
                reasonable costs arising from Company’s provision of such
                assistance.
              </StyledLi>
              <StyledLi>
                <strong>Authority Requests</strong>. Company shall promptly
                notify Customer of all enquiries from an Authority that Company
                receives which relate to the Processing of Customer’s Data as
                part of the provision to or receipt of the Service by Customer,
                unless prohibited from doing so by law or by the Authority.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>SUBPROCESSORS</strong>
            <ol>
              <StyledLi>
                <strong>Appointment of Subprocessors</strong>. Customer
                acknowledges and specifically authorizes Company’s use of its
                Subprocessors existing as of the Effective Date, as detailed in
                <strong>Attachment B</strong>, attached hereto. Customer hereby
                gives a general authorization to further Subprocessors, provided
                Company follows the following procedure:
                <ol>
                  <StyledLi>
                    Customer authorizes the Company to engage another Processor
                    for carrying out specific processing activities as part of
                    the Services, provided that the Company informs Customer at
                    least ten (10) business days in advance of any new or
                    substitute Processor, in which case Customer shall have the
                    right to object, on reasoned grounds, to that new or
                    replaced Processor. If Customer so objects, the Company may
                    not engage that new or substitute Processor for the purpose
                    of Processing Personal Data as part of the Services.
                  </StyledLi>
                  <StyledLi>
                    Without limiting the foregoing, in any event where the
                    Company engages another Processor, the Company will ensure
                    that substantially equivalent data protection obligations as
                    set out in this Addendum are imposed on that other Processor
                    by way of a contract, in particular providing sufficient
                    guarantees to implement appropriate technical and
                    organizational measures in such a manner that the processing
                    will meet the requirements of the Privacy Laws. Where the
                    other Processor fails to fulfil its data protection
                    obligations, the Company shall remain fully liable to
                    Customer for the performance of that other Processor’s
                    obligations.
                  </StyledLi>
                  <StyledLi>
                    Company and its other Processors will only Process the
                    Personal Data in member states of the European Economic
                    Area, in territories or territorial sectors recognized by an
                    adequacy decision of the European Commission, as providing
                    an adequate level of protection for Personal Data pursuant
                    to Article 45 of the GDPR or using adequate safeguards as
                    required under Data Protection Law governing cross-border
                    data transfers (e.g., EU SCC or UK Addendum, as applicable).
                  </StyledLi>
                </ol>
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>PERSONAL DATA BREACH.</strong>
          </StyledLi>
          <StyledP>
            Upon becoming aware of Personal Data Breach (as defined by Privacy
            Laws) related to Customer Data and that materially infringes Privacy
            Laws, Company shall without undue delay, and in any event within
            seventy-two (72) hours, notify Customer of such Personal Data
            Breach. Company shall investigate the breach and take all available
            measures to mitigate the breach and prevent its reoccurrence.
            Company will reasonably cooperate in good-faith with Customer on
            issuing any statements or notices regarding such breaches, to
            Authorities and Data Subjects. Notification of or response to a
            Personal Data Breach under this Section will not be construed as an
            acknowledgement by Company of any fault or liability with respect to
            the Personal Data Breach.
          </StyledP>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              DATA PROTECTION IMPACT ASSESSMENT.
            </strong>
          </StyledLi>
          <StyledP>
            Company will reasonably assist Customer with the eventual
            preparation of data privacy impact assessments and prior
            consultation as appropriate (and if needed). Customer shall be
            responsible for the actual, reasonable costs for Company’s provision
            of such assistance by Company.
          </StyledP>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px" }}>
              DELETION OR RETURN OF PERSONAL DATA.
            </strong>
          </StyledLi>
          <StyledP>
            Upon Customer’s request, Company will delete the Customer Data,
            including, Personal Data, Processed on Customer’s behalf for the
            provision of the Services under this Addendum and Customer
            Agreement, if stored from its own and its Processor’s systems, or,
            at Customer’s choice, return such Personal Data and delete existing
            copies if they exist in its own systems, within 30 business days of
            receiving a request to do so. Customer acknowledges and agrees that
            the Service shall automatically delete Customer Data sixty (60) days
            as of the termination of the Customer Agreement.
          </StyledP>
        </ol>
        <StyledTitle>Part 3 (CPRA)</StyledTitle>
        <ol>
          <StyledLi>
            This Part 3 applies if the CPRA (as defined below) applies to the
            Customer.
          </StyledLi>
          <StyledLi>
            Capitalized terms used in this Part 3 but not defined in this
            Addendum have the meaning ascribed to them in the California Privacy
            Rights Act (Cal. Civ. Code §1798.100 et seq., Cal. Civ. Code
            §1798.140 or the regulations at 11 C.C.R. §7000 et seq.,
            collectively, the “<strong>CPRA</strong>”).
          </StyledLi>
          <StyledLi>
            The parties acknowledge and agree that Corsound AI is a Service
            Provider. To that end, and unless otherwise required by law:
            <ol>
              <StyledLi>
                Corsound AI will process, retain, use, and disclose Personal
                Information on behalf of the Customer, only as necessary to
                provide the Service as specified in the Customer Agreement. The
                parties agree that Customer is disclosing the Customer’s Data to
                Corsound AI only for the purpose of properly performing the
                Service, support services, or for any commercial purpose other
                than as reasonably necessary to provide the Service, to comply
                with other reasonable and lawful instructions provided by
                Customer, for development of the Service via machine learning
                and AI development or as otherwise permitted under 11 CCR §7051I
                (the “<strong>Business Purpose</strong>”).
              </StyledLi>
              <StyledLi>
                Corsound AI shall not sell or share Customer‘s Personal
                Information; retain, use or disclose Customer’s Data for any
                commercial purpose outside of the direct business relationship
                between the parties, or for any purpose other than the Business
                Purposes, unless expressly permitted by the CPRA. Corsound AI
                certifies that it understands its obligations under the
                applicable Data Protection Law and will comply with them.
              </StyledLi>
              <StyledLi>
                Corsound AI is prohibited from combining the Customer’s Data
                with other Personal Information about the Customer, or on behalf
                of another person, or that it Collects from its own interaction
                with a Consumer, unless expressly permitted by the CPRA.
              </StyledLi>
              <StyledLi>
                If Corsound AI receives a request from a California Consumer of
                the Customer, about his or her Personal Information, Corsound AI
                shall not comply with the request itself, but shall inform the
                Consumer that Corsound AI’s basis for denying the request is
                that Corsound AI is merely a Service Provider that follows
                Customer’s instruction, and inform the Consumer that they should
                submit the request directly to the Customer and provide the
                Consumer with the Customer’s contact information.
              </StyledLi>
              <StyledLi>
                Commensurate with the nature of Corsound AI’s services to
                Customer and in accordance with Customer’s specified
                instructions to Corsound AI, Corsound AI shall help Customer to
                comply with California Consumers requests made pursuant to the
                CPRA of which Corsound AI is informed of by Customer.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi>
            At Customer’s direction, Corsound AI shall delete or return to
            Customer the Personal Information it has Processed on Customer’s
            behalf from its own and its service provider’s systems, shortly
            after it completes the requested Service, and upon Customer’s
            request, will furnish written confirmation that the Personal
            Information has been deleted pursuant to this Section, unless
            retention of the Personal Information is required by law.
          </StyledLi>
          <StyledLi>
            Corsound AI shall comply with all applicable sections of the CPRA
            and shall provide, with respect to the Personal Information it
            Collects pursuant to the Customer Agreement, the same level of
            privacy protection as required of Businesses by the CPRA, and as
            follows:
            <ol>
              <StyledLi>
                Corsound AI shall cooperate with Client in responding to and
                complying with Consumers’ requests made pursuant to the CPRA,
                such as assisting Customer by appropriate technical and
                organizational measures, insofar as this is possible, for the
                fulfilment of Customer’s obligation to respond to requests for
                exercising Consumer rights under the CPRA.
              </StyledLi>
              <StyledLi>
                Taking into account the state of the art, the costs of
                implementation and the nature, scope, context and purposes of
                Corsound AI’s processing of Personal Information of the
                Customer, as well as the nature of personal information
                processed for Customer, Corsound AI shall implement and maintain
                reasonable security procedures and practices appropriate to the
                nature of the Personal Information, to protect the Personal
                Information from unauthorized access, destruction, use,
                modification, or disclosure (including data breaches), in
                accordance with Cal. Civ. Code §1798.81.5, and commensurate with
                the 18 Critical Security Controls published by the Center for
                Internet Security (CIS).
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi>
            Corsound AI grants Customer the right to take reasonable and
            appropriate steps to ensure that Corsound AI uses the Customer’s
            Data in a manner consistent with Customer’s obligations under the
            CPRA. Customer may, in coordination with Corsound AI, monitor
            Corsound AI’s compliance with the Customer Agreement through
            measures, including, but not limited to ongoing manual reviews and
            automated scans of Corsound AI’s system, at least once every 12
            months. Corsound AI shall perform regular internal or third-party
            assessments, audits, or other technical and operational testing of
            its security procedures and practices at least once every 12 months.
            Upon the reasonable request of Customer, Corsound AI shall make
            available to Customer all information in its possession necessary to
            demonstrate Corsound AI’s compliance with the obligations in this
            clause.
          </StyledLi>
          <StyledLi>
            Corsound AI shall promptly notify Customer once it makes a
            determination that it can no longer meet its obligations under the
            CPRA.
          </StyledLi>
          <StyledLi>
            Corsound AI grants Customer the right, upon notice, including under
            Section 5, to take reasonable and appropriate steps to stop and
            remediate Corsound AI’s unauthorized use of Customer’s Data.
          </StyledLi>
          <StyledLi>
            Corsound AI shall ensure that each person involved in Processing the
            Customer’s Data it collects pursuant to the Customer Agreement is
            subject to a contractual or statutory duty of confidentiality with
            respect to that Customer’s Data.
          </StyledLi>
        </ol>
        <StyledTitle>Part 4 (Israeli law)</StyledTitle>
        <ol>
          <StyledLi>
            <strong style={{ textDecoration: "underline" }}>Definitions</strong>
            . In this Part, the following terms shall be interpreted as follows:
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                The “<strong>Applicable Law</strong>” – shall mean the Israeli
                Protection of Privacy Law, 5741-1981 (hereinafter – the “
                <strong>Privacy Law</strong>”) and the regulations promulgated
                thereunder (and in particular the Protection of Privacy
                Regulations (Information Security), 5777 - 2017), the guidelines
                of the Registrar of Databases, and in particular Guidelines No.
                2/2011 regarding the use of outsourcing for processing of
                personal data, as well as any legislative or administrative
                provision or directive that will apply to the Company in
                connection with Processing Personal Dat“.
              </StyledLi>
              <StyledLi>
                "<strong>Database</strong>" - a collection of Personal Data held
                by physical, magnetic or optical means.
              </StyledLi>
              <StyledLi>
                “<strong>Personal Data</strong>” means Customer Data that
                relates to an individual, and which is Processed by Company in
                the course of Service.
              </StyledLi>
              <StyledLi>
                "<strong>Processing</strong>" (and its derivatives, including,
                but not limited “o "<strong>Processor</strong>") – the
                collection, access, retention, modification, use, disclosure and
                transfer of Personal Data.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              General Provisions
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Customer is the sole owner of the Databases containing the
                Personal Data, and nothing contained in this Part shall be
                deemed to constitute the grant of proprietary rights to the
                Company in the Personal Data.
              </StyledLi>
              <StyledLi>
                Customer may instruct the Company regarding the manner in which
                the Personal Data should be Processed, and the Company
                undertakes to comply with all of Customer's instructions, as
                shall be determined from time to time, provided that if the
                instructions entail new costs to the Company, their performance
                is subject to additional payment as shall be agreed upon by the
                parties.
              </StyledLi>
              <StyledLi>
                Each Party must maintain an up-to-date listing of all authorized
                individuals of the Database and prevent access to any individual
                who does not have the need to be exposed to the Personal Data.
              </StyledLi>
              <StyledLi>
                Each Party shall grant its employees with access to the
                Database, subject to conducting training activities regarding
                privacy protection and information security obligations
                applicable to the Customer by virtue of the Applicable Law
                and/or this Part 4. Such training shall take place at least once
                every two years and as soon as possible after recruiting.
              </StyledLi>
              <StyledLi>
                Each Party shall implement security and monitoring measures
                through which each Party shall record each access made to the
                Database Systems (as defined below).
              </StyledLi>
              <StyledLi>
                Each Party shall develop, implement and enforce an information
                security policy that shall include at least the following issues
                ("<strong>Information Security Policy</strong>"):
                <ol>
                  <StyledLi>
                    Mapping of all the of the security measures taken by the
                    each Party regarding the Database Systems;
                  </StyledLi>
                  <StyledLi>
                    Instructions regarding the manner in which access to the
                    Database is managed and the means of controlling access to
                    Personal Data and the actions taken in it.
                  </StyledLi>
                  <StyledLi>
                    Guidelines for individuals authorized to access Personal
                    Data and Database Systems;
                  </StyledLi>
                  <StyledLi>
                    A review of the risks to which the Personal Data is exposed
                    to as part of the each Party's ongoing activities;
                  </StyledLi>
                  <StyledLi>
                    Instructions regarding the means of recording, monitoring
                    and identifying threats to which the Database systems are
                    exposed, and events in which there is a risk of Breach of
                    Information Security;
                  </StyledLi>
                  <StyledLi>
                    Instructions regarding periodic audit reports as stated in
                    Section Error! Reference source not found. below;
                  </StyledLi>
                  <StyledLi>
                    Instructions and procedures regarding periodic backup and
                    restore of the audit data as stated;
                  </StyledLi>
                  <StyledLi>
                    Instruction regarding the manner in which development
                    activities in the Database are performed and documented.
                  </StyledLi>
                </ol>
              </StyledLi>
            </ol>
            <StyledLi>
              Each Party shall map the operational environment of the Database.
              In this regard, each Party shall prepare an inventory list that
              includes all the data systems, software, interfaces,
              infrastructures of hardware components and communications
              components that the Company operates in the Database environment
              for the ongoing operation of the Database (the “
              <strong>Database Systems</strong>"). Each Party shall update the
              list of inventories specified in this Section from time to time
              and shall only disclose the document to those individuals who
              require access to it for the performance of their job functions.
              Each Party shall update the aforesaid list in any case in which
              substantial changes to the operating environment are performed on
              the Database Systems or in the manner in which data is being
              Processed.
            </StyledLi>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              Company’s obligations regarding the processing of Personal Data
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Company shall process the Personal Data for Customer solely in
                accordance with Customer’s instructions, and only in the manner
                determined in this Part 4, for the development of the Service
                via machine learning and AI development, and for no other
                purpose, unless expressly instructed by Customer to do so.
              </StyledLi>
              <StyledLi>
                Company undertakes to manage access rights to Personal Data,
                including providing its users with ‘Least Privileges’ based on
                their ‘Need to Know’, for the purpose of carrying out their
                tasks, and shall take measures in order prevent access by
                unauthorized individuals to Personal Data. In addition, Company
                must maintain an up-to-date listing of all authorized
                individuals of the Database and prevent access to any individual
                who does not have the need to be exposed to the Personal Data.
              </StyledLi>
              <StyledLi>
                Company shall not grant access to the Personal Data to its
                employees, consultants or anyone acting on its behalf, before:
                (a) reviewing and confirming that their background and personal
                integrity and reliability are suitable for a position granting
                them access to Personal Data; and (b) binding them to a letter
                of undertaking in order to maintain the confidentiality,
                security of information and privacy of the data subjects whose
                details are included in the Database. Company shall be liable to
                Customer for any act and/or omission of itself or any of its
                employees, advisors, Sub-contractors (as defined below) and
                anyone else acting on its behalf in connection with the breach
                of the provisions of this Part 4.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              Disclosure and transfer of Personal Data
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Company shall not disclose any Personal Data that the Company
                processed for Customer to any person or entity without
                Customer’s prior written consent, except to the extent required
                for the performance of Customer’s instructions in accordance
                with this Part 4.
              </StyledLi>
              <StyledLi>
                If Company desires to disclose Personal Data to a subcontractor
                of the Company or use a subcontractor to Process Personal Data
                (each, a "<strong>Sub-contractor</strong>"), then prior to such
                disclosure, the Company shall enter into a written, valid and
                enforceable agreement with the Sub-Contractor containing
                substantially adequate protective terms on data security.
                Company shall provide Customer any information reasonable
                requested by Customer about Company’s use of Sub-contractors,
                about Sub-contractors’ Processing activities for Company and
                their data security practices.
              </StyledLi>
              <StyledLi>
                Company shall use accepted encryption mechanisms for each
                transfer of Personal Data to a third party and for any remote
                access to the Database Systems.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              Retention and return of Personal
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Each Party declares and undertakes that it shall take
                appropriate information security measures, when applicable, in
                order to ensure the integrity, availability, confidentiality and
                reliability of the Personal Data.
              </StyledLi>
              <StyledLi>
                Each Party shall maintain logical separation between the
                Database Systems and the computer systems used by the each
                Party, which are not directly related to the Personal Data from
                the Service.. In the event of connection of the Database Systems
                to the Internet or to another public network, the each Party
                shall implement appropriate safeguards against information
                security issues.
              </StyledLi>
              <StyledLi>
                Each Party shall regularly update the Database Systems,
                including the software, which is installed in the Database
                Systems, with information security updates. In operating the
                Database Systems, each Party shall not use any software or
                hardware components whose manufacturer does not support their
                security aspects.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              Audit, documentation and monitoring
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Each Party undertakes to document by an automated mechanism the
                activity carried out in the Database Systems, including (but not
                limited to) documentation of attempts to access the Database
                Systems, deletion and/or change of Personal Data and change of
                access rights to the Database Systems (“
                <strong>Audit Mechanism</strong>"). The Audit Mechanism shall
                collect at least the following data: the user identity, the date
                and time of the activity, the source of the activity (Internet
                address or computer name), the component of the system in which
                the activity was performed, the type of activity, and whether or
                not the activity was successful.
              </StyledLi>
              <StyledLi>
                The audit data to be generated by the Audit Mechanism shall be
                maintained for 24 months. 6.3. Each Party undertakes to backup
                all data generated by the Audit Mechanisms. 6.4. Each Party
                undertakes to conduct at least once in 12 months, an internal or
                external audit by an entity or a person with appropriate
                certification for auditing information security, and who is not
                the Party's CISO, in order to ascertain the Party's compliance
                with these provisions and the provisions of the Applicable Law.
              </StyledLi>
            </ol>
          </StyledLi>
          <StyledLi style={{ marginTop: "10px" }}>
            <strong style={{ marginTop: "10px", textDecoration: "underline" }}>
              Transfer of Personal Data to foreign jurisdiction
            </strong>
            <ol>
              <StyledLi style={{ marginTop: "10px" }}>
                Company shall comply with the law applicable to the transfer of
                Personal Data to foreign jurisdictions, including but not
                limited to the Protection of Privacy Regulations (Transfer of
                Information to Databases Outside of Israel), 5761-2001.
              </StyledLi>
            </ol>
          </StyledLi>
        </ol>
        <br />
        <StyledTitle style={{ justifyContent: "start" }}>
          8. General cooperation
        </StyledTitle>
        <StyledP>
          Company shall cooperate with Customer and Customer’s client in
          providing information and assistance reasonably requested by Customer
          in connection with data security issues and practices and
          supplementary documents, so as to allow Customer to properly address
          information security, privacy and regulatory matters relating to the
          Database.
        </StyledP>
        <StyledTitle style={{ textDecoration: "none" }}>
          Attachment A - DATA PROCESSING
        </StyledTitle>
        <StyledTable>
          <tr>
            <td>
              Categories of data subjects whose personal data may be processed
            </td>
            <td>
              <ul>
                <StyledLi>
                  The Customer and its users, such as employees, agents and
                  anyone on Customer’s behalf who is authorized to use the
                  Service;
                </StyledLi>
                <StyledLi>
                  Customer data subjects who may appear in the Output Data
                </StyledLi>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Categories of personal data Processed</td>
            <td>
              <ul>
                <StyledLi>
                  Full name, ID number, email address, and usernames of Customer
                  and Customer’s users, employees, agents and anyone on
                  Customer’s behalf who is authorized to use the Service
                </StyledLi>
                <StyledLi>
                  content uploaded, provided or imported by Customer and its
                  users to the Service and that is being processed through the
                  Service, including Personal Data in videos, gifs, pictures,
                  audio voiceprints etc. of the Customer data subjects.
                </StyledLi>
                nalytics information, such as IP address from which Customer
                Users access the Service, time and date of access, type of
                device and browser used, language used, links clicked via a
                mouse or a touch screen, and actions taken while using the
                Service, in accordance with the users’ preferences.
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              The frequency of the Processing (e.g. whether the data is
              processed and on what basis).
            </td>
            <td>while providing the Services to the Customer</td>
          </tr>
          <tr>
            <td>Nature of the processing</td>
            <td>
              Company processes personal data to provide the Service as
              specified under the Customer Agreement. The nature of the
              processing is mainly to provide the Service, technical support for
              technical questions, problems and inquiries regarding the Service
              as agreed under the Order Form, uploading data to the Service,
              storage on the Service (where applicable), analytics reporting,
              the development of the Service via machine learning and AI
              development.
            </td>
          </tr>
          <tr>
            <td>Purpose(s) of the data Processing and further processing</td>
            <td>
              Personal Data is contained in the data which Customer and its
              Users share through Service under the Customer Agreement. Company
              has access to such data solely for purposes pursuant to the
              Customer Agreement and relevant Order Forms, and for the purpose
              of machine learning and AI development.
            </td>
          </tr>
          <tr>
            <td>
              The period for which the personal data will be retained, or, if
              that is not possible, the criteria used to determine that period
            </td>
            <td>
              During the provision of the Services requested by Customer, within
              the Term
            </td>
          </tr>
          <tr>
            <td>For transfers to (sub-) processors, also specify</td>
            <td>As detailed in Attachment B for the Services.</td>
          </tr>
          <tr>
            <td>
              location, subject matter, nature and duration of the processing
            </td>
            <td></td>
          </tr>
        </StyledTable>
        <StyledTitle>Attachment B – List of Sub-processors</StyledTitle>
        <StyledSilverTable>
          <tr>
            <th style={{ width: "33%", textAlign: "center", border: "none" }}>
              Sub-processor’ Name
            </th>
            <th style={{ width: "33%", textAlign: "center", border: "none" }}>
              Purpose of Processing
            </th>
            <th style={{ width: "33%", textAlign: "center", border: "none" }}>
              Location
            </th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>AWS</td>
            <td>Hosting Service</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </StyledSilverTable>
      </p>
    </Content>
  );
};

export default PdaContent;
