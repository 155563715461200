import React, { FC, useState } from "react";

import InputStyled from "../../../Input/Input";
import ModalStyled from "../../../Modal";

import { ModalContent } from "./EditListModal.styled";

type TableStyledProps = {
  title: string;
  children: JSX.Element;
  handleSave: (name: string) => void;
  initialData: string;
  width?: number;
};
const EditListModal: FC<TableStyledProps> = ({
  children,
  title,
  handleSave,
  width,
  initialData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [listName, setListName] = useState(initialData);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value: string) => {
    setListName(value);
  };

  const submit = () => {
    handleSave(listName);
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        width={width}
        title={title}
        isModalOpen={isModalOpen}
        handleSave={() => submit()}
        handleClose={handleClose}
        modalOpen={() => console.log()}
      >
        <ModalContent>
          <InputStyled
            title="List name: "
            value={listName}
            onChange={handleChange}
          />
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default EditListModal;
