import { AxiosInstance } from "axios";

import { barEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type PieType = {
  categories: string[];
  data: PieData[];
};

export type PieData = {
  name: string;
  value: number;
};
class Pie {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  getVerify = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${barEndpoints.verify}${queries}`);
  };

  getGender = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${barEndpoints.gender}${queries}`);
  };

  getAge = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${barEndpoints.age}${queries}`);
  };

  getEthnicity = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${barEndpoints.ethnicity}${queries}`);
  };
}
export const barApi = new Pie(axios);
