import { Checkbox, Collapse } from "antd";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useQuery } from "react-query";

import { userApi, UserProps } from "../../../../api/UserApi/User";
import { Colors } from "../../../../utils/colors";
import {
  AgeStart,
  Duration,
  Ethnicity,
} from "../../../../utils/constants/filters";
import { QueryNames } from "../../../../utils/constants/QueryNames";
import CollapseStyled from "../../../Callapse";
import InputStyled from "../../../Input/Input";
import SearchInput from "../../../Input/SearchInput";
import ModalStyled from "../../../Modal";
import { GraphicalFilterType } from "../../GraphicalReports/GraphicalReportsScreen";
import { SettingContent } from "../../Settings/Settings.styled";
import { FilterType } from "../ListReportsScreen";

import {
  AgeWrapper,
  DatePickerContainer,
  DatePickerWrapper,
  ModalContent,
  PanelTitle,
  SearchWrapper,
  SelectItem,
  SelectStyled,
} from "./AccountModal.styled";
import DateInput from "./DateInput/DateInput";
import DateInputTo from "./DateInput/DateInputTo";

const { Panel } = Collapse;
type TableStyledProps = {
  title: string;
  children: JSX.Element;
  width?: number;
  filter?: Dispatch<SetStateAction<FilterType | GraphicalFilterType>>;
  show: number[];
};

const panelStyle = {
  padding: 0,
  marginBottom: 16,
  background: Colors.WHITE_COLOR,
  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
};

const AccountModal: FC<TableStyledProps> = ({
  children,
  title,
  width,
  filter,
  show,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePanel, setActivePanel] = useState("1");
  const [placementGender, setPlacementGender] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState("");
  const [callDuration, setCallDuration] = useState<string[]>([]);
  const [operatorsIds, setOperatorsIds] = useState<string[]>([]);
  const [age, setAge] = useState<string[]>([]);
  const [ethnicity, setEthnicity] = useState<string[]>([]);
  const [verification, setVerification] = useState<string>("");
  const [verificationFraud, setVerificationFraud] = useState<string>("");
  const [isCustomAge, setIsCustomAge] = useState({
    custom: false,
    minAge: "",
    maxAge: "",
  });

  const {
    data: { operators },
  } = useQuery({
    queryKey: [QueryNames.GET_USER],
    queryFn: () => userApi.get("?offset=0&sort=operator_id&dir=asc"),
    initialData: {
      operators: [],
    },
  });

  const placementChange = (value: string) => {
    setVerification(value);
  };

  const fraudChange = (value: string) => {
    setVerificationFraud(value);
  };

  const onChangeDuration = (value: string) => {
    const list = [...callDuration];
    if (list.includes(value)) {
      setCallDuration(list.filter((tag) => tag !== value));
    } else {
      list.push(value);
      setCallDuration(list);
    }
  };

  const onChangeOperatorIds = (operatorId: string) => {
    const list = [...operatorsIds];
    if (list.includes(operatorId)) {
      setOperatorsIds(list.filter((tag) => tag !== operatorId));
    } else {
      list.push(operatorId);
      setOperatorsIds(list);
    }
  };

  const onChangeEthnicity = (value: string) => {
    const list = [...ethnicity];
    if (list.includes(value)) {
      setEthnicity(list.filter((tag) => tag !== value));
    } else {
      list.push(value);
      setEthnicity(list);
    }
  };

  const onChangeAge = (value: string) => {
    let list = [...age];
    if (list.includes(value)) {
      setAge(list.filter((tag) => tag !== value));
    } else {
      if (list.includes(AgeStart.SIXTY_FIVE)) {
        const newList = list.filter((age) => age !== AgeStart.SIXTY_FIVE);
        newList.push(value);
        newList.push(AgeStart.SIXTY_FIVE);
        list = [...newList];
      } else {
        list.push(value);
      }
      setAge(list);
    }
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const placementGenderChange = (value: string) => {
    setPlacementGender(value);
  };

  const handleSave = () => {
    let durationFiltered: number[] = [];
    callDuration.map((el: string) => {
      durationFiltered = [
        ...durationFiltered,
        ...el.split(",").map((item) => +item),
      ];
      return el;
    });

    let ageFiltered: number[] = [];
    age.map((el: string) => {
      ageFiltered = [...ageFiltered, ...el.split(",").map((item) => +item)];
      return el;
    });

    if (isCustomAge.custom) {
      ageFiltered.push(+isCustomAge.minAge);
      ageFiltered.push(+isCustomAge.maxAge);
    }

    filter?.((prev) => ({
      ...prev,
      offset: 0,
      dt_start: moment(startDate).format("YYYY[-]MM[-]DD"),
      dt_end:
        moment(endDate).format("YYYY[-]MM[-]DD") ===
        moment(startDate).format("YYYY[-]MM[-]DD")
          ? ""
          : moment(endDate).format("YYYY[-]MM[-]DD"),
      search: searchValue,
      verify: verification !== "" ? verification === "verified" : null,
      fraud: verificationFraud !== "" ? verificationFraud === "fraud" : null,
      gender: placementGender || null,
      dur: durationFiltered,
      age: ageFiltered,
      operator: operatorsIds,
      ethnicity: ethnicity,
      export: null,
    }));
  };
  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        width={width}
        handleSave={handleSave}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <SearchWrapper>
            <SearchInput
              icon={<></>}
              width={"100%"}
              onChange={(value: string) => setSearchValue(value)}
            />
          </SearchWrapper>
          <CollapseStyled
            defaultActiveKey={activePanel}
            onChange={setActivePanel}
          >
            <>
              {show.includes(1) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "1"}>
                      Call date
                    </PanelTitle>
                  }
                  key="1"
                  style={panelStyle}
                >
                  <DatePickerContainer>
                    <div style={{ width: "max-content" }}>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                    </div>
                    <DatePickerWrapper>
                      <label>
                        <DatePicker
                          customInput={
                            <DateInput
                              onChange={onChange}
                              title="From"
                              value={startDate}
                              id={"2"}
                            />
                          }
                          dateFormat="MMMM d, yyyy"
                          wrapperClassName="datePicker"
                          selected={startDate}
                          onChange={(date: Date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </label>
                      <label>
                        <DatePicker
                          customInput={
                            <DateInputTo
                              onChange={onChange}
                              value={endDate}
                              id={"1"}
                            />
                          }
                          dateFormat="MMMM d, yyyy"
                          wrapperClassName="datePicker"
                          selected={endDate}
                          onChange={(date: Date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </label>
                    </DatePickerWrapper>
                  </DatePickerContainer>
                </Panel>
              )}
              {show.includes(8) && operators && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "8"}>
                      Filter by Agent
                    </PanelTitle>
                  }
                  key="8"
                  style={panelStyle}
                >
                  {operators.map(
                    (
                      { name, operator_id, call_dt_create }: UserProps,
                      index: number
                    ) => {
                      return (
                        <div key={`${call_dt_create} ${index}`}>
                          <Checkbox
                            checked={operatorsIds.includes(operator_id)}
                            onChange={() => onChangeOperatorIds(operator_id)}
                          >
                            <SettingContent>{name}</SettingContent>
                          </Checkbox>
                        </div>
                      );
                    }
                  )}
                </Panel>
              )}
              {show.includes(2) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "2"}>
                      Call duration
                    </PanelTitle>
                  }
                  key="2"
                  style={panelStyle}
                >
                  <div>
                    <Checkbox
                      checked={callDuration.includes(Duration.LESS_FOR)}
                      onChange={() => onChangeDuration(Duration.LESS_FOR)}
                    >
                      <SettingContent>Less than 5 minutes</SettingContent>
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={callDuration.includes(Duration.FIVE)}
                      onChange={() => onChangeDuration(Duration.FIVE)}
                    >
                      <SettingContent>5-10 minutes</SettingContent>
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={callDuration.includes(Duration.ELEVEN)}
                      onChange={() => onChangeDuration(Duration.ELEVEN)}
                    >
                      <SettingContent>11-19 minutes</SettingContent>
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={callDuration.includes(Duration.MORE)}
                      onChange={() => onChangeDuration(Duration.MORE)}
                    >
                      <SettingContent>More then 20 minutes</SettingContent>
                    </Checkbox>
                  </div>
                </Panel>
              )}
              {show.includes(4) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "3"}>
                      Verification result
                    </PanelTitle>
                  }
                  key="3"
                  style={panelStyle}
                >
                  <SelectStyled>
                    <SelectItem
                      width={"50%"}
                      active={verification === "verified"}
                      onClick={() => placementChange("verified")}
                    >
                      Verified
                    </SelectItem>
                    <SelectItem
                      width={"50%"}
                      active={verification === "not_verified"}
                      onClick={() => placementChange("not_verified")}
                    >
                      Not verified
                    </SelectItem>
                  </SelectStyled>
                </Panel>
              )}
              {show.includes(7) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "7"}>
                      Fraud result
                    </PanelTitle>
                  }
                  key="7"
                  style={panelStyle}
                >
                  <SelectStyled>
                    <SelectItem
                      width={"50%"}
                      active={verificationFraud === "fraud"}
                      onClick={() => fraudChange("fraud")}
                    >
                      Fraud
                    </SelectItem>
                    <SelectItem
                      width={"50%"}
                      active={verificationFraud === "not_fraud"}
                      onClick={() => fraudChange("not_fraud")}
                    >
                      Not fraud
                    </SelectItem>
                  </SelectStyled>
                </Panel>
              )}
              {show.includes(4) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "4"}>Age</PanelTitle>
                  }
                  key="4"
                  style={panelStyle}
                >
                  <AgeWrapper>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.EIGHTEEN)}
                        onChange={() => onChangeAge(AgeStart.EIGHTEEN)}
                      >
                        <SettingContent>18-24</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.TWENTY_FIVE)}
                        onChange={() => onChangeAge(AgeStart.TWENTY_FIVE)}
                      >
                        <SettingContent>24-35</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.THIRTY_FIVE)}
                        onChange={() => onChangeAge(AgeStart.THIRTY_FIVE)}
                      >
                        <SettingContent>35-44</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.FORTY_FIVE)}
                        onChange={() => onChangeAge(AgeStart.FORTY_FIVE)}
                      >
                        <SettingContent>44-54</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.FIFTY_FIVE)}
                        onChange={() => onChangeAge(AgeStart.FIFTY_FIVE)}
                      >
                        <SettingContent>54-64</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={age.includes(AgeStart.SIXTY_FIVE)}
                        onChange={() => onChangeAge(AgeStart.SIXTY_FIVE)}
                      >
                        <SettingContent>65 and older</SettingContent>
                      </Checkbox>
                    </div>
                  </AgeWrapper>
                  <Checkbox
                    checked={isCustomAge.custom}
                    onChange={() =>
                      setIsCustomAge((prev) => ({
                        ...prev,
                        custom: !prev.custom,
                      }))
                    }
                  >
                    <SettingContent>Enter your age</SettingContent>
                  </Checkbox>
                  <InputStyled
                    disabled={!isCustomAge.custom}
                    type="number"
                    placeholder="Max age..."
                    value={isCustomAge.maxAge}
                    onChange={(maxAge) =>
                      setIsCustomAge((prev) => ({ ...prev, maxAge }))
                    }
                  />
                  <InputStyled
                    disabled={!isCustomAge.custom}
                    type="number"
                    placeholder="Min age..."
                    value={isCustomAge.minAge}
                    onChange={(minAge) =>
                      setIsCustomAge((prev) => ({ ...prev, minAge }))
                    }
                  />
                </Panel>
              )}
              {show.includes(5) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "5"}>Gender</PanelTitle>
                  }
                  key="5"
                  style={panelStyle}
                >
                  <SelectStyled>
                    <SelectItem
                      active={placementGender === "male"}
                      onClick={() => placementGenderChange("male")}
                    >
                      Male
                    </SelectItem>
                    <SelectItem
                      active={placementGender === "female"}
                      onClick={() => placementGenderChange("female")}
                    >
                      Female
                    </SelectItem>
                    <SelectItem
                      active={placementGender === "unknown"}
                      onClick={() => placementGenderChange("unknown")}
                    >
                      Unknown
                    </SelectItem>
                  </SelectStyled>
                </Panel>
              )}
              {show.includes(6) && (
                <Panel
                  header={
                    <PanelTitle active={activePanel === "6"}>
                      Ethnicity
                    </PanelTitle>
                  }
                  key="6"
                  style={panelStyle}
                >
                  <AgeWrapper>
                    {/*<div>*/}
                    {/*  <Checkbox*/}
                    {/*    checked={ethnicity.includes(Ethnicity.WHITE)}*/}
                    {/*    onChange={() => onChangeEthnicity(Ethnicity.WHITE)}*/}
                    {/*  >*/}
                    {/*    <SettingContent>White</SettingContent>*/}
                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  <Checkbox*/}
                    {/*    checked={ethnicity.includes(Ethnicity.BLACK)}*/}
                    {/*    onChange={() => onChangeEthnicity(Ethnicity.BLACK)}*/}
                    {/*  >*/}
                    {/*    <SettingContent>Black</SettingContent>*/}
                    {/*  </Checkbox>*/}
                    {/*</div>*/}
                    <div>
                      <Checkbox
                        checked={ethnicity.includes(Ethnicity.CAUCASIAN_LATIN)}
                        onChange={() =>
                          onChangeEthnicity(Ethnicity.CAUCASIAN_LATIN)
                        }
                      >
                        <SettingContent>Caucasian Latin</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={ethnicity.includes(Ethnicity.AFRICAN_AMERICAN)}
                        onChange={() =>
                          onChangeEthnicity(Ethnicity.AFRICAN_AMERICAN)
                        }
                      >
                        <SettingContent>African American</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={ethnicity.includes(Ethnicity.ASIAN_INDIAN)}
                        onChange={() =>
                          onChangeEthnicity(Ethnicity.ASIAN_INDIAN)
                        }
                      >
                        <SettingContent>Asian Indian</SettingContent>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={ethnicity.includes(Ethnicity.EAST_ASIAN)}
                        onChange={() => onChangeEthnicity(Ethnicity.EAST_ASIAN)}
                      >
                        <SettingContent>East Asian</SettingContent>
                      </Checkbox>
                    </div>
                  </AgeWrapper>
                </Panel>
              )}
            </>
          </CollapseStyled>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default AccountModal;
