import { FC } from "react";

export const Arrow: FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L5.34518 5.83333L9.75592 10.2441C10.0814 10.5695 10.0814 11.0972 9.75592 11.4226C9.43048 11.748 8.90285 11.748 8.57741 11.4226L3.57741 6.42259C3.25197 6.09715 3.25197 5.56951 3.57741 5.24408L8.57741 0.244078C8.90285 -0.0813593 9.43049 -0.0813593 9.75592 0.244078Z"
      fill="#BDBDBD"
      fillOpacity="0.3"
    />
  </svg>
);
