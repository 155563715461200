import { AxiosInstance, AxiosRequestConfig } from "axios";

import { listEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type ManageListProps = {
  org_list_id: "string";
  org_list_dt_create: "2023-05-16T18:11:17.365Z";
  org_list_name: "string";
  total_clients: 0;
  clients: string[];
};

class ManageList {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  get = async (queries: string, config?: AxiosRequestConfig): Promise<any> => {
    return this.requestInstance.get(`${listEndpoints.list}${queries}`, {
      ...config,
    });
  };

  create = async (newList: { file: FormData; name: string }): Promise<any> => {
    return this.requestInstance.post(
      `${listEndpoints.list}`,
      {
        name: newList.name,
        file: newList.file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  update = async (listUpdate: { name: string; id: string }): Promise<any> => {
    return this.requestInstance.post(`${listEndpoints.list}/${listUpdate.id}`, {
      org_list_name: listUpdate.name,
    });
  };

  getById = async (id: string): Promise<any> => {
    return this.requestInstance.get(`${listEndpoints.list}/${id}`);
  };

  deleteById = async (id: string): Promise<any> => {
    return this.requestInstance.delete(`${listEndpoints.list}/${id}`);
  };
}

export const listApi = new ManageList(axios);
