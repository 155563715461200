import styled from "styled-components";

export const IconActionWrapper = styled.div`
  display: flex;
  opacity: 0.8;
  border: 1px solid #1e1e1e;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: 28px;
  &:hover {
    cursor: pointer;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
