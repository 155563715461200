import { FC } from "react";

export const Company: FC = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 14H15M3 2V14M10 2V14M14 5V14M5 4.5H5.5M5 6.5H5.5M5 8.5H5.5M7.5 4.5H8M7.5 6.5H8M7.5 8.5H8M5 14V11.75C5 11.336 5.336 11 5.75 11H7.25C7.664 11 8 11.336 8 11.75V14M2.5 2H10.5M10 5H14.5M12 7.5H12.0053V7.50533H12V7.5ZM12 9.5H12.0053V9.50533H12V9.5ZM12 11.5H12.0053V11.5053H12V11.5Z"
      stroke="#858691"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
