import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const SearchStyled = styled.div<{ width?: string }>`
  width: 100%;
  padding-right: 10px;
  & .ant-input-affix-wrapper {
    border-radius: 4px;
    border: none;
    padding: 6px 16px;
    width: 100%;
    max-width: ${({ width }) => (width ? `${width}` : "365px")};
  }

  span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${Colors.TITLE_SILVER_COLOR};
  }
`;

export const StyledSearchIcon = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;
