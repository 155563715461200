import { Select } from "antd";
import React, { FC } from "react";

import "./index.css";
import { MenuArrow } from "../../assets";

import { DropdownStyledWrapper, Title } from "./Dropdown.styled";

type ButtonStyledProps = {
  options: { value: string; label: string | JSX.Element }[];
  defaultValue?: string;
  bordered?: boolean;
  background?: string;
  color?: string;
  title?: string;
  fontWeight?: number;
  fontSize?: number;
  onChange?: (value: string) => void;
  right?: string;
  borderRadius?: string;
  borderColor?: string;
  height?: string;
  open?: boolean;
  width?: string;
  padding?: string;
  style?: React.CSSProperties;
};
const DropdownStyled: FC<ButtonStyledProps> = ({
  onChange,
  title,
  options,
  defaultValue,
  background,
  color,
  bordered,
  fontWeight,
  fontSize,
  right,
  borderRadius,
  borderColor,
  height,
  open,
  padding,
  width = "100%",
  style,
}) => {
  return (
    <DropdownStyledWrapper
      padding={padding}
      height={height}
      borderColor={borderColor}
      borderRadius={borderRadius}
      right={right}
      style={style}
      background={background}
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      {title && <Title>{title}</Title>}
      <Select
        open={open}
        value={defaultValue}
        bordered={bordered}
        suffixIcon={<MenuArrow fill={color} />}
        options={options}
        onChange={onChange}
        style={{ width: width }}
      />
    </DropdownStyledWrapper>
  );
};

export default DropdownStyled;
