import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const Updated = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: 0.005em;

  color: ${Colors.BLACK_COLOR};
`;

export const ContentWrapper = styled.div`
  background: ${Colors.WHITE_COLOR};
  border-radius: 10px;
  padding: 24px;
  margin: 24px;
  color: ${Colors.BLACK_COLOR};
  height: calc(100vh - 124px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    background: ${Colors.WHITE_COLOR};
    color: ${Colors.WHITE_COLOR};
  }

  &::-webkit-scrollbar-track {
    background-color: ${Colors.WHITE_COLOR};
    color: ${Colors.WHITE_COLOR};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #d9f0e3;
  }
`;

export const Content = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.005em;
  color: ${Colors.TITLE_MUTED};
`;

export const StyledA = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0 0 0;
  width: 100%;
  font-weight: 700;
  color: ${Colors.TITLE_INNER_COLOR};
`;
