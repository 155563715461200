import { Empty } from "antd";
import React, { FC } from "react";
import {
  Bar,
  Legend,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import { BarCategories } from "../../../../api/BarApi/Bar";
import {
  BarChartWrapper,
  ChartLabel,
  TableBlock,
  TableContainer,
  TableHead,
} from "../GraphicalReports.styled";

type PieChartDonutProps = {
  bar: any;
  colors: string[];
  label: string;
};

const BarChartTable: FC<PieChartDonutProps> = ({ bar, colors, label }) => {
  const graphTable = (category: BarCategories[], label: string) => {
    return (
      <TableContainer>
        {category?.map((category: BarCategories, index: number) => {
          return (
            <div key={category.name} style={{ display: "flex" }}>
              <TableHead>
                <div
                  style={{
                    backgroundColor: colors[index % colors.length],
                    width: "6px",
                    minWidth: "6px",
                    height: "6px",
                  }}
                ></div>
                {category.name}
              </TableHead>
              {category.values.map((value: number, index: number) => {
                return (
                  <TableBlock key={`${label}-${value}-${index}`}>
                    {value}
                  </TableBlock>
                );
              })}
            </div>
          );
        })}
      </TableContainer>
    );
  };

  if (!bar?.categories.length || !bar.data.length) {
    return (
      <Empty
        style={{ width: "642px" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No data found"
      />
    );
  }

  return (
    <BarChartWrapper>
      <ChartLabel>{label}</ChartLabel>
      <BarChart
        width={601}
        height={300}
        data={bar?.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {bar?.categories.map((el: BarCategories, index: number) => {
          return (
            <Bar
              key={`${label}-${index}`}
              dataKey={el.name === "not_verified" ? "Not verified" : el.name}
              fill={colors[index]}
            />
          );
        })}
      </BarChart>
      {!!bar?.categories[0].values.length && graphTable(bar.categories, label)}
    </BarChartWrapper>
  );
};

export default BarChartTable;
