import React, { FC, useState } from "react";

import { UpdateClient } from "../../../../api/ClientApi/Client";
import InputStyled from "../../../Input/Input";
import { Title } from "../../../Input/Input/Input.styled";
import ModalStyled from "../../../Modal";
import { initialClientState } from "../ClientsManagementScreen";

import { ModalContent } from "./AccountModal.styled";

type TableStyledProps = {
  title: string;
  children: JSX.Element;
  handleSave: (data: any) => void;
  initialData?: UpdateClient;
  width?: number;
};
const AccountModal: FC<TableStyledProps> = ({
  children,
  title,
  handleSave,
  initialData = initialClientState,
  width,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setData] = useState<UpdateClient>(initialData);

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        width={width}
        handleSave={() => handleSave(modalData)}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Title></Title>
          <InputStyled
            title="Full name:"
            value={modalData?.name}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["name"]: value }));
            }}
          />
          <InputStyled
            title="Email:"
            value={modalData?.email}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["email"]: value }));
            }}
          />
          <InputStyled
            title="Claim Id:"
            value={modalData?.claim_id}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["claim_id"]: value }));
            }}
          />
          <InputStyled
            title="Phone:"
            value={modalData?.phone}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["phone"]: value }));
            }}
          />
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default AccountModal;
