import { FC } from "react";

export const Filter: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1248 22.125C9.9259 22.125 9.73513 22.046 9.59448 21.9053C9.45383 21.7647 9.37481 21.5739 9.37481 21.375V14.1637L2.42231 6.5175C2.14862 6.21591 1.96836 5.84136 1.9034 5.43931C1.83844 5.03727 1.89157 4.62501 2.05635 4.25257C2.22113 3.88013 2.49047 3.56353 2.83168 3.34119C3.17289 3.11885 3.5713 3.00032 3.97856 3H20.0211C20.4283 3.00032 20.8267 3.11885 21.1679 3.34119C21.5092 3.56353 21.7785 3.88013 21.9433 4.25257C22.108 4.62501 22.1612 5.03727 22.0962 5.43931C22.0313 5.84136 21.851 6.21591 21.5773 6.5175L14.6248 14.1637V18.375C14.6247 18.4873 14.5994 18.5982 14.5507 18.6995C14.5021 18.8008 14.4313 18.8898 14.3436 18.96L10.5936 21.96C10.4606 22.0667 10.2953 22.1249 10.1248 22.125ZM3.97856 4.5C3.86185 4.50029 3.74773 4.5344 3.65001 4.5982C3.55228 4.66201 3.47515 4.75276 3.42793 4.85949C3.38072 4.96622 3.36545 5.08435 3.38397 5.19958C3.4025 5.31481 3.45402 5.4222 3.53231 5.50875L10.6798 13.3837C10.8023 13.5184 10.8716 13.693 10.8748 13.875V19.815L13.1248 18V13.875C13.1244 13.6878 13.1939 13.5073 13.3198 13.3688L20.4673 5.49375C20.5409 5.40681 20.5885 5.30081 20.6044 5.188C20.6203 5.07519 20.604 4.96018 20.5573 4.85625C20.5107 4.75232 20.4355 4.66372 20.3406 4.60068C20.2458 4.53764 20.135 4.50273 20.0211 4.5H3.97856Z"
      fill="white"
    />
  </svg>
);
