export const timer = (time: number) => {
  if (!time) {
    return "";
  }
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  return `${minutes > 0 ? `${minutes}m` : "0m"} ${
    seconds > 9 ? `${seconds}s` : `0${seconds}s`
  }`;
};
