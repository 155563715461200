import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UpdateClient } from "../../../../api/ClientApi/Client";
import { Link } from "../../../../assets/Link";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import ModalStyled from "../../../Modal";
import { Cansel } from "../Licensing.styled";

import "./table.module.css";
import PdaContent from "./PdaContent";
import { CancelStyled, ModalContent, Privacy, Title } from "./PdaModal.styled";

type TableStyledProps = {
  title?: string;
  children?: JSX.Element;
  handleSave?: () => void;
  initialData?: UpdateClient;
  width?: number;
  okText?: string;
  footer?: null;
};
const Pda: FC<TableStyledProps> = ({
  children,
  title,
  width,
  okText,
  footer,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        footer={footer}
        okText={okText}
        width={width}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Privacy>
            <Title>Data protection agreement</Title>
            <div
              onClick={() => navigate("/licensing/pda")}
              style={{ display: "flex", cursor: "pointer" }}
            >
              <Link />
            </div>
          </Privacy>
          <PdaContent />
          <CancelStyled>
            <ButtonStyled
              background={Colors.WHITE_COLOR}
              padding="8px 16px"
              onClick={() => setIsModalOpen(false)}
            >
              <Cansel>Cancel</Cansel>
            </ButtonStyled>
          </CancelStyled>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default Pda;
