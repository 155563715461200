import styled from "styled-components";

export const AddAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  padding: 0 24px 27px 24px;
  width: 100%;
`;
