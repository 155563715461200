import { Content } from "antd/lib/layout/layout";
import React, { FC, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  clientApi,
  UpdateClient,
  CreateClient,
} from "../../../api/ClientApi/Client";
import { ButtonPlus } from "../../../assets";
import { useUser } from "../../../context/auth-context";
import { QueryNames } from "../../../utils/constants/QueryNames";
import { removeEmpty } from "../../../utils/helpers/removeEmpty";
import ButtonStyled from "../../Button";
import { TextStyled } from "../../Button/Button.styled";
import SearchInput from "../../Input/SearchInput";
import PanelHeader from "../../PanelHeader";

import AccountModal from "./AccountModal/AccountModal";
import { AddAccountWrapper, TableWrapper } from "./ClientsManagement.styled";
import ClientTable from "./ClientTable/ClientTable";

export type FilterType = {
  limit: number;
  offset: number;
  sort: string;
  dir: string;
  search: string;
};

export const initialClientState = {
  name: "",
  email: "",
  claim_id: "",
  phone: "",
};
const ClientsManagementScreen: FC = () => {
  const LIMIT = 10;

  const { user } = useUser();

  const [filterValues, setFilterValues] = useState<FilterType>({
    limit: LIMIT,
    offset: 0,
    sort: "client_id",
    dir: "",
    search: "",
  });

  const makeQuery = ({
    limit,
    search,
    offset,
    sort,
    dir,
  }: FilterType): string => {
    return `?limit=${limit}&offset=${offset}&sort=${sort}${
      dir ? `&dir=${dir}` : ""
    }${search ? `&search=${search}` : ""}
    `;
  };

  const {
    data: { operators: clients, total },
  } = useQuery({
    queryKey: [QueryNames.GET_CLIENTS, filterValues],
    queryFn: () => {
      return clientApi.get(makeQuery(filterValues));
    },
    initialData: {
      clients: [],
      total: 0,
      offset: 0,
    },
  });
  const queryClient = useQueryClient();
  const createClient = useMutation(clientApi.create);
  const updateClient = useMutation(clientApi.update);
  const deleteClient = useMutation(clientApi.deleteClient);
  const deleteClientSignature = useMutation(clientApi.delete);

  const clientCreate = (newClient: CreateClient) => {
    createClient.mutate(
      {
        name: newClient.name,
        email: newClient.email,
        phone: newClient.phone,
        claim_id: newClient.claim_id,
      },
      {
        onSuccess: () => {
          NotificationManager.success("New Client created");
          queryClient.invalidateQueries([QueryNames.GET_CLIENTS]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            error?.errors?.[0]
              ? `${error.errors[0].path[0]}, ${error.errors[0].message}`
              : error.message
          );
          queryClient.invalidateQueries([QueryNames.GET_CLIENTS]);
        },
      }
    );
  };

  const onClientUpdate = (updatedClient: UpdateClient, id: string) => {
    const filteredClient = removeEmpty(updatedClient);
    updateClient.mutate(
      {
        ...filteredClient,
        id: id,
      },
      {
        onSuccess: () => {
          NotificationManager.success("Client successfully updated");
          queryClient.invalidateQueries([QueryNames.GET_CLIENTS]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            error?.errors?.[0]
              ? `${error.errors[0].path[0]}, ${error.errors[0].message}`
              : error.message
          );
        },
      }
    );
  };

  const onDelete = (id: string) => {
    deleteClientSignature.mutate(id, {
      onSuccess: () => {
        NotificationManager.success(
          `Client ${id} voice print successfully deleted`
        );
        queryClient.invalidateQueries([QueryNames.GET_CLIENTS]);
      },
      onError: (error: any) => {
        NotificationManager.error(`${JSON.stringify(error?.request.response)}`);
      },
    });
  };

  const onDeleteClient = (id: string) => {
    deleteClient.mutate(id, {
      onSuccess: () => {
        NotificationManager.success(`Client ${id} successfully deleted`);
        queryClient.invalidateQueries([QueryNames.GET_CLIENTS]);
      },
      onError: (error: any) => {
        NotificationManager.error(`${JSON.stringify(error?.request.response)}`);
      },
    });
  };

  const onCreate = async (createClient: CreateClient) => {
    try {
      await clientCreate(createClient);
    } catch (error) {
      NotificationManager.error(`Error message:  ${error}`);
    }
  };

  return (
    <>
      <PanelHeader
        tabTitle="Management"
        title="Clients Management"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <AddAccountWrapper>
        <SearchInput
          onSearch={(value: string) =>
            setFilterValues((prev) => ({ ...prev, ["search"]: value }))
          }
        />
        <AccountModal
          width={500}
          handleSave={(data) => {
            onCreate({
              name: data.name as string,
              email: data.email as string,
              claim_id: data.claim_id as string,
              phone: data.phone as string,
            });
          }}
          title="Add Client Account"
        >
          <ButtonStyled>
            <>
              <ButtonPlus />
              <TextStyled>Add Client Account</TextStyled>
            </>
          </ButtonStyled>
        </AccountModal>
      </AddAccountWrapper>
      <Content>
        <TableWrapper>
          <ClientTable
            onDeleteClient={onDeleteClient}
            onDelete={onDelete}
            onUpdate={onClientUpdate}
            data={clients || []}
            limit={LIMIT}
            total={total}
            filter={setFilterValues}
            filterValues={filterValues}
            offset={filterValues.offset / LIMIT + 1}
            setOffset={(value) => {
              setFilterValues((prev) => ({
                ...prev,
                ["offset"]: (value - 1) * LIMIT,
              }));
            }}
          />
        </TableWrapper>
      </Content>
    </>
  );
};

export default ClientsManagementScreen;
