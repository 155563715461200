import { Button } from "antd";
import React, { FC } from "react";

import { ButtonStyledWrapper } from "./Button.styled";

type ButtonStyledProps = {
  children?: JSX.Element;
  onClick?: () => void;
  background?: string;
  padding?: string;
  border?: string;
};
const ButtonStyled: FC<ButtonStyledProps> = ({
  onClick,
  children,
  background,
  padding,
  border,
}) => {
  return (
    <ButtonStyledWrapper
      background={background}
      padding={padding}
      border={border}
    >
      <Button onClick={onClick}>{children}</Button>
    </ButtonStyledWrapper>
  );
};

export default ButtonStyled;
