import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const HeaderStyled = styled.div`
  padding: 26px 24px;
  background-color: ${Colors.WHITE_COLOR};

  .ant-layout-header {
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: ${Colors.WHITE_COLOR};
    height: max-content;
    row-gap: 2px;
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0 12px;
    transform: rotate(270deg);
  }
`;

export const Title = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${Colors.TITLE_BLACK_MUTED};
`;

export const TitleMain = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${Colors.TITLE_BLACK_MUTED};
  opacity: 0.5;
`;

export const SubTitle = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.TITLE_INNER_COLOR};
`;
