import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const AddAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  padding: 0 24px 27px 24px;
  width: 100%;
`;

export const Title = styled.div`
  width: 56px;
  text-align: start;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.WHITE_COLOR};
`;
