import { Popover } from "antd";
import React, { FC } from "react";

import { UserProps } from "../../../../api/UserApi/User";
import ButtonStyled from "../../../Button";
import { ButtonText, Corsound, Call } from "../Licensing.styled";
type PopoverProps = {
  title: string;
  className?: string;
  userData: UserProps;
};
const CustomPopover: FC<PopoverProps> = ({ className, title, userData }) => {
  const content = (
    <div style={{ display: "flex", gap: "4px" }}>
      <Call>Please contact: </Call>
      <Corsound
        href={`mailto: it@corsound.ai?subject=Plan renewal&body=clentid%${userData?.operator_id}%20username%${userData?.name}`}
        target="_blank"
      >
        Corsound.AI
      </Corsound>
    </div>
  );
  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomLeft"
      overlayClassName={className}
    >
      <ButtonStyled padding="8px 16px" border="none">
        <ButtonText>{title}</ButtonText>
      </ButtonStyled>
    </Popover>
  );
};

export default CustomPopover;
