import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction } from "react";

import { ManageListProps } from "../../../../api/ManageListApi/ManageList";
import { Edit } from "../../../../assets";
import { DeleteFile } from "../../../../assets/DeleteFile";
import { FileColored } from "../../../../assets/FileColored";
import { FileExport } from "../../../../assets/FileExport";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import TableStyled from "../../../Table";
import { NameWrapper } from "../../AgentsManagement/ManagementTable/ManagementTable.styled";
import EditListModal from "../AditListModal/EditListModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import { FilterType } from "../ManageListScreen";

import { ActionWrapper, IconActionWrapper } from "./ManageListTable.styled";

export interface DataClientType {
  key: React.Key;
  org_list_id: number;
  org_list_name: string;
  org_list_dt_create: string;
  total_clients: string;
  settings: number;
}

type TableStyledProps = {
  data: ManageListProps[];
  onDelete: (listId: string) => void;
  filterValues: any;
  onDownload: (id: string) => void;
  onClick?: () => void;
  setOffset?: (val: number) => void;
  total: number;
  offset?: number;
  limit?: number;
  filter?: Dispatch<SetStateAction<FilterType>>;
  onUpdate: (name: string, id: string) => void;
};
const ManageListTable: FC<TableStyledProps> = ({
  data,
  setOffset,
  offset,
  limit = 10,
  filter,
  total,
  onDelete,
  onDownload,
  onUpdate,
}) => {
  const columns: ColumnsType<DataClientType> = [
    {
      title: "List ID",
      dataIndex: "org_list_id",
      render: (callId) => <span>{callId}</span>,
    },
    {
      title: "Lists Name",
      dataIndex: "org_list_name",
      sorter: {},
      render: (listsName) => (
        <NameWrapper>
          <div style={{ display: "flex" }}>
            <FileColored />
          </div>
          <span style={{ whiteSpace: "nowrap" }}>{listsName}</span>
        </NameWrapper>
      ),
    },
    {
      title: "Total",
      dataIndex: "total_clients",
      sorter: {},
      render: (total) => <span style={{ whiteSpace: "nowrap" }}>{total}</span>,
    },
    {
      defaultSortOrder: "descend",
      title: "Uploaded Date",
      dataIndex: "org_list_dt_create",
      sorter: {},
      render: (date) => (
        <NameWrapper>
          <span style={{ whiteSpace: "nowrap" }}>
            {moment(date).format("YYYY[/]MM[/]D")}
          </span>
        </NameWrapper>
      ),
    },
    {
      title: "Settings",
      dataIndex: "settings",
      render: (arr) => (
        <ActionWrapper>
          <IconActionWrapper onClick={() => onDownload(arr[0])}>
            <FileExport />
          </IconActionWrapper>
          <IconActionWrapper>
            <DeleteModal
              content={`Are you sure you want to delete the List ID of: ${arr?.[0]}?`}
              width={350}
              handleSave={() => onDelete(arr[0])}
            >
              <DeleteFile />
            </DeleteModal>
          </IconActionWrapper>
          <EditListModal
            title="Update List name"
            width={350}
            initialData={arr[1]}
            handleSave={(value: string) => onUpdate(value, arr[0])}
          >
            <ButtonStyled background={Colors.BLACK_COLOR} padding="1px">
              <Edit />
            </ButtonStyled>
          </EditListModal>
        </ActionWrapper>
      ),
    },
  ];

  const dataParsers = (data: ManageListProps[]) => {
    return data.map((columnData) => {
      return {
        key: columnData.org_list_id,
        org_list_id: columnData.org_list_id,
        org_list_name: columnData.org_list_name,
        org_list_dt_create: columnData.org_list_dt_create,
        total_clients: columnData.total_clients,
        settings: [columnData.org_list_id, columnData.org_list_name],
      };
    });
  };

  const onChange: TableProps<DataClientType>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    if (sorter?.order && sorter?.field) {
      const field = sorter?.order
        ? sorter.order.substr(0, 3) === "asc"
          ? sorter.order.substr(0, 3)
          : sorter.order.substr(0, 4)
        : "";
      filter?.((prev) => ({
        ...prev,
        ["sort"]: sorter.field,
        ["dir"]: field,
        export: null,
      }));
    }
  };

  return (
    <TableStyled
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      total={total}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      dataSource={dataParsers(data)}
      onChange={onChange}
    />
  );
};

export default ManageListTable;
