import React, { FC, useState } from "react";

import ModalStyled from "../../../Modal";

import { ModalContent, Content } from "./DeleteModal.styled";

type TableStyledProps = {
  children: JSX.Element;
  content: string;
  handleSave: () => void;
  width?: number;
  title?: string;
};
const DeleteModal: FC<TableStyledProps> = ({
  children,
  title,
  handleSave,
  content,
  width,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        okText="Yes"
        cancelText="No"
        width={width}
        handleSave={handleSave}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Content>{content}</Content>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default DeleteModal;
