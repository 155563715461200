import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import React, { FC } from "react";
import { NotificationContainer } from "react-notifications";
import "./index.css";
import { Navigate } from "react-router-dom";

import { Logo } from "../../assets";
import { LogoWrapper } from "../../components/Layout/Layout.styled";
import { Colors } from "../../utils/colors";

import useLogin from "./useLogin";

const Login: FC = ({}) => {
  const { isAuth, onLogin } = useLogin();

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `${Colors.BLACK_COLOR}`,
      }}
    >
      {isAuth && <Navigate to="/" replace={true} />}

      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onLogin}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
      <NotificationContainer />
    </div>
  );
};

export default Login;
