import { Upload, UploadProps, Progress } from "antd";
import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { CLose } from "../../assets/Close";
import { File } from "../../assets/File";
import { UploadIcon } from "../../assets/Upload";
import { Colors } from "../../utils/colors";
import ButtonStyled from "../Button";
import { UploadFile } from "../screens/ManageList/UploadModal/UploadModal";

import {
  ButtonContainer,
  DraggedWrapper,
  DragInfoWrapper,
  FileName,
  FileSize,
  LoadingFileInnerWrapper,
  LoadingFileWrapper,
  ProgressStyled,
  RemoveFile,
  StyledDiv,
  SubText,
  TextStyled,
  UploadedFileWrapper,
  UploadText,
} from "./FileUpload.styled";

const { Dragger } = Upload;

type FileUploadProps = {
  modalData: UploadFile;
  onChange: Dispatch<SetStateAction<UploadFile>>;
};
const FileUpload: FC<FileUploadProps> = ({ onChange, modalData }) => {
  const props: UploadProps = {
    maxCount: 1,
    showUploadList: false,
    style: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const [progress, setProgress] = useState(0);

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleFileUpload = ({ file }) => {
    const fileSize = formatBytes(file.size);
    const fileArr = fileSize.split(" ");

    onChange((prevState) => ({
      ...prevState,
      file,
      fileSize,
      error: +fileArr[0] > 20 && fileArr[1] === "MB",
    }));
    setProgress(100);
  };

  const handleClose = () => {
    setProgress(0);
    onChange((prevState) => ({
      ...prevState,
      file: null,
      fileSize: "",
      error: false,
    }));
  };
  return (
    <>
      <DraggedWrapper>
        <Dragger {...props} customRequest={handleFileUpload}>
          <div style={{ padding: "8px 24px" }}>
            <DragInfoWrapper>
              <UploadIcon />
              <UploadText>Upload or Drag CSV File</UploadText>
            </DragInfoWrapper>
            <ButtonContainer>
              <StyledDiv>
                <ButtonStyled>
                  <TextStyled>Choose File</TextStyled>
                </ButtonStyled>
              </StyledDiv>
              <FileSize>20 mb max</FileSize>
            </ButtonContainer>
          </div>
        </Dragger>
      </DraggedWrapper>
      <div style={{ display: "flex" }}>
        <SubText>
          Some data formats, such as dates, and colors, may not be recognized.
        </SubText>
        {/*<ReadMore>Learn more.</ReadMore>*/}
      </div>
      {modalData?.file && (
        <LoadingFileWrapper>
          <LoadingFileInnerWrapper>
            <File
              stroke={modalData.error ? Colors.TITLE_NON_ACTIVE : undefined}
            />
            <UploadedFileWrapper>
              <FileName>{`${modalData?.file.name}(${modalData.fileSize})`}</FileName>
              {modalData.error ? (
                <FileName style={{ color: Colors.TITLE_NON_ACTIVE }}>
                  Exceeding the threshold size
                </FileName>
              ) : (
                <ProgressStyled>
                  <Progress
                    percent={Math.ceil(progress * 100)}
                    width={200}
                    format={() => "100%"}
                  />
                </ProgressStyled>
              )}
            </UploadedFileWrapper>
          </LoadingFileInnerWrapper>
          <RemoveFile onClick={handleClose}>
            <CLose />
          </RemoveFile>
        </LoadingFileWrapper>
      )}
    </>
  );
};

export default FileUpload;
