import { useCallback, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useMutation } from "react-query";

import { userApi } from "../../api/UserApi/User";

const useLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState("");
  const [isStaySingIn, setIsStaySingIn] = useState(true);

  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const saveToken = useCallback((token: string) => {
    if (isStaySingIn) return localStorage.setItem("token", token);

    return sessionStorage.setItem("token", token);
  }, []);

  const handleToggle = useCallback(() => {
    setIsStaySingIn((isStaySingIn) => !isStaySingIn);
  }, []);

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const postUser = useMutation(userApi.login);

  type User = { email: string; password: string };

  const userLogin = (user: User) => {
    postUser.mutate(
      { email: user.email, password: user.password },
      {
        onSuccess: (data) => saveToken(data.token),
        onError: (err: any) =>
          NotificationManager.error(`Error message:  ${err.message}`),
      }
    );
  };

  const onLogin = async (user: User) => {
    try {
      setStatus(validateEmail(user.email) ? "access" : "");
      await userLogin(user);
    } catch (error) {
      throw error;
    }
  };

  return {
    onLogin,
    handleToggle,
    isAuth,
    isStaySingIn,
    showPassword,
    setShowPassword,
    status,
    setStatus,
  };
};

export default useLogin;
