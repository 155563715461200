import { Input } from "antd";
import React, { FC } from "react";

import { EyeHide } from "../../../assets/EyeHide";
import EyeView from "../../../assets/view.png";

import { InputStyledWrapper, Title } from "./Input.styled";

type MainLayoutProps = {
  title?: string;
  onChange?: (value: string) => void;
  value?: string;
  type?: "password" | "string" | "number";
  placeholder?: string;
  disabled?: boolean;
};
const InputStyled: FC<MainLayoutProps> = ({
  onChange,
  title,
  type,
  value,
  placeholder,
  disabled,
}) => {
  return (
    <div>
      <Title>{title}</Title>
      <InputStyledWrapper width={440}>
        {type !== "password" ? (
          <Input
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            type={type}
          />
        ) : (
          <Input.Password
            iconRender={(visible) =>
              visible ? (
                <div style={{ display: "flex" }}>
                  <img src={EyeView} alt="Eye view" />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <EyeHide />
                </div>
              )
            }
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
          />
        )}
      </InputStyledWrapper>
    </div>
  );
};

export default InputStyled;
