import { FC } from "react";

type IconProps = {
  stroke?: string;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Logo: FC<IconProps> = ({ stroke = "#858691" }) => (
  <svg
    width="186"
    height="51"
    viewBox="0 0 186 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5846 3.22434L26.4105 0.388487C26.4105 0.388487 27.8352 -0.203827 29.7135 0.703153C31.5917 1.61013 50.4771 10.7771 50.4771 10.7771C50.4771 10.7771 52.9815 11.4998 52.9815 15.1408C52.9815 18.7818 52.9815 34.6007 52.9815 34.6007C52.9815 34.6007 53.0447 36.4294 51.1797 37.3965C49.3148 38.3636 46.6161 39.7264 46.6161 39.7264C46.6161 39.7264 47.7488 38.4562 47.805 35.1661C47.8081 31.1124 47.805 19.3618 47.805 19.3618V16.7689C47.805 16.7689 47.9931 14.7189 44.9626 13.2566C41.9322 11.7951 26.9398 4.48995 26.9398 4.48995C26.9398 4.48995 23.5721 2.42379 20.5846 3.22434Z"
      fill="url(#paint0_linear_142_1630)"
    />
    <path
      d="M0 13.6964L5.82598 10.8606C5.82598 10.8606 7.25066 10.2682 9.1289 11.1752C11.0071 12.0822 29.8926 21.2492 29.8926 21.2492C29.8926 21.2492 32.3969 21.9718 32.3969 25.6129C32.3969 29.2539 32.3969 45.0728 32.3969 45.0728C32.3969 45.0728 32.4601 46.9014 30.5952 47.8686C28.731 48.8349 26.0315 50.1985 26.0315 50.1985C26.0315 50.1985 27.1642 48.9283 27.2204 45.6381C27.2235 41.5845 27.2204 29.8339 27.2204 29.8339V27.241C27.2204 27.241 27.4086 25.191 24.3781 23.7287C21.3476 22.2672 6.35526 14.962 6.35526 14.962C6.35526 14.962 2.98754 12.8959 0 13.6964Z"
      fill="url(#paint1_linear_142_1630)"
    />
    <path
      d="M39.8305 15.8287C38.5471 15.2055 20.9536 6.66559 19.1441 5.79178C17.2658 4.8848 15.8412 5.47711 15.8412 5.47711L10.0152 8.31296C13.0027 7.51242 16.3704 9.57857 16.3704 9.57857C16.3704 9.57857 31.3628 16.883 34.3933 18.3453C34.4924 18.3931 34.5877 18.4417 34.6798 18.4903C35.5916 18.0075 38.7352 16.3909 39.8305 15.8287Z"
      fill="url(#paint2_linear_142_1630)"
    />
    <path
      d="M42.4117 20.2295C42.4117 20.0544 42.4039 19.8886 42.393 19.7266L37.2352 22.2779V24.4497C37.2352 24.4497 37.2383 36.2011 37.2352 40.254C37.179 43.5441 36.0463 44.8143 36.0463 44.8143C36.0463 44.8143 38.7458 43.4515 40.61 42.4844C42.4741 41.518 42.4117 39.6886 42.4117 39.6886C42.4117 39.6886 42.4117 23.8697 42.4117 20.2295Z"
      fill="url(#paint3_linear_142_1630)"
    />
    <path
      d="M19.0219 26.248C19.0219 26.248 20.3646 26.8842 21.0859 27.9216C21.5629 28.6943 21.6948 29.7479 21.6948 31.3397V45.7719L6.13963 38.3071L4.31447 37.3893C4.31447 37.3893 3.58457 36.8926 3.42688 36.7608C3.26918 36.6289 2.09041 36.0381 2.10602 33.7305C2.12163 31.423 2.10602 18.0404 2.10602 18.0404L19.0219 26.248Z"
      fill="url(#paint4_linear_142_1630)"
    />
    <path
      d="M67.5261 32.0739C68.4549 32.4307 69.4578 32.6091 70.5347 32.6091C71.7058 32.6091 72.7692 32.4032 73.7249 31.9915C74.6807 31.5798 75.4883 30.9829 76.1479 30.2006L74.4518 28.5538C73.9403 29.1302 73.3682 29.5625 72.7356 29.8507C72.1029 30.1251 71.4164 30.2624 70.676 30.2624C69.9357 30.2624 69.2559 30.1389 68.6367 29.8918C68.0309 29.6448 67.4992 29.2949 67.0415 28.842C66.5973 28.3891 66.2473 27.8539 65.9916 27.2364C65.7493 26.6188 65.6281 25.9395 65.6281 25.1984C65.6281 24.4574 65.7493 23.7781 65.9916 23.1605C66.2473 22.543 66.5973 22.0077 67.0415 21.5549C67.4992 21.102 68.0309 20.7521 68.6367 20.505C69.2559 20.258 69.9357 20.1345 70.676 20.1345C71.4164 20.1345 72.1029 20.2786 72.7356 20.5668C73.3682 20.8413 73.9403 21.2598 74.4518 21.8225L76.1479 20.1963C75.4883 19.4003 74.6807 18.8033 73.7249 18.4054C72.7692 17.9937 71.7125 17.7878 70.5549 17.7878C69.4645 17.7878 68.4617 17.9731 67.5463 18.3436C66.631 18.7004 65.83 19.215 65.1435 19.8875C64.457 20.5462 63.9253 21.3284 63.5484 22.2342C63.1715 23.1399 62.983 24.128 62.983 25.1984C62.983 26.2689 63.1715 27.257 63.5484 28.1627C63.9253 29.0684 64.4503 29.8575 65.1233 30.53C65.8098 31.1887 66.6108 31.7033 67.5261 32.0739Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.9412 32.0533C82.87 32.4238 83.8863 32.6091 84.9901 32.6091C86.0804 32.6091 87.0833 32.4238 87.9986 32.0533C88.9275 31.6828 89.7351 31.1681 90.4216 30.5094C91.1081 29.8507 91.6399 29.0684 92.0168 28.1627C92.3937 27.257 92.5821 26.2689 92.5821 25.1984C92.5821 24.128 92.3937 23.1468 92.0168 22.2548C91.6399 21.349 91.1081 20.5668 90.4216 19.9081C89.7351 19.2356 88.9275 18.7141 87.9986 18.3436C87.0833 17.9731 86.0737 17.7878 84.9699 17.7878C83.8796 17.7878 82.87 17.9731 81.9412 18.3436C81.0124 18.7141 80.2047 19.2356 79.5182 19.9081C78.8317 20.5668 78.3 21.349 77.923 22.2548C77.5461 23.1468 77.3577 24.128 77.3577 25.1984C77.3577 26.2689 77.5461 27.257 77.923 28.1627C78.3 29.0547 78.8317 29.837 79.5182 30.5094C80.2047 31.1681 81.0124 31.6828 81.9412 32.0533ZM86.9487 29.8918C86.3429 30.1389 85.6833 30.2624 84.9699 30.2624C84.2565 30.2624 83.5969 30.1389 82.9911 29.8918C82.3854 29.6448 81.8604 29.2949 81.4162 28.842C80.972 28.3754 80.622 27.8333 80.3662 27.2158C80.1239 26.5982 80.0028 25.9258 80.0028 25.1984C80.0028 24.4574 80.1239 23.7849 80.3662 23.1811C80.622 22.5635 80.972 22.0283 81.4162 21.5755C81.8739 21.1089 82.3988 20.7521 82.9911 20.505C83.5969 20.258 84.2565 20.1345 84.9699 20.1345C85.6833 20.1345 86.3429 20.258 86.9487 20.505C87.5544 20.7521 88.0794 21.1089 88.5236 21.5755C88.9678 22.0283 89.3111 22.5635 89.5534 23.1811C89.8092 23.7849 89.937 24.4574 89.937 25.1984C89.937 25.9395 89.8092 26.6188 89.5534 27.2364C89.3111 27.8402 88.9678 28.3754 88.5236 28.842C88.0794 29.2949 87.5544 29.6448 86.9487 29.8918Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.733 28.1903C101.552 28.1994 101.368 28.2039 101.18 28.2039H97.9894V32.4032H95.3645V17.9937H101.18C102.432 17.9937 103.502 18.1995 104.39 18.6112C105.292 19.0229 105.985 19.613 106.47 20.3815C106.954 21.15 107.197 22.0626 107.197 23.1193C107.197 24.176 106.954 25.0887 106.47 25.8572C105.985 26.612 105.292 27.1952 104.39 27.6069C104.327 27.6351 104.263 27.6624 104.198 27.6886L107.439 32.4032H104.612L101.733 28.1903ZM103.663 25.2396C103.084 25.7474 102.216 26.0013 101.058 26.0013H97.9894V20.258H101.058C102.216 20.258 103.084 20.505 103.663 20.9991C104.255 21.4931 104.552 22.1999 104.552 23.1193C104.552 24.0251 104.255 24.7318 103.663 25.2396Z"
      fill="white"
    />
    <path
      d="M111.345 32.1356C112.355 32.4513 113.411 32.6091 114.515 32.6091C115.807 32.6091 116.877 32.417 117.726 32.0327C118.574 31.6347 119.2 31.1132 119.603 30.4682C120.021 29.8095 120.229 29.0959 120.229 28.3274C120.229 27.5726 120.074 26.9482 119.765 26.4541C119.469 25.9601 119.072 25.569 118.574 25.2808C118.089 24.9926 117.544 24.7593 116.938 24.5809C116.346 24.4025 115.747 24.2447 115.141 24.1074C114.535 23.9565 113.983 23.8055 113.485 23.6546C113.001 23.5036 112.604 23.2977 112.294 23.037C111.998 22.7625 111.85 22.4057 111.85 21.9666C111.85 21.596 111.951 21.2667 112.153 20.9785C112.368 20.6903 112.698 20.457 113.142 20.2786C113.6 20.1002 114.192 20.011 114.919 20.011C115.538 20.011 116.184 20.1071 116.857 20.2992C117.53 20.4776 118.183 20.7589 118.816 21.1432L119.644 19.0641C119.025 18.6524 118.298 18.3367 117.463 18.1172C116.628 17.8976 115.787 17.7878 114.939 17.7878C113.647 17.7878 112.577 17.9868 111.729 18.3848C110.894 18.769 110.268 19.2905 109.851 19.9492C109.447 20.5942 109.245 21.3079 109.245 22.0901C109.245 22.8586 109.4 23.4899 109.709 23.9839C110.019 24.478 110.416 24.8759 110.901 25.1779C111.399 25.466 111.944 25.7062 112.536 25.8983C113.142 26.0767 113.741 26.2346 114.333 26.3718C114.939 26.509 115.484 26.66 115.969 26.8247C116.467 26.9756 116.871 27.1883 117.18 27.4628C117.49 27.7235 117.645 28.0735 117.645 28.5126C117.645 28.8695 117.537 29.192 117.322 29.4801C117.106 29.7546 116.77 29.9742 116.312 30.1389C115.854 30.3035 115.255 30.3859 114.515 30.3859C113.64 30.3859 112.785 30.2349 111.951 29.933C111.116 29.6311 110.416 29.2537 109.851 28.8008L108.942 30.8799C109.534 31.3877 110.335 31.8063 111.345 32.1356Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.455 32.6091C128.351 32.6091 127.335 32.4238 126.406 32.0533C125.477 31.6828 124.67 31.1681 123.983 30.5094C123.297 29.837 122.765 29.0547 122.388 28.1627C122.011 27.257 121.823 26.2689 121.823 25.1984C121.823 24.128 122.011 23.1468 122.388 22.2548C122.765 21.349 123.297 20.5668 123.983 19.9081C124.67 19.2356 125.477 18.7141 126.406 18.3436C127.335 17.9731 128.344 17.7878 129.435 17.7878C130.539 17.7878 131.548 17.9731 132.463 18.3436C133.392 18.7141 134.2 19.2356 134.886 19.9081C135.573 20.5668 136.105 21.349 136.482 22.2548C136.859 23.1468 137.047 24.128 137.047 25.1984C137.047 26.2689 136.859 27.257 136.482 28.1627C136.105 29.0684 135.573 29.8507 134.886 30.5094C134.2 31.1681 133.392 31.6828 132.463 32.0533C131.548 32.4238 130.545 32.6091 129.455 32.6091ZM129.435 30.2624C130.148 30.2624 130.808 30.1389 131.414 29.8918C132.019 29.6448 132.544 29.2949 132.988 28.842C133.433 28.3754 133.776 27.8402 134.018 27.2364C134.274 26.6188 134.402 25.9395 134.402 25.1984C134.402 24.4574 134.274 23.7849 134.018 23.1811C133.776 22.5635 133.433 22.0283 132.988 21.5755C132.544 21.1089 132.019 20.7521 131.414 20.505C130.808 20.258 130.148 20.1345 129.435 20.1345C128.721 20.1345 128.062 20.258 127.456 20.505C126.864 20.7521 126.339 21.1089 125.881 21.5755C125.437 22.0283 125.087 22.5635 124.831 23.1811C124.589 23.7849 124.468 24.4574 124.468 25.1984C124.468 25.9258 124.589 26.5982 124.831 27.2158C125.087 27.8333 125.437 28.3754 125.881 28.842C126.325 29.2949 126.85 29.6448 127.456 29.8918C128.062 30.1389 128.721 30.2624 129.435 30.2624Z"
      fill="white"
    />
    <path
      d="M141.344 30.9417C142.447 32.0533 143.969 32.6091 145.907 32.6091C147.832 32.6091 149.34 32.0533 150.43 30.9417C151.534 29.8301 152.086 28.2245 152.086 26.1248V17.9937H149.501V26.0218C149.501 27.504 149.191 28.5813 148.572 29.2537C147.953 29.9262 147.071 30.2624 145.927 30.2624C144.783 30.2624 143.895 29.9262 143.262 29.2537C142.643 28.5813 142.333 27.504 142.333 26.0218V17.9937H139.708V26.1248C139.708 28.2245 140.253 29.8301 141.344 30.9417Z"
      fill="white"
    />
    <path
      d="M166.16 32.4032L158.366 22.6474V32.4032H155.762V17.9937H157.922L165.716 27.7495V17.9937H168.321V32.4032H166.16Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.325 18.8994C181.167 18.2956 179.821 17.9937 178.287 17.9937H172.108V32.4032H178.287C179.821 32.4032 181.167 32.1013 182.325 31.4975C183.483 30.8937 184.385 30.0565 185.031 28.9861C185.677 27.902 186 26.6394 186 25.1984C186 23.7438 185.677 22.4812 185.031 21.4108C184.385 20.3404 183.483 19.5032 182.325 18.8994ZM180.912 29.5213C180.144 29.933 179.229 30.1389 178.166 30.1389H174.733V20.258H178.166C179.229 20.258 180.144 20.4639 180.912 20.8756C181.692 21.2873 182.291 21.8637 182.709 22.6047C183.14 23.3321 183.355 24.1966 183.355 25.1984C183.355 26.1865 183.14 27.0511 182.709 27.7922C182.291 28.5332 181.692 29.1096 180.912 29.5213Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_142_1630"
        x1="51.479"
        y1="16.8878"
        x2="74.9395"
        y2="84.7582"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37B2B9" />
        <stop offset="1" stopColor="#69C28E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_142_1630"
        x1="94.6396"
        y1="-1.47328"
        x2="94.499"
        y2="44.3791"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09" stopColor="#3DC3D8" />
        <stop offset="1" stopColor="#592C7D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_142_1630"
        x1="176.869"
        y1="44.1936"
        x2="29.5767"
        y2="-58.8168"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6AC28B" />
        <stop offset="1" stopColor="#5FC199" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_142_1630"
        x1="93.0192"
        y1="51.8063"
        x2="93.0192"
        y2="-12.0337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6FC493" />
        <stop offset="1" stopColor="#47BEA7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_142_1630"
        x1="94.3164"
        y1="7.66435"
        x2="94.1437"
        y2="73.0216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3DC3D8" />
        <stop offset="0.5" stopColor="#592C7D" />
      </linearGradient>
    </defs>
  </svg>
);
