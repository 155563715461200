import React, { FC } from "react";

import { UpdateClient } from "../../../../api/ClientApi/Client";
import { UserProps } from "../../../../api/UserApi/User";
import { Email } from "../../../../assets/Email";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import ModalStyled from "../../../Modal";
import { StyledPopover } from "../Licensing.styled";

import {
  Cancel,
  Expires,
  ModalContent,
  ModalFooter,
} from "./LicenseModal.styled";

type TableStyledProps = {
  title?: string;
  children?: JSX.Element;
  handleSave?: () => void;
  initialData?: UpdateClient;
  width?: number;
  modalOpen?: boolean;
  okText?: string;
  footer?: null;
  userData: UserProps;
  setIsModalOpen: (value: boolean) => void;
};
const AccountModal: FC<TableStyledProps> = ({
  children,
  title,
  width,
  modalOpen = false,
  okText,
  setIsModalOpen,
  userData,
  footer,
}) => {
  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        footer={footer}
        okText={okText}
        width={width}
        title={title}
        isModalOpen={modalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Email></Email>
          <Expires>Your subscription expires this month</Expires>
          {/*<Content>*/}
          {/*  Lorem ipsum dolor sit amet consectetur. Faucibus tincidunt velit*/}
          {/*  tempor sagittis dignissim.*/}
          {/*</Content>*/}
          <ModalFooter>
            <StyledPopover title="Renew your plan" userData={userData} />
            <ButtonStyled
              padding="8px 16px"
              background={Colors.WHITE_COLOR}
              onClick={() => setIsModalOpen(false)}
            >
              <Cancel>Cancel</Cancel>
            </ButtonStyled>
          </ModalFooter>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default AccountModal;
