import { AxiosInstance } from "axios";

import { userEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export const clientInitialData = {
  org_name: "",
  dt_create: "",
  dt_last_login: "",
  dt_token_expire: "",
  dt_update: "",
  id: "",
  operator_id: "",
  org_id: 0,
  role: "",
  name: "",
  email: "",
  password: "",
  natural_operator_id: "",
  is_active: true,
  operator_phone: "",
};

export type CreateUser = {
  name: string;
  email: string;
  natural_operator_id: string;
  password?: string;
  role: string;
  operator_phone: string;
};

export type UpdateUser = {
  dt_token_expire?: string;
  email?: string;
  is_active?: boolean;
  name?: string;
  natural_operator_id?: string;
  password?: string;
  role?: string;
  id?: string;
  operator_phone?: string;
};

export type UserProps = {
  dt_create: string;
  dt_last_login: string;
  dt_token_expire: string;
  dt_update: string;
  email: string;
  is_active: boolean;
  name: string;
  password: string;
  natural_operator_id: string;
  operator_id: string;
  org_name: string;
  org_id: number;
  role: string;
  id?: string;
  call_dt_create?: string;
  operator_phone: string;
};

class User {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  login = async (data: any): Promise<any> => {
    return this.requestInstance.post(userEndpoints.login, {
      email: data.email,
      password: data.password,
    });
  };

  get = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${userEndpoints.operator}${queries}`);
  };

  getProfile = async (): Promise<any> => {
    return this.requestInstance.get(`${userEndpoints.profile}`);
  };

  create = async (newUser: CreateUser): Promise<any> => {
    return this.requestInstance.post(`${userEndpoints.operator}`, {
      name: newUser.name,
      email: newUser.email,
      password: newUser.password,
      natural_operator_id: newUser.natural_operator_id,
      role: newUser.role,
    });
  };

  getById = async (id: string): Promise<any> => {
    return this.requestInstance.get(`${userEndpoints.operator}/${id}`);
  };

  update = async (updateUser: UpdateUser): Promise<any> => {
    const { id, ...rest } = updateUser;
    return this.requestInstance.patch(`${userEndpoints.operator}/${id}`, rest);
  };
}

export const userApi = new User(axios);
