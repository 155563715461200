export enum Ethnicity {
  // WHITE = "white",
  // BLACK = "black",
  CAUCASIAN_LATIN = "caucasian-latin",
  AFRICAN_AMERICAN = "african-american",
  ASIAN_INDIAN = "asian-indian",
  EAST_ASIAN = "east-asian",
}

export enum AgeStart {
  EIGHTEEN = "18,24",
  TWENTY_FIVE = "24,35",
  THIRTY_FIVE = "35,44",
  FORTY_FIVE = "44,54",
  FIFTY_FIVE = "54,64",
  SIXTY_FIVE = "65",
}

export enum Duration {
  LESS_FOR = "0,5",
  FIVE = "5,10",
  ELEVEN = "10,20",
  MORE = "20,999",
}
