import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const TextStyled = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
`;

export const UploadText = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.BLACK_SECOND};
`;

export const FileSize = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: ${Colors.BLACK_SECOND};
`;

export const FileName = styled.div`
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.BLACK};
`;

export const SubText = styled.div`
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: rgba(41, 45, 50, 0.5);
  margin: 0;
`;

export const DragInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingFileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LoadingFileInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RemoveFile = styled.div`
  cursor: pointer;
  height: 24px;
  margin-top: 2px;
`;

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  & button {
    background: linear-gradient(#e8f1ff, #e8f1ff) padding-box,
      linear-gradient(90.97deg, #592c7d 0.16%, #3fbdd4 43.58%, #6bc495 101.83%)
        border-box;
    border-radius: 4px;
    border: 1px solid transparent;

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
  }
`;

export const ReadMore = styled.a`
  margin-left: 2px;
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: ${Colors.TITLE_ACTIVE};

  &:hover {
    color: ${Colors.TITLE_ACTIVE};
    text-decoration: underline;
  }
`;

export const DraggedWrapper = styled.div`
  & .ant-upload-btn {
    background: rgba(39, 108, 181, 0.1);
  }
  & .ant-upload-drag {
    border: 1px dashed #1269fb;
    border-radius: 8px;
  }
`;

export const ProgressStyled = styled.div`
  & .ant-progress {
    height: max-content;
    margin: 0;

    display: flex;
    flex-direction: unset;
    align-items: center;
  }
  & .ant-progress-outer {
    line-height: 2px;
    padding-right: 40px !important;
  }
  & .ant-progress-bg {
    height: 4px !important;
    background: ${Colors.MENU_COLOR};
  }

  & .ant-progress-text {
    width: max-content;
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    color: #000000 !important;
    margin-left: 10px;
  }
`;
