import React, { FC } from "react";

import { ModalStyledWrapper } from "./Modal.styled";

type ModalStyledProps = {
  children: JSX.Element;
  modalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  handleSave?: () => void;
  handleClose?: () => void;
  width?: number;
  okText?: string;
  title?: string;
  footer?: null;
  cancelText?: string;
};

const ModalStyled: FC<ModalStyledProps> = ({
  children,
  title,
  modalOpen,
  isModalOpen,
  handleSave,
  handleClose,
  width,
  okText = "Save",
  footer,
  cancelText,
}) => {
  const handleOk = () => {
    handleSave?.();
    modalOpen?.(false);
  };

  const handleCancel = () => {
    handleClose?.();
    modalOpen?.(false);
  };

  return (
    <>
      <ModalStyledWrapper
        footer={footer}
        width={width}
        title={title}
        open={isModalOpen}
        cancelText={cancelText}
        okText={okText}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {children}
      </ModalStyledWrapper>
    </>
  );
};

export default ModalStyled;
