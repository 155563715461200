import React, { FC } from "react";
import "./index.css";
import { NotificationManager } from "react-notifications";
import { useMutation } from "react-query";

import { ConfigUpdate, settingsApi } from "../../../api/SettingsApi/Settings";
import { useUser } from "../../../context/auth-context";
import PanelHeader from "../../PanelHeader";

import {
  ContentWrapper,
  SettingContainer,
  SettingContent,
  SettingTitle,
  SwitchStyled,
  TitleWrapper,
} from "./Settings.styled";

const SettingsScreen: FC = () => {
  const { user, config, onUpdateSettings } = useUser();

  const updateConfig = useMutation(settingsApi.update);

  const onClientUpdate = (updatedConfig: ConfigUpdate) => {
    updateConfig.mutate(
      {
        ...updatedConfig,
      },
      {
        onSuccess: () => {
          NotificationManager.success(
            `${updatedConfig.path} updated to ${updatedConfig.value}`
          );
          onUpdateSettings();
        },
        onError: (error: any) => {
          NotificationManager.error(`${error}`);
        },
      }
    );
  };

  // const tagList = [
  //   "lorem Ipsum1",
  //   "lorem Ipsum2",
  //   "lorem Ipsum3",
  //   "lorem Ipsum4",
  // ];

  // const [activeTags, setActiveTags] = useState<string[]>([]);

  // const onClickTag = (tagToAdd: string) => {
  //   const tagList = [...activeTags];
  //   if (tagList.includes(tagToAdd)) {
  //     setActiveTags(tagList.filter((tag) => tag !== tagToAdd));
  //   } else {
  //     tagList.push(tagToAdd);
  //     setActiveTags(tagList);
  //   }
  // };

  return (
    <>
      <PanelHeader
        title="Settings"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <ContentWrapper>
        <SettingContainer>
          {config && (
            <SettingContainer>
              <TitleWrapper>
                <SettingTitle>Abilities</SettingTitle>
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable age</SettingContent>
                <SwitchStyled
                  checked={config.can.age}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.age",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable antispoof</SettingContent>
                <SwitchStyled
                  checked={config.can.antispoof}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.antispoof",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable ethnicity</SettingContent>
                <SwitchStyled
                  checked={config.can.ethnicity}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.ethnicity",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable gender</SettingContent>
                <SwitchStyled
                  checked={config.can.gender}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.gender",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable verify</SettingContent>
                <SwitchStyled
                  checked={config.can.verify}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.verify",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable enroll</SettingContent>
                <SwitchStyled
                  checked={config.can.enroll}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.enroll",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Enable voice2face</SettingContent>
                <SwitchStyled
                  checked={config.can.voice2face}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.voice2face",
                      value,
                    })
                  }
                />
              </TitleWrapper>
              <TitleWrapper>
                <SettingContent>Debug</SettingContent>
                <SwitchStyled
                  checked={config.can.debug}
                  onChange={(value) =>
                    onClientUpdate({
                      path: "can.debug",
                      value,
                    })
                  }
                />
              </TitleWrapper>
            </SettingContainer>
          )}

          {/*  <SettingTitle>ID Preference</SettingTitle>*/}
          {/*  <RadioStyled onClick={() => onChange(!value)} checked={value}>*/}
          {/*    <SettingContent>Incoming calls</SettingContent>*/}
          {/*  </RadioStyled>*/}
          {/*</SettingContainer>*/}
          {/*<SubText margin="8px 0 0 0 ">*/}
          {/*  Incoming call - is the user ID, no need for agent to insert name and*/}
          {/*  account details*/}
          {/*</SubText>*/}
          {/*<Hr />*/}

          {/*<SettingContainer>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingTitle>IVR use</SettingTitle>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <Checkbox onChange={onChangeCheck}>*/}
          {/*    <SettingContent>*/}
          {/*      Active password generator for speech recognition*/}
          {/*    </SettingContent>*/}
          {/*  </Checkbox>*/}
          {/*  <div>*/}
          {/*    <Checkbox onChange={onChangeCheck}>*/}
          {/*      <SettingContent>Choose language</SettingContent>*/}
          {/*    </Checkbox>*/}
          {/*  </div>*/}

          {/*  <SettingTitle margin="12px 0 2px 0 ">IVR voice</SettingTitle>*/}
          {/*  <Radio.Group*/}
          {/*    onChange={onChangeGroup}*/}
          {/*    value={valueGroup}*/}
          {/*    style={{*/}
          {/*      display: "flex",*/}
          {/*      flexDirection: "column",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <RadioStyled value={1}>*/}
          {/*      <SettingContent>Male voice</SettingContent>*/}
          {/*    </RadioStyled>*/}
          {/*    <Radio.Group*/}
          {/*      disabled={valueGroup !== 1}*/}
          {/*      onChange={onChangeableGroup}*/}
          {/*      value={maleGroup}*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        marginLeft: "32px",*/}
          {/*        marginTop: "8px",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <RadioStyled value={1}>*/}
          {/*        <SettingContent>Formal</SettingContent>*/}
          {/*      </RadioStyled>*/}
          {/*      <RadioStyled style={{ marginTop: "8px" }} value={2}>*/}
          {/*        <SettingContent>Casual</SettingContent>*/}
          {/*      </RadioStyled>*/}
          {/*    </Radio.Group>*/}
          {/*    <RadioStyled value={2} style={{ marginTop: "8px" }}>*/}
          {/*      <SettingContent>Woman voice</SettingContent>*/}
          {/*    </RadioStyled>*/}
          {/*    <RadioStyled value={3} style={{ marginTop: "12px" }}>*/}
          {/*      <SettingContent>Robotic voice</SettingContent>*/}
          {/*    </RadioStyled>*/}
          {/*  </Radio.Group>*/}
          {/*</SettingContainer>*/}
          {/*<Hr />*/}

          {/*<SettingContainer>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingTitle>SR</SettingTitle>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Show age</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <div>*/}
          {/*    <TitleWrapper>*/}
          {/*      <SettingSubContent margin="0 0 0 32px">*/}
          {/*        Show age group*/}
          {/*      </SettingSubContent>*/}
          {/*      <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*    </TitleWrapper>*/}
          {/*    <SubText margin="8px 0 0 32px">*/}
          {/*      Age groups are divided into the following groups: 18-24, 25-34,*/}
          {/*      35-44, 45-54, 55-64, and 65 and older*/}
          {/*    </SubText>*/}
          {/*  </div>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingSubContent margin="0 0 0 32px">*/}
          {/*      Show specific age*/}
          {/*    </SettingSubContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}

          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Show gender</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}

          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Show ethnicity</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*</SettingContainer>*/}
          {/*<Hr />*/}
          {/*<SettingContainer>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingTitle>Synthetic Speech Detection</SettingTitle>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*</SettingContainer>*/}
          {/*<Hr />*/}

          {/*<SettingContainer gap="11px">*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingTitle>Voice-To-Face</SettingTitle>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <Checkbox onChange={onChangeCheck}>*/}
          {/*    <SettingContent>Show person image (for sure in MVP)</SettingContent>*/}
          {/*  </Checkbox>*/}
          {/*  <div>*/}
          {/*    <Checkbox onChange={onChangeCheck}>*/}
          {/*      <SettingContent>Show person cartoon</SettingContent>*/}
          {/*    </Checkbox>*/}
          {/*  </div>*/}
          {/*  <Checkbox onChange={onChangeCheck}>*/}
          {/*    <SettingContent>Show person nose</SettingContent>*/}
          {/*  </Checkbox>*/}
          {/*  <div>*/}
          {/*    <Checkbox onChange={onChangeCheck}>*/}
          {/*      <SettingContent>*/}
          {/*        Show person face estimation (clasteron/sketch)*/}
          {/*      </SettingContent>*/}
          {/*    </Checkbox>*/}
          {/*  </div>*/}
          {/*</SettingContainer>*/}
          {/*<Hr margin="16px 0 27px 0" />*/}

          {/*<TitleWrapper>*/}
          {/*  <SettingTitle>Others</SettingTitle>*/}
          {/*</TitleWrapper>*/}
          {/*<SettingContainer margin="20px 0 0 0 ">*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Noise cancellation</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Diarization</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingContent>Emotional score</SettingContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}

          {/*  <SettingContent>Transcription</SettingContent>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingSubContent margin="0 0 0 32px">*/}
          {/*      Auto-save transcription*/}
          {/*    </SettingSubContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*  <TitleWrapper>*/}
          {/*    <SettingSubContent margin="0 0 0 32px">*/}
          {/*      Choose keywords to pop in report screen*/}
          {/*    </SettingSubContent>*/}
          {/*    <SwitchStyled defaultChecked onChange={onChangeSwitch} />*/}
          {/*  </TitleWrapper>*/}
          {/*<HashTagWrapper>*/}
          {/*  {tagList.map((tag) => {*/}
          {/*    return (*/}
          {/*      <HashTag*/}
          {/*        onClick={() => onClickTag(tag)}*/}
          {/*        key={tag}*/}
          {/*        active={activeTags.includes(tag)}*/}
          {/*      >*/}
          {/*        {`#${tag}`}*/}
          {/*      </HashTag>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</HashTagWrapper>*/}
        </SettingContainer>
      </ContentWrapper>
    </>
  );
};

export default SettingsScreen;
