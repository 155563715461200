import { AxiosInstance, AxiosRequestConfig } from "axios";

import { callEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type CallProps = {
  client_id: string;
  call_id: string;
  claim_id: string;
  call_dt_create: string;
  duration_sec: number;
  from_phone: string;
  client: Client;
  operator: Operator;
  phone: Phone;
  enroll: Enroll[];
  verify: Verify[];
  fraud: Fraud[] | null;
  age: Age[];
  gender: Gender[];
  ethnicity: Ethnicity[];
  voice2face: [];
  operator_name: string;
  operator_id: string;
};

type Operator = {
  operator_id: string;
  natural_operator_id: string;
  name: string;
};

type Fraud = {
  is_spoof: boolean;
  score: number;
  task_id: string;
  task_status: string;
};

type Client = {
  client_id: string;
  claim_id: string;
  name: string;
  email: string;
  phone: string;
};

type Phone = {
  org_phone_id: string;
  org_phone: string;
};

type Enroll = {
  task_id: string;
  task_status: string;
};

type Verify = {
  actual_score: number;
  target_score: number;
  task_id: string;
  dt: string;
  duration: number;
  is_success: boolean;
  is_fraud: boolean;
  score: number;
};

type Age = {
  task_id: string;
  dt: string;
  age: number;
};

type Gender = {
  task_id: string;
  dt: string;
  gender: number;
};

type Ethnicity = {
  task_id: string;
  dt: string;
  ethnicity: number;
};
class Call {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  get = async (queries: string, config?: AxiosRequestConfig): Promise<any> => {
    return this.requestInstance.get(`${callEndpoints.call}${queries}`, {
      ...config,
    });
  };

  getById = async (id: string): Promise<any> => {
    return this.requestInstance.get(`${callEndpoints.call}/${id}`);
  };
}

export const callApi = new Call(axios);
