import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const AddAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  padding: 0 24px 27px 24px;
  width: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.div`
  width: 56px;
  text-align: start;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.WHITE_COLOR};
`;

export const ExportTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const DropdownWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  border: 1px solid ${Colors.BLACK};
  border-radius: 4px;
  position: relative;
`;

export const ExportWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 10px;
`;
