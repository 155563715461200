import { FC } from "react";

export const Exel: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6914 5.63672L12.4883 1.43359C12.3711 1.31641 12.2129 1.25 12.0469 1.25H3.75C3.4043 1.25 3.125 1.5293 3.125 1.875V18.125C3.125 18.4707 3.4043 18.75 3.75 18.75H16.25C16.5957 18.75 16.875 18.4707 16.875 18.125V6.08008C16.875 5.91406 16.8086 5.75391 16.6914 5.63672ZM15.4336 6.36719H11.7578V2.69141L15.4336 6.36719ZM15.4688 17.3438H4.53125V2.65625H10.4297V6.875C10.4297 7.09256 10.5161 7.30121 10.67 7.45505C10.8238 7.60889 11.0324 7.69531 11.25 7.69531H15.4688V17.3438ZM10.041 11.3301L8.83398 9.33008C8.79102 9.25977 8.71484 9.2168 8.63281 9.2168H7.88281C7.83789 9.2168 7.79492 9.22852 7.75781 9.25391C7.64844 9.32227 7.61523 9.4668 7.68555 9.57812L9.29297 12.125L7.66406 14.7188C7.64193 14.7543 7.62971 14.7952 7.62865 14.837C7.6276 14.8789 7.63775 14.9203 7.65806 14.957C7.67838 14.9936 7.70811 15.0242 7.74419 15.0455C7.78027 15.0668 7.82138 15.078 7.86328 15.0781H8.53711C8.61914 15.0781 8.69336 15.0352 8.73633 14.9668L9.96094 12.9844L11.1777 14.9648C11.2207 15.0352 11.2969 15.0762 11.377 15.0762H12.1094C12.1543 15.0762 12.1973 15.0625 12.2363 15.0391C12.3457 14.9688 12.377 14.8242 12.3066 14.7148L10.666 12.168L12.332 9.58008C12.3546 9.54461 12.3672 9.50374 12.3686 9.46174C12.37 9.41973 12.3601 9.37811 12.34 9.34122C12.3199 9.30433 12.2902 9.27351 12.2541 9.25197C12.218 9.23043 12.1768 9.21896 12.1348 9.21875H11.4375C11.3555 9.21875 11.2793 9.26172 11.2363 9.33203L10.041 11.3301Z"
      fill="#008351"
    />
  </svg>
);
