import { FC } from "react";

export const SignOut: FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 12L2.10957 11.6877L1.85969 12L2.10957 12.3123L2.5 12ZM11.5 12.5C11.7761 12.5 12 12.2761 12 12C12 11.7239 11.7761 11.5 11.5 11.5V12.5ZM6.10957 6.68765L2.10957 11.6877L2.89043 12.3123L6.89043 7.31235L6.10957 6.68765ZM2.10957 12.3123L6.10957 17.3123L6.89043 16.6877L2.89043 11.6877L2.10957 12.3123ZM2.5 12.5H11.5V11.5H2.5V12.5Z"
      fill="white"
    />
    <path
      d="M10.5 8.13193V7.38851C10.5 5.77017 10.5 4.961 10.974 4.4015C11.4479 3.84201 12.2461 3.70899 13.8424 3.44293L15.5136 3.1644C18.7567 2.62388 20.3782 2.35363 21.4391 3.25232C22.5 4.15102 22.5 5.79493 22.5 9.08276V14.9172C22.5 18.2051 22.5 19.849 21.4391 20.7477C20.3782 21.6464 18.7567 21.3761 15.5136 20.8356L13.8424 20.5571C12.2461 20.291 11.4479 20.158 10.974 19.5985C10.5 19.039 10.5 18.2298 10.5 16.6115V16.066"
      stroke="white"
    />
  </svg>
);
