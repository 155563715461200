import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const AddAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  padding: 0 24px 27px 24px;
  width: 100%;
`;

export const ChartLabel = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2f363e;
`;

export const ChartGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const PieChartWrapper = styled.div`
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 12px 5px;
  background-color: ${Colors.WHITE_COLOR};
`;

export const BarChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 12px 20px;
  background-color: ${Colors.WHITE_COLOR};
`;

const Table = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  text-align: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
`;

export const TableBlock = styled(Table)`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;
  color: #000000;
  padding: 3px 12px;
  width: 40px;
`;

export const TableHead = styled(Table)`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  padding: 2px;
  color: #616f81;
  width: 45px;
  gap: 4px;
`;

export const TableContainer = styled.div`
  margin-top: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
`;

export const ContentStyled = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 20px;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0 12px 12px 12px;
`;
