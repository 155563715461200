import moment from "moment/moment";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";

import { pieApi } from "../../../api/BarApi/Bar";
import { barApi } from "../../../api/PieApi/Pie";
import { MenuArrow } from "../../../assets";
import { Filter } from "../../../assets/Filter";
import { useUser } from "../../../context/auth-context";
import { Colors } from "../../../utils/colors";
import { QueryNames } from "../../../utils/constants/QueryNames";
import { previousDate } from "../../../utils/helpers/previous-date";
import ButtonStyled from "../../Button";
import DropdownStyled from "../../Dropdown";
import SearchInput from "../../Input/SearchInput";
import PanelHeader from "../../PanelHeader";
import AccountModal from "../ListReporst/AccountModal/AccountModal";
import { ButtonContent, Title } from "../ListReporst/ListReports.styled";

export type GraphicalFilterType = {
  dt_start: string;
  dt_end: string;
  limit: number;
  offset: number;
  search: string;
  export: string | null;
};

import BarChartTable from "./BarChart/Bar";
import {
  AddAccountWrapper,
  ChartGroupWrapper,
  ContentStyled,
} from "./GraphicalReports.styled";
import PieChartDonut from "./PieChartDonut/Pie";

const GraphicalReportsScreen: FC = () => {
  const { user } = useUser();
  const LIMIT = 10;

  const [dateRange, setDateRange] = useState("day_view");
  const [filterValues, setFilterValues] = useState<GraphicalFilterType>({
    dt_start: moment(previousDate("day_view")).format("YYYY[-]MM[-]DD"),
    dt_end: moment(previousDate()).format("YYYY[-]MM[-]DD"),
    limit: LIMIT,
    offset: 0,
    search: "",
    export: null,
  });

  const makeQuery = (filterValues: GraphicalFilterType): string => {
    return `?dt_start=${filterValues.dt_start}${
      filterValues.dt_end ? `&dt_end=${filterValues.dt_end}` : ""
    }${filterValues.search ? `&search=${filterValues.search}` : ""}
    ${filterValues.export ? `&export=${filterValues.export}` : ""}
    `.replaceAll(" ", "");
  };
  const { data: pieVerify } = useQuery({
    queryKey: [QueryNames.GET_PIE_VERIFY, filterValues],
    queryFn: () => {
      return pieApi.getVerify(makeQuery(filterValues));
    },
  });

  const { data: pieGender } = useQuery({
    queryKey: [QueryNames.GET_PIE_GANDER, filterValues],
    queryFn: () => {
      return pieApi.getGender(makeQuery(filterValues));
    },
  });

  const { data: pieAge } = useQuery({
    queryKey: [QueryNames.GET_PIE_AGE, filterValues],
    queryFn: () => {
      return pieApi.getAge(makeQuery(filterValues));
    },
  });

  const { data: pieEthnicity } = useQuery({
    queryKey: [QueryNames.GET_PIE_ETHNICITY, filterValues],
    queryFn: () => {
      return pieApi.getEthnicity(makeQuery(filterValues));
    },
  });

  const { data: barVerify } = useQuery({
    queryKey: [QueryNames.GET_BAR_VERIFY, filterValues],
    queryFn: () => {
      return barApi.getVerify(makeQuery(filterValues));
    },
  });

  const { data: barGender } = useQuery({
    queryKey: [QueryNames.GET_BAR_GANDER, filterValues],
    queryFn: () => {
      return barApi.getGender(makeQuery(filterValues));
    },
  });

  const { data: barAge } = useQuery({
    queryKey: [QueryNames.GET_BAR_AGE, filterValues],
    queryFn: () => {
      return barApi.getAge(makeQuery(filterValues));
    },
  });

  const { data: barEthnicity } = useQuery({
    queryKey: [QueryNames.GET_BAR_ETHNICITY, filterValues],
    queryFn: () => {
      return barApi.getEthnicity(makeQuery(filterValues));
    },
  });

  const COLORS1 = [
    "#334687",
    "#421467",
    "#379865",
    "#1C9EB6",
    "#26749E",
    "#7B7B7B",
  ];
  const COLORS2 = [
    "#4F619C",
    "#592C7D",
    "#6BC495",
    "#3FBDD4",
    "#4791B9",
    "#A5A5A5",
  ];
  // const [open, setOpen] = useState(false);

  const dateFormatter = (format: string) => {
    const dtStart = moment(previousDate(format)).format("YYYY[-]MM[-]DD");

    setFilterValues((prev) => ({
      ...prev,
      dt_end: "",
      dt_start: dtStart,
      export: null,
      age: "",
      gender: "",
      ethnicity: "",
    }));
    setDateRange(format);
  };

  return (
    <>
      <PanelHeader
        tabTitle="Reports"
        title="Clients verification history graphs"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <AddAccountWrapper>
        <SearchInput
          onSearch={(value: string) =>
            setFilterValues((prev) => ({
              ...prev,
              ["search"]: value,
              export: null,
            }))
          }
        />
        <div style={{ marginRight: "20px" }}>
          <DropdownStyled
            style={{ maxWidth: "max-content" }}
            right="12px"
            fontSize={12}
            fontWeight={400}
            onChange={dateFormatter}
            background="initial"
            color={Colors.TITLE_BLACK_MUTED}
            bordered={false}
            defaultValue={dateRange}
            options={[
              {
                value: "month_view",
                label: "Month view",
              },
              {
                value: "week_view",
                label: "Week view",
              },
              {
                value: "day_view",
                label: "Day view",
              },
            ]}
          />
        </div>
        <AccountModal
          show={[1]}
          width={540}
          title="Filter"
          filter={setFilterValues}
        >
          <ButtonStyled background={Colors.BLACK} padding="6px 16px">
            <ButtonContent>
              <Filter />
              <Title>Filter</Title>
              <MenuArrow fill={Colors.WHITE_COLOR}></MenuArrow>
            </ButtonContent>
          </ButtonStyled>
        </AccountModal>
        {/*<div*/}
        {/*  onClick={() => setOpen((prev) => !prev)}*/}
        {/*  style={{*/}
        {/*    display: "flex",*/}
        {/*    marginLeft: "20px",*/}
        {/*    alignItems: "center",*/}
        {/*    border: `1px solid ${Colors.BLACK}`,*/}
        {/*    borderRadius: "4px",*/}
        {/*    position: "relative",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    style={{ position: "absolute", display: "flex", left: "10px" }}*/}
        {/*  >*/}
        {/*    <Export />*/}
        {/*  </div>*/}
        {/*  <DropdownStyled*/}
        {/*    onChange={(value: string) => console.log(value)}*/}
        {/*    padding="0 48px 0 48px"*/}
        {/*    open={open}*/}
        {/*    style={{ maxWidth: "max-content" }}*/}
        {/*    right="12px"*/}
        {/*    borderRadius="4px"*/}
        {/*    fontSize={12}*/}
        {/*    height="36px"*/}
        {/*    bordered={false}*/}
        {/*    fontWeight={700}*/}
        {/*    color={Colors.TITLE_BLACK_MUTED}*/}
        {/*    defaultValue="Export"*/}
        {/*    options={[*/}
        {/*      {*/}
        {/*        value: "pdf",*/}
        {/*        label: (*/}
        {/*          <div*/}
        {/*            style={{*/}
        {/*              display: "flex",*/}
        {/*              alignItems: "center",*/}
        {/*              gap: "8px",*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            <Pdf />*/}
        {/*            <ExportTitle>PDF</ExportTitle>*/}
        {/*          </div>*/}
        {/*        ),*/}
        {/*      },*/}
        {/*      {*/}
        {/*        value: "csv",*/}
        {/*        label: (*/}
        {/*          <div*/}
        {/*            style={{*/}
        {/*              display: "flex",*/}
        {/*              alignItems: "center",*/}
        {/*              gap: "8px",*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            <Exel />*/}
        {/*            <ExportTitle>EXEL</ExportTitle>*/}
        {/*          </div>*/}
        {/*        ),*/}
        {/*      },*/}
        {/*    ]}*/}
        {/*  ></DropdownStyled>*/}
        {/*</div>*/}
      </AddAccountWrapper>
      <ContentStyled>
        <ChartGroupWrapper>
          <PieChartDonut
            pie={pieVerify}
            label="Fraud/Verified/Not verified"
            colors={COLORS1}
            secondColors={COLORS2}
          />
          <PieChartDonut
            pie={pieGender}
            label="Gender"
            colors={COLORS1}
            secondColors={COLORS2}
          />
          <PieChartDonut
            pie={pieAge}
            label="Age"
            colors={COLORS1}
            secondColors={COLORS2}
          />
          <PieChartDonut
            pie={pieEthnicity}
            label="Ethnicity"
            colors={COLORS1}
            secondColors={COLORS2}
          />
        </ChartGroupWrapper>
        <ChartGroupWrapper>
          <BarChartTable
            bar={barVerify}
            label="Fraud/Verified/Not verified"
            colors={COLORS1}
          />
          <BarChartTable bar={barGender} label="Gender" colors={COLORS1} />
          <BarChartTable bar={barAge} label="Age" colors={COLORS1} />
          <BarChartTable
            bar={barEthnicity}
            label="Ethnicity"
            colors={COLORS1}
          />
        </ChartGroupWrapper>
      </ContentStyled>
    </>
  );
};

export default GraphicalReportsScreen;
