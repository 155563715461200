import { FC } from "react";

export const Calendar: FC = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_600_3063)">
      <path
        d="M11.5104 12.9996H1.48962C0.667892 12.9996 0 12.3317 0 11.51V2.57253C0 1.7508 0.667892 1.08301 1.48962 1.08301H11.5104C12.3321 1.08301 13 1.7508 13 2.57253V11.51C13 12.3317 12.3321 12.9996 11.5104 12.9996ZM1.48962 1.89551C1.11639 1.89551 0.8125 2.1993 0.8125 2.57253V11.51C0.8125 11.8832 1.11639 12.1871 1.48962 12.1871H11.5104C11.8836 12.1871 12.1875 11.8832 12.1875 11.51V2.57253C12.1875 2.1993 11.8836 1.89551 11.5104 1.89551H1.48962Z"
        fill="#616F81"
      />
      <path
        d="M12.5938 5.14551H0.40625C0.181999 5.14551 0 4.96351 0 4.73926C0 4.51501 0.181999 4.33301 0.40625 4.33301H12.5938C12.818 4.33301 13 4.51501 13 4.73926C13 4.96351 12.818 5.14551 12.5938 5.14551Z"
        fill="#616F81"
      />
      <path
        d="M3.11426 3.25C2.89001 3.25 2.70801 3.068 2.70801 2.84375V0.40625C2.70801 0.181999 2.89001 0 3.11426 0C3.33851 0 3.52051 0.181999 3.52051 0.40625V2.84375C3.52051 3.068 3.33851 3.25 3.11426 3.25Z"
        fill="#616F81"
      />
      <path
        d="M9.88574 3.25C9.66149 3.25 9.47949 3.068 9.47949 2.84375V0.40625C9.47949 0.181999 9.66149 0 9.88574 0C10.11 0 10.292 0.181999 10.292 0.40625V2.84375C10.292 3.068 10.11 3.25 9.88574 3.25Z"
        fill="#616F81"
      />
    </g>
    <defs>
      <clipPath id="clip0_600_3063">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
