import { FC } from "react";

type FilerProps = {
  stroke?: string;
};
export const File: FC<FilerProps> = ({ stroke = "#292D32" }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3337 8.83333V13C18.3337 17.1667 16.667 18.8333 12.5003 18.8333H7.50033C3.33366 18.8333 1.66699 17.1667 1.66699 13V8C1.66699 3.83333 3.33366 2.16667 7.50033 2.16667H11.667"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3337 8.83333H15.0003C12.5003 8.83333 11.667 8 11.667 5.5V2.16667L18.3337 8.83333Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83301 11.3333H10.833"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83301 14.6667H9.16634"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
