import { FC } from "react";

type IconProps = {
  fill?: string;
};

export const MenuArrow: FC<IconProps> = ({ fill = "#858691" }) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1C8 0.72 7.78 0.5 7.5 0.5H0.5C0.22 0.5 0 0.72 0 1C0 1.13 0.05 1.24 0.13 1.33L3.63 5.33C3.72 5.43 3.85 5.5 4 5.5C4.15 5.5 4.28 5.43 4.37 5.33L7.87 1.33C7.95 1.24 8 1.13 8 1Z"
      fill={fill}
    />
  </svg>
);
