import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";

import { callApi } from "../../../api/CallApi/Call";
import { UpdateClient } from "../../../api/ClientApi/Client";
import { userApi } from "../../../api/UserApi/User";
import { MenuArrow } from "../../../assets";
import { Exel } from "../../../assets/Exel";
import { Export } from "../../../assets/Export";
import { Filter } from "../../../assets/Filter";
import { Colors } from "../../../utils/colors";
import { QueryNames } from "../../../utils/constants/QueryNames";
import { previousDate } from "../../../utils/helpers/previous-date";
import ButtonStyled from "../../Button";
import DropdownStyled from "../../Dropdown";
import SearchInput from "../../Input/SearchInput";
import PanelHeader from "../../PanelHeader";

import AccountModal from "./AccountModal/AccountModal";
import {
  AddAccountWrapper,
  ButtonContent,
  DropdownWrapper,
  ExportTitle,
  ExportWrapper,
  TableWrapper,
  Title,
} from "./ListReports.styled";
import ListReportsTable from "./ListReportsTable/ListReportsTable";

export type FilterType = {
  dt_start: string;
  dt_end: string;
  limit: number;
  offset: number;
  sort: string;
  dir: string;
  search: string;
  gender: null | string;
  ethnicity: string;
  verify: null | boolean;
  fraud: null | boolean;
  operator: string[];
  age: string[];
  dur: string[];
  export: string | null;
};

const ListReportsScreen: FC = () => {
  const LIMIT = 10;

  const { data: userData } = useQuery({
    queryKey: [QueryNames.GET_USER_PROFILE],
    queryFn: () => userApi.getProfile(),
  });

  const [dateRange, setDateRange] = useState("day_view");
  const [filterValues, setFilterValues] = useState<FilterType>({
    dt_start: moment(previousDate("day_view")).format("YYYY[-]MM[-]DD"),
    dt_end: "",
    limit: LIMIT,
    offset: 0,
    sort: "call_dt_create",
    dir: "desc",
    search: "",
    gender: null,
    ethnicity: "",
    verify: null,
    fraud: null,
    operator: [],
    age: [],
    dur: [],
    export: null,
  });

  const makeQuery = (filterValues: FilterType): string => {
    return `?dt_start=${filterValues.dt_start}${
      filterValues.dt_end ? `&dt_end=${filterValues.dt_end}` : ""
    }&limit=${filterValues.limit}&offset=${filterValues.offset}&sort=${
      filterValues.sort
    }${filterValues.dir ? `&dir=${filterValues.dir}` : ""}
    ${filterValues.gender !== null ? `&gender=${filterValues.gender}` : ""}
    ${
      filterValues.ethnicity.length > 0
        ? `&ethnicity=${filterValues.ethnicity}`
        : ""
    }
    ${
      filterValues.operator.length > 0
        ? `&operator=${filterValues.operator}`
        : ""
    }
    ${filterValues.search ? `&search=${filterValues.search}` : ""}
    ${filterValues.verify !== null ? `&verify=${filterValues.verify}` : ""}
    ${filterValues.fraud !== null ? `&fraud=${filterValues.fraud}` : ""}
    ${filterValues.search ? `&search=${filterValues.search}` : ""}
    ${filterValues.age.length > 0 ? `&age=${filterValues.age}` : ""}
    ${filterValues.dur.length > 0 ? `&dur=${filterValues.dur}` : ""}
    ${filterValues.export ? `&export=${filterValues.export}` : ""}
    `.replaceAll(" ", "");
  };

  const {
    data: { calls, total },
  } = useQuery({
    queryKey: [QueryNames.GET_CLIENTS, filterValues],
    queryFn: () => {
      return callApi.get(makeQuery(filterValues));
    },
    initialData: {
      calls: [],
      total: 0,
      offset: 0,
    },
  });

  const downloadFile = async (type: string) => {
    await callApi
      .get(`${makeQuery(filterValues)}&export=${type}`, {
        responseType: "blob",
      })
      .then((res) => {
        const headers = res.headers;
        const filename = headers["x-suggested-filename"];
        const blob = new Blob([res.data]);

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      });
  };
  const [open, setOpen] = useState(false);

  const dateFormatter = (format: string) => {
    const dtStart = moment(previousDate(format)).format("YYYY[-]MM[-]DD");

    setFilterValues((prev) => ({
      ...prev,
      dt_end: "",
      dt_start: dtStart,
      export: null,
      call_duration: "",
      gender: "",
      ethnicity: "",
      verify: null,
      fraud: null,
      age: [],
      operator: [],
      duration: [],
    }));
    setDateRange(format);
  };

  return (
    <>
      <PanelHeader
        tabTitle="Reports"
        title="Calls"
        subTitle={`Hello ${
          userData?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <AddAccountWrapper>
        <SearchInput
          onSearch={(value: string) =>
            setFilterValues((prev) => ({
              ...prev,
              ["search"]: value,
              export: null,
            }))
          }
        />
        <div style={{ marginRight: "20px" }}>
          <DropdownStyled
            style={{ maxWidth: "max-content" }}
            right="12px"
            fontSize={12}
            fontWeight={400}
            onChange={dateFormatter}
            background="initial"
            color={Colors.TITLE_BLACK_MUTED}
            bordered={false}
            defaultValue={dateRange}
            options={[
              {
                value: "month_view",
                label: "Month view",
              },
              {
                value: "week_view",
                label: "Week view",
              },
              {
                value: "day_view",
                label: "Day view",
              },
            ]}
          />
        </div>

        <AccountModal
          show={[1, 2, 3, 4, 5, 6, 7, 8]}
          width={540}
          title="Filter"
          initialData={{} as UpdateClient}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filter={setFilterValues}
        >
          <ButtonStyled background={Colors.BLACK} padding="6px 16px">
            <ButtonContent>
              <Filter />
              <Title>Filter</Title>
              <MenuArrow fill={Colors.WHITE_COLOR}></MenuArrow>
            </ButtonContent>
          </ButtonStyled>
        </AccountModal>
        <DropdownWrapper onClick={() => setOpen((prev) => !prev)}>
          <ExportWrapper>
            <Export />
          </ExportWrapper>
          <DropdownStyled
            padding="0 48px 0 48px"
            open={open}
            style={{ maxWidth: "max-content" }}
            right="12px"
            borderRadius="4px"
            fontSize={12}
            height="36px"
            bordered={false}
            fontWeight={700}
            color={Colors.TITLE_BLACK_MUTED}
            defaultValue="Export"
            options={[
              {
                value: "csv",
                label: (
                  <div
                    onClick={() => downloadFile("csv")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Exel />
                    <ExportTitle>CSV</ExportTitle>
                  </div>
                ),
              },
            ]}
          ></DropdownStyled>
        </DropdownWrapper>
      </AddAccountWrapper>
      <Content>
        <TableWrapper>
          <ListReportsTable
            data={calls}
            limit={LIMIT}
            total={total}
            filter={setFilterValues}
            filterValues={filterValues}
            offset={filterValues.offset / LIMIT + 1}
            setOffset={(value) => {
              setFilterValues((prev) => ({
                ...prev,
                ["offset"]: (value - 1) * LIMIT,
                export: null,
              }));
            }}
          />
        </TableWrapper>
      </Content>
    </>
  );
};

export default ListReportsScreen;
