import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;

export const Content = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${Colors.TITLE_MUTED};
`;

export const Title = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.02em;

  color: ${Colors.SILVER_BLUR_COLOR};
`;

export const Expires = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.02em;

  color: ${Colors.BLACK};
`;

export const Save = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${Colors.WHITE_COLOR};
`;

export const Cancel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.TITLE_SILVER_COLOR};
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  gap: 84px;
`;
