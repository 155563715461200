import { UpdateClient } from "../../api/ClientApi/Client";
import { UpdateUser } from "../../api/UserApi/User";

export const removeEmpty = (obj: UpdateClient | UpdateUser) => {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, v]) => v != null || v?.length > 0)
  );
};
