import { FC } from "react";

export const DeleteFile: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.39941 7.79967H17.6L16.4939 17.7548C16.4151 18.4638 15.8158 19.0003 15.1023 19.0003H8.89705C8.18364 19.0003 7.58433 18.4638 7.50554 17.7548L6.39941 7.79967Z"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.74129 5.80305C8.97241 5.31281 9.4657 5 10.0077 5H13.9923C14.5344 5 15.0276 5.31281 15.2588 5.80305L16.2003 7.80015H7.7998L8.74129 5.80305Z"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.79967H19.0007"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6011 11.2998V14.8"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3999 11.2998V14.8"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
