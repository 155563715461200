import { AxiosInstance } from "axios";

import { pieEndpoints } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type BarType = {
  categories: BarCategories[];
  data: BarData[];
};

export type BarCategories = {
  name: string;
  values: number[];
};

export type BarData = {
  name: string;
};
class Bar {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  getVerify = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${pieEndpoints.verify}${queries}`);
  };

  getGender = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${pieEndpoints.gender}${queries}`);
  };

  getAge = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${pieEndpoints.age}${queries}`);
  };

  getEthnicity = async (queries: string): Promise<any> => {
    return this.requestInstance.get(`${pieEndpoints.ethnicity}${queries}`);
  };
}
export const pieApi = new Bar(axios);
