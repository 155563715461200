import { FC, useEffect, useState } from "react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useParams } from "react-router-dom";

import { clientApi, UpdateClient } from "../../../../api/ClientApi/Client";
import { Arrow } from "../../../../assets";
import { Colors } from "../../../../utils/colors";
import { QueryNames } from "../../../../utils/constants/QueryNames";
import { removeEmpty } from "../../../../utils/helpers/removeEmpty";
import ButtonStyled from "../../../Button";
import { TextStyled } from "../../../Button/Button.styled";
import InputStyled from "../../../Input/Input";

import { Text, TextTitle } from "./ClientScreen.styled";

const ClientScreen: FC = () => {
  const queryClient = useQueryClient();
  const updateClient = useMutation(clientApi.update);
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();

  const { data } = useQuery({
    queryFn: () => clientApi.getById(id as string),
  });

  const [modalData, setModalData] = useState<UpdateClient>();

  useEffect(() => {
    if (data) {
      setModalData({
        name: data.name,
        email: data.email,
        phone: data.phone,
        claim_id: data.claim_id,
      });
    }
  }, [data]);

  const onClientUpdate = (clientUpdate: UpdateClient) => {
    const filteredClient = removeEmpty(clientUpdate);
    updateClient.mutate(
      {
        ...filteredClient,
        id: id as string,
      },
      {
        onSuccess: () => {
          NotificationManager.success("Client updated");
          setIsEdit(() => false);
          queryClient.invalidateQueries([QueryNames.GET_CLIENT]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            `${JSON.stringify(error?.request.response)}`
          );
        },
      }
    );
  };
  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const notEditableText = (title: string, text?: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderBottom: `1px solid ${Colors.TITLE_INNER_COLOR}`,
        }}
      >
        <TextTitle>{title}</TextTitle>
        <Text>{text}</Text>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <NotificationContainer />
      {!isAuth && <Navigate to={"/login"} replace={true} />}
      {data?.client_id === id && modalData && (
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "column",
            maxWidth: "600px",
            padding: "10px",
            width: "100%",
            gap: "24px",
          }}
        >
          <a
            onClick={() => history.back()}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "16px 0",
              width: "100%",
              fontWeight: 700,
            }}
          >
            <Arrow></Arrow>
            <div style={{ width: "5px" }}></div>
            Back
          </a>
          {isEdit ? (
            <InputStyled
              title="Full name:"
              value={modalData.name}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["name"]: value }));
              }}
            />
          ) : (
            notEditableText("Full name:", modalData.name)
          )}
          {isEdit ? (
            <InputStyled
              title="Email:"
              value={modalData.email}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["email"]: value }));
              }}
            />
          ) : (
            notEditableText("Email:", modalData.email)
          )}
          {isEdit ? (
            <InputStyled
              title="Phone:"
              value={modalData.phone}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["phone"]: value }));
              }}
            />
          ) : (
            notEditableText("Phone:", modalData.phone)
          )}
          {isEdit ? (
            <InputStyled
              title="Claim id:"
              value={modalData.claim_id}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["claim_id"]: value }));
              }}
            />
          ) : (
            notEditableText("Claim id:", modalData.claim_id)
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            {isEdit && (
              <ButtonStyled
                background={Colors.WHITE_COLOR}
                onClick={() => {
                  setModalData(data);
                  setIsEdit(false);
                }}
              >
                <TextStyled style={{ color: Colors.BLACK_COLOR }}>
                  Cancel
                </TextStyled>
              </ButtonStyled>
            )}
            <ButtonStyled
              onClick={() =>
                isEdit ? onClientUpdate(modalData) : setIsEdit(() => true)
              }
            >
              <TextStyled>{isEdit ? "Save" : "Edit"}</TextStyled>
            </ButtonStyled>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientScreen;
