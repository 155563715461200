export const previousDate = (dateType?: string): Date => {
  const date = new Date();

  switch (dateType) {
    case "month_view":
      return new Date(date.getTime() - 30 * 24 * 3600 * 1000);
    case "week_view":
      return new Date(date.getTime() - 7 * 24 * 3600 * 1000);
    case "day_view":
      return new Date();
    default:
      return new Date();
  }
};
