import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const DropdownStyledWrapper = styled.div<{
  background?: string;
  color?: string;
  fontWeight?: number;
  fontSize?: number;
  right?: string;
  borderRadius?: string;
  borderColor?: string;
  height?: string;
  padding?: string;
}>`
  & .ant-select {
    & .ant-select-arrow {
      right: ${({ right }) => (right ? `${right}` : "20px")};
    }

    & .ant-select-selector {
      border: ${({ borderColor }) =>
        `1px solid ${borderColor || Colors.TITLE_INNER_COLOR}`};
      border-radius: ${({ borderRadius }) =>
        borderRadius ? `${borderRadius}` : "8px"};
      height: ${({ height }) => (height ? `${height}` : "auto")};
      padding: ${({ padding }) => (padding ? `${padding}` : "0 28px 0 12px")};
      background-color: ${({ background }) =>
        background ? `${background}!important` : `${Colors.WHITE_COLOR}`};

      & .ant-select-selection-item {
        padding-right: 0;
        display: flex;
        align-items: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
        font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "16px")};
        color: ${({ color }) =>
          color ? `${color}` : `${Colors.TITLE_SILVER_COLOR}`};
      }
    }
  }
`;

export const Title = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.TITLE_INNER_COLOR};
  margin-bottom: 8px;
`;
