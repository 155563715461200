import { AxiosInstance } from "axios";

import { licenseEndpoint } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type LicenseType = {
  dt_month: string;
  license_type: string;
  license_price: number;
  limit_operators: number;
  total_operators: number;
  count_calls: number;
  count_enrolls: number;
  count_verifies: number;
  price_call: number;
  price_enroll: number;
  price_verify: number;
};

class License {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  getLicenses = async (): Promise<any> => {
    return this.requestInstance.get(`${licenseEndpoint.license}`);
  };

  getLicensesLast = async (): Promise<any> => {
    return this.requestInstance.get(`${licenseEndpoint.licenseLast}`);
  };
}
export const licenseApi = new License(axios);
