import React, { createContext, useContext, useEffect, useState } from "react";

import { settingsApi } from "../api/SettingsApi/Settings";
import { userApi, UserProps } from "../api/UserApi/User";

type UserContextType = {
  user: UserProps;
  config: any;
  onUpdate: () => void;
  onUpdateSettings: () => void;
  onClear: () => void;
};

const UserContext = createContext<UserContextType>({
  user: {} as UserProps,
  config: null,
  onUpdate: () => undefined,
  onClear: () => undefined,
  onUpdateSettings: () => undefined,
});

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserProps | null>(null);
  const [config, setConfig] = useState(null);

  const getUserInfo = async () => {
    userApi.getProfile().then((res) => setUser(() => res));
  };

  const getUserSettings = async () => {
    settingsApi.get().then((res) => setConfig(() => res));
  };

  useEffect(() => {
    const isAuth =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (isAuth) {
      getUserInfo().then(() => getUserSettings());
    }

    return () => onClear();
  }, []);

  const onUpdate = async (): Promise<void> => {
    await getUserInfo();
    await getUserSettings();
  };
  const onUpdateSettings = async (): Promise<void> => getUserSettings();

  const onClear = () => {
    setUser(null);
    setConfig(null);
  };

  return (
    <UserContext.Provider
      value={{
        user: user as UserProps,
        config,
        onUpdate,
        onUpdateSettings,
        onClear,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => useContext(UserContext);

export default UserProvider;
