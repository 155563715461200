import styled from "styled-components";

import { Colors } from "../../../utils/colors";

export const InputStyledWrapper = styled.div<{ width?: number }>`
  border: 1px solid #acb9c9;
  margin-top: 8px;
  border-radius: 8px;
  overflow: hidden;

  & :where(.css-dev-only-do-not-override-10ed4xt).ant-input:hover {
    border-radius: 7px;
  }

  & .ant-input {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.TITLE_SILVER_COLOR};
    padding: 8px 12px;
  }

  & .ant-input-affix-wrapper {
    border-radius: 4px;
    border: none;
    padding: 6px 16px;
    width: ${({ width }) => (width ? `${width}` : "365px")};
  }

  span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${Colors.TITLE_SILVER_COLOR};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Title = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.TITLE_INNER_COLOR};
`;
