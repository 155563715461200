import { AxiosInstance } from "axios";

import { configEndpoint } from "../../utils/constants/endpoints";
import { axios } from "../axiosInstance";

export type ConfigUpdate = {
  path: string;
  value: boolean;
};
class Settings {
  requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.requestInstance = axiosInstance;
  }

  get = async (): Promise<any> => {
    return this.requestInstance.get(`${configEndpoint.config}`);
  };

  update = async (configUpgrade: ConfigUpdate): Promise<any> => {
    return this.requestInstance.post(`${configEndpoint.config}`, {
      ...configUpgrade,
    });
  };
}
export const settingsApi = new Settings(axios);
