import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const TableStyledWrapper = styled.div`
  box-shadow: 1px 1px 2px 2px ${Colors.SHADOW_COLOR};
  border-radius: 12px;
  overflow: hidden;

  & .ant-pagination-options {
    display: none;
  }

  & .ant-table-pagination {
    padding: 12px 20px;
    background-color: ${Colors.WHITE_COLOR};
    margin: 0 !important;
  }

  & .ant-pagination-disabled {
    svg {
      path {
        fill: #bdbdbd !important;
      }
    }
  }

  & .ant-pagination-item-link {
    svg {
      font-size: 14px;
      path {
        fill: rgba(189, 189, 189, 0.3);
      }
    }
  }

  & .ant-table-column-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: ${Colors.TITLE_BLACK_MUTED};
  }

  & .ant-table-cell {
    padding: 13px 20px !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${Colors.TITLE_SILVER_COLOR};
    background-color: ${Colors.WHITE_COLOR}!important;
  }

  & .ant-pagination-item {
    margin-right: 4px;

    a {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: ${Colors.SILVER_COLOR};
    }
  }

  & .ant-pagination-item-active {
    background: rgba(218, 218, 218, 0.4);
    border-radius: 3px;
    border: none;
    margin-right: 4px;

    a {
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: ${Colors.BLACK};

      &:hover {
        color: initial;
      }
    }
  }
`;
